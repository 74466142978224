/*
You are reading the 'positions module'

The positions module (positions.js) holds the fragments of a space
it administrates spatial relations of fragments and users

Directions for USER SCRIPTS:
you can only use functions and variables that have the keyword 'export' in front of them
in your user scripts you always have to write "modules.positions.something"

Here are some examples:
modules.positions.goToCenter();
modules.positions.getAll();
modules.positions.goToScene(x, y, scale);
modules.positions.getGlobalScaleLowPrec();

To find these examples in the code below, you can search for der their individual names (search for
'goToScene' to find that function etc.).

Scroll through the code to find more
*/

// Relations to the other modules of nota

// From the 'settings module' positions imports an unusual type of number called 'Decimal'
// Decimal uses a wrong 'body' (a string) to bend the rules of Javascript and expand notas space
import {Decimal} from './settings.js';
// From the 'main module' positions gets the p5.js library and more specifially the 'p5' renderer
// We could also just import {getSketch, renderer} from './main.js'
import * as main from './main.js';
// From the 'base_fragment' module we get the BaseFragment Class
import {BaseFragment} from './fragment/base_fragment.js';
// From the 'api_fragments module' positions gets a list of fragments with their identifications 
import * as api_fragments from './api/fragments.js';
// From the 'undo module' positions imports a function to reset/nullify the undo and redo history
// resetting this history is part of resetting the space, which is why the positions.reset() function
// is called by the 'space module' to reset all positions
import {reset as undo_reset} from './undo.js';
// Positions uses the localstore module to save and remember the user position
import * as localstore from './localstore.js';
// From the 'utilities module' positions imports a function to reverse a list of fragments that are
// on top of each other
import {reverse} from './utilities/utilities.js';
// From the 'input module' positions uses a function to reset the users movement when clicking 'center'
import {resetMovement} from './input.js';

// MONSTER
// This is a function that is not part of the module, it is just there to show the limit of the module
// We call it a monster function because monsters are also demonstrating the limits of systems
function getFragmentPosition() {
  throw Error('the "positions module" only has a list of the fragments, fragments know their own positions');
}

// fragments in front of array are draw first -> are below all other fragments
let _fragments = [];

let globalScale = new Decimal(1);
let globalTranslation = {x:new Decimal(0), y:new Decimal(0)};
let _positionChanged = true;

function setGlobalTranslation(val) {
  if(val.x !== globalTranslation.x && val.y !== globalTranslation.y) {
    setPositionChanged(true);
  }
  globalTranslation = val;
}

function getGlobalTranslation() {
  return globalTranslation;
}

function setGlobalScale(val) {
  if(val !== globalScale) {
    setPositionChanged(true);
  }
  globalScale = val;
}

function getGlobalScale() {
  return globalScale;
}

export function getGlobalScaleLowPrec() {
  let fc = main.renderer.frameCount;
  if(fc != _calcedGSFrame) {
    calcedGS = getGlobalScale().toNumber();
    _calcedGSFrame = fc;
  }
  return calcedGS;
}

export function getGlobalScalePrec() {
  return getGlobalScale();
}

export function getPositionChanged() {
  return _positionChanged;
}

export function setPositionChanged(val) {
  _positionChanged = val;
}

export function storeCamera() {
  localstore.setItem('translation_x', getGlobalTranslation().x);
  localstore.setItem('translation_y', getGlobalTranslation().y);
  localstore.setItem('scale', getGlobalScale());
}

// The goToScene() function will move the canvas to show you a specific position
// For USER SCRIPTS: You can use the x, y and scale values of fragment. However the way you have to
// apply the values, is not intuitive.
// "I managed to figure it out by myself, but is was really hard, as demonstrated here" (Birk):
// [TODO: link to screenrecording]
// Insert this code into a fragment and you will jump there:
/*
let scale = 1 / fragment.getIndividualScale()
let x = -fragment.x * scale;
let y = -fragment.y * scale;
modules.positions.goToScene(x, y, scale);
*/
export function goToScene(tx, ty, scale) {
  setGlobalScale( new Decimal(scale));
  setGlobalTranslation( {x:new Decimal(tx), y:new Decimal(ty)});
  storeCamera();
}

// The goToCenter() function is called by the 'input module' when the user clicks the 'center button'
// For USER SCRIPTS: This is function that calls two other functions:
// modules.input.resetMovement() you can find in the 'input module'
// modules.postions.goToScene() you can find in this module 
export function goToCenter() {
  //this is modules.input.resetMovement()
  resetMovement();
  goToScene(0, 0, 1)
}

// TODO should be same or something
export function resetCamera() {
  let tx = localstore.getItem('translation_x') || 0;
  let ty = localstore.getItem('translation_y') || 0;
  let sc = localstore.getItem('scale') || 1;
  goToScene(tx, ty, sc);
}

export function resetFragments() {
  let canv = document.getElementById('defaultCanvas0');
  let cont = document.getElementById('zoom-sketch');
  while (cont.firstChild) {
    cont.removeChild(cont.firstChild);
  }
  cont.appendChild(canv);
  _fragments = [];
}

export function reset() {
  resetFragments();
  resetCamera();
  // this is modules.undo.reset()
  undo_reset();
}

export function refreshFragmentsByIDs(fragment_ids) {
  let fragments = getAll().filter(
    frag => frag.id !== -1 && fragment_ids.includes(frag.id)
  );
  let fragments_by_id = {};
  fragments.forEach((frag) => {
    fragments_by_id[frag.id] = frag;
  });
  api_fragments.get_list_by_ids(fragment_ids).then(function(pers){
    fragments.forEach(function(frag) {
      let fragPers = pers[frag.id];
      if(fragPers) {
        fragPers.persistent = true;
        Object.getPrototypeOf(frag).constructor.setFragmentData(
            frag, fragPers);
      }
    });
  });
}

export function loadNewFragmentsByIDs(fragment_ids) {
  api_fragments.get_list_by_ids(fragment_ids).then(function(fragment_jsons) {
    let sketch = main.getSketch();
    fragment_jsons = Object.keys(fragment_jsons).map(key => fragment_jsons[key]);
    fragment_jsons.forEach(function(fragJson) {
      var fragment = BaseFragment.restore(sketch, fragJson);
      _fragments.push(fragment);
    });
    /*
    fragments.forEach(function(frag) {
      let fragPers = pers[frag.id];
      if(fragPers) {
        fragPers.persistent = true;
        Object.getPrototypeOf(frag).constructor.setFragmentData(
            frag, fragPers);
      }
    });
    */
  });
}


// TODO implement
export function getOnScreenFragments() {
  return _fragments;
}

/**
 * Add fragments according to their floating_z, if any; else on top
 */
export function insertFragments(fragmentsToInsert, sync = true) {
  fragmentsToInsert.forEach(f => insertFragment(f, sync));
}
export function insertFragment(toInsert, sync = true) {
  let fz = toInsert.floating_z;
  let didInsert = _fragments.some(function(frag, idx) {
    if(fz <= frag.floating_z) {
      _fragments.splice(idx, 0, toInsert);
      return true;
    }
  });
  if(!didInsert) {
    addOnTop(toInsert, sync);
  }
}
function addFragment(fragment, onTop, sync = true) {
  fragment.isDeleted = false;
  fragment.removed_from_space = false;
  if(onTop) {
    _fragments.push(fragment);
    sendFragmentToFront(fragment, sync);
  }
  else {
    _fragments.unshift(fragment);
    sendFragmentToBack(fragment, sync);
  }
}
export function addFragmentsBelow(newFragments, sync = true) {
  newFragments.forEach(f => addBelow(f, sync));
}
export function unshift(fragment) {
  _fragments.unshift(fragment);
}
export function addBelow(fragment, sync = true) {
  addFragment(fragment, false, sync);
}
export function removeFragmentsByIDs(fragment_ids) {
  fragment_ids.forEach(removeFragmentByID);
}
export function removeFragmentByID(fragment_id) {
  let fragment = _fragments.find(f => f.id === fragment_id);
  _fragments = _fragments.filter(
    f => f.id !== fragment_id
  );
  if(typeof fragment !== 'undefined') {
    fragment.onRemove();
  }
  else {
    console.warn("did not find fragment object before removing");
  }
  //removeFragmentByID(fragment.id);
}
export function removeFragments(removeFragments) {
  removeFragments.forEach(removeFragment);
}
export function removeFragment(fragment) {
  _fragments = _fragments.filter(
    f => f !== fragment
  );
  fragment.onRemove();
  //removeFragmentByID(fragment.id);
}

export function addFragmentsOnTop(fragmentsToAdd, sync = true) {
  fragmentsToAdd.forEach(f => addOnTop(f, sync));
}
export function addOnTop(fragment, sync = true) {
  addFragment(fragment, true, sync);
}

export function getAll() {
  return _fragments;
}
export function getPersistentFragments() {
  return _fragments.filter(function(frag) {
    return frag.persistent;
  });
}

export function getFragmentsContainedByRect(screenRect) {
  let x = screenRect.x;
  let y = screenRect.y;
  let w = screenRect.w;
  let h = screenRect.h;
  // TODO
}
export function getFragmentAt(screenPoint) {
  let x = screenPoint.x;
  let y = screenPoint.y;
  let frag = null;
  reverse(onScreenFragments(), function(fragment) {
    var within = fragment.pointCollide(x, y);
    if(within) {
      frag = fragment;
    }
    return within;
  });
  return frag;
}
export function onScreenFragments() {
  return _fragments.filter(function(frag) {
    return frag.isOnScreen();
  });
}
// return bottom fragment, excluding non-persistent fragments
export function getBottomFragment() {
  let frags = getPersistentFragments();
  return frags[frags.length - 1];
}
// return top fragment, excluding non-persistent fragments
export function getTopFragment() {
  return getPersistentFragments()[0];
}
export function sendFragmentToFront(fragment, sync  = true) {
  let idx = _fragments.indexOf(fragment);
  if(idx !== -1) {
    var shiftElem = _fragments.splice(idx, 1);
    _fragments.push(shiftElem[0]);
    let persistentFragments = getPersistentFragments();
    if(persistentFragments.length > 1) {
      let largest = persistentFragments[persistentFragments.length - 2].floating_z;
      fragment.floating_z =  largest + 1;
    }
    if(sync) {
      fragment.sync()
    }
  }
  else {
    console.error("Cannot send fragment to front if not in fragments array");
  }
}
export function sendFragmentToBack(fragment, sync = true) {
  let idx = _fragments.indexOf(fragment);
  if(idx !== -1) {
    var shiftElem = _fragments.splice(idx, 1);
    _fragments.unshift(shiftElem[0]);
    let persistentFragments = getPersistentFragments();
    if(persistentFragments.length > 1) {
      fragment.floating_z = _fragments[1].floating_z - 1;
    }
    if(sync) {
      fragment.sync()
    }
  }
  else {
    console.error("Cannot send fragment to front if not in fragments array");
  }
}
export function moveToTop(fragstomove) {
  fragstomove.forEach(sendFragmentToFront);
}

let resetTimeout = null;
let warnTimeout = null;
export function changeTranslationBy(x, y) {
  setGlobalTranslation({
    x : getGlobalTranslation().x.add(x),
    y : getGlobalTranslation().y.add(y)
  });
  storeCamera();
}
export function getTranslation() {
  let translation =  {
    x: getGlobalTranslation().x.toNumber(),
    y: getGlobalTranslation().y.toNumber()
  };
  return translation;
}
export function getTranslationPrec() {
  return getGlobalTranslation();
}
let _calcedGSFrame = -1;
let calcedGS = 0;
function _zoomGlobalPrecision(mouse, factor) {
  let gtx = getGlobalTranslation().x;
  let gty = getGlobalTranslation().y;
  let gs = getGlobalScale();
  // let xSpace = mouse.x / globalScale - globalTranslation.x / globalScale;
  let xSpace = (new Decimal(mouse.x).div(gs)).sub(gtx.div(gs));
  //let ySpace = mouse.y / globalScale - globalTranslation.y / globalScale;
  let ySpace = (new Decimal(mouse.y).div(gs)).sub(gty.div(gs));
  //let newXSpace = xSpace / factor;
  let newXSpace = xSpace.div(factor);
  //let newYSpace = ySpace / factor;
  let newYSpace = ySpace.div(factor);

  //globalScale *= factor;
  gs = gs.mul(factor);
  setGlobalScale( gs);
  //globalScale = gs.toNumber();
  //let xt = (newXSpace - xSpace) * globalScale;
  let xt = (newXSpace.sub(xSpace)).mul(gs);
  //let yt = (newYSpace - ySpace) * globalScale;
  let yt = (newYSpace.sub(ySpace)).mul(gs);
  changeTranslationBy(xt, yt);
}
export function zoomGlobal(mouse, factor) {
  _zoomGlobalPrecision(mouse, factor);
  storeCamera();
}

export function moveFragmentByScreenDiff(fragment, screenX, screenY) {
  let xdif = new Decimal(screenX).div(getGlobalScale());
  fragment.x += xdif.toNumber();
  let ydif = new Decimal(screenY).div(getGlobalScale());
  fragment.y += ydif.toNumber();
}
export function moveFragment(fragment, dx, dy) {
  fragment.x += dx;
  fragment.y += dy;
}
/**
 * scaleFragment with scaling center at (screenX, screenY) by factor
 */
export function scaleFragment(fragment, factor, point) {
  let centerX = point.x;
  let centerY = point.y
  let scaleOld = fragment.getIndividualScale();
  let newScale = factor * scaleOld;

  let relCenterX = centerX - fragment.screenX();
  relCenterX = relCenterX / fragment.screenW();
  let relCenterY = centerY - fragment.screenY();
  relCenterY = relCenterY / fragment.screenH();

  fragment.setIndividualScale(newScale);

  let scdiff = newScale - scaleOld;
  let dx = relCenterX * fragment.w * scdiff;
  let dy = relCenterY * fragment.h * scdiff;
  fragment.x -= dx;
  fragment.y -= dy;
  return {x: dx, y: dy};
}
/**
 * Be careful this method is expensive on the  CPU
 */
export function screenToGlobalDiff(diff) {
  let xdif = new Decimal(diff.dx).div(getGlobalScale());
  let ydif = new Decimal(diff.dy).div(getGlobalScale());
  return {dx: xdif.toNumber(), dy: ydif.toNumber()};
}
/**
 * Be careful this method is expensive on the  CPU
 */
export function screenToGlobalCoords(point) {
  let x = new Decimal(point.x).div(getGlobalScale());
  x = x.sub(getGlobalTranslation().x.div(getGlobalScale()));
  let y = new Decimal(point.y).div(getGlobalScale());
  y = y.sub(getGlobalTranslation().y.div(getGlobalScale()));
  return {x: x.toNumber(), y: y.toNumber()};
}
export function globalToScreenCoords(point) {
  let sc = getGlobalScaleLowPrec();
  let t = getTranslation();
  return {
    x: point.x * sc + t.x,
    y: point.y * sc + t.y
  }
}
export function globalToScreenSize(size) {
  return {
    w: size.w * getGlobalScaleLowPrec(),
    h: size.h * getGlobalScaleLowPrec()
  }
}
export function getView(screenW, screenH) {
  let x = getGlobalTranslation().x;
  let y = getGlobalTranslation().y;
  let w = new Decimal(screenW).div(getGlobalScale());
  let h = new Decimal(screenH).div(getGlobalScale());
  return {x: x.toNumber(), y: y.toNumber(), w: w.toNumber(), h: h.toNumber()};
}


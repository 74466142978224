import {BaseFragment} from './base_fragment.js';
import * as settings from '../settings.js';
import * as audio from '../audio.js';

class AudioFragment extends BaseFragment {
  constructor(p, x, y, type, data, persistent = false) {
    super(p, x, y, type, data, persistent);

    this.w = data.width;
    this.h = data.width;

    this.audio = {};
    this.audio.audioContext = audio.getContext();;
    this.audio.shouldStart = true;
    this.audio.audioSource = null;
    this.audio.gainNode = null;
    this.audio.panner = null;
    this.setPlaying(false);
    this.loadAudio(data.url || self.url || '');
  }
  loadAudio(url) {
    let self = this;
    url = settings.get_media_url() + url;
    //
    let audioElement = new Audio(url);
    audioElement.oncanplaythrough = function() {
      // only set up once
      if(self.audio.audioSource === null) {
        self.audio.audioSource = self.audio.audioContext.createMediaElementSource(audioElement);
        self.audio.gainNode = self.audio.audioContext.createGain();
        let pannerOptions = { pan: 0 };
        self.audio.panner = new StereoPannerNode(self.audio.audioContext, pannerOptions);

        let audioSource = self.audio.audioSource.connect(self.audio.gainNode).connect(self.audio.panner)
        audio.connect(audioSource);
        self.setReady(true);
      }
    }
    audioElement.autoplay = true;
    audioElement.onplaying = function() {
      self.setPlaying(true);
    };
    audioElement.onpause = function() {
      self.setPlaying(false);
    };
    audioElement.onload = function() {
      self.setPlaying(true);
    }
    this.audio.audioElement = audioElement; // renamed from audio
    // add for userscript backwards compatibilty
    this.audio.elt = audioElement; // TODO: filter
  }
  typeMovesOnScreen() {
    if(this.audio.audioElement) {
      this.playAudio();
    }
  }
  typeMovesOffScreen() {
    if(this.audio.audioElement) {
      this.audio.audioElement.pause();
    }
  }
  playAudio() {
    // only try playing, if AudioContext is running,
    // trying to play is expensive
    if(audio.isRunning() && this.audio.audioElement.paused) {
      let playPromise = this.audio.audioElement.play();
      playPromise.then(_=>{
        // success, do nothing
      }).catch(error=>{
        // assume it's because of the autoplay policy
        // and don't spam the log
      });
    }
  }
  pauseAudio() {
    this.shouldStart = false;
    if(!this.audio.audioElement.paused) {
      this.audio.audioElement.pause();
    }
  }
  typeManageState() {
    if(this.isOnScreen() && this.audio.audioElement && !this.audio.audioElement.muted) {
      if(this.shouldStart && this.audio.audioElement.paused) {
        this.playAudio();
      }
      let rar = this.getRelativeOnScreenArea();
      if(0 <= rar && rar <= 1) {
        this.audio.audioElement.volume = this.overrideVolume || rar;
      }
      else {
        console.warn("Tried to set audio volume outside of [0,1]");
        console.warn(rar);
        this.audio.audioElement.volume = this.overrideVolume || Math.max(0, Math.min(1, rar));
      }
    }
    else if(this.audio.audioElement) {
      this.audio.audioElement.volume = this.overrideVolume || 0;
    }
  }
  typeOnRemove() {
    this.doDisable();
  }
  setAudio(url, filehashes) {
    let self = this;

    this.url = url;
    this.filehashes = filehashes;

    this.loadAudio(url);
  }
  typeOnRemove() {
    this.doDisable();
  }
  doDisable() {
    if(this.audio.audioElement) {
      this.audio.audioElement.pause();
    }
  }
  typeLoop() {
    if(this.audio.audioElement) {
      this.audio.audioElement.loop = true;
      this.playAudio();
    }
  }
  typePause() {
    if(this.audio.audioElement) {
      this.audio.audioElement.pause();
    }
  }
  typeIsPlaying() {
    return this.audio.audioElement && !this.audio.audioElement.paused;
  }
  typeClick() {
    if(this.audio.audioElement) {
      if(this.audio.audioElement.paused) {
        this.playAudio();
      }
      else {
        this.pauseAudio();
      }
    }
  }
  typeWheel(dir) {
    var vol = this.audio.audioElement.volume;
    if(dir < 0) {
      vol = vol * 1.2;
      if(vol === 0) {
        vol = 0.02;
      }
    }
    else {
      vol = vol / 1.2;
    }
    vol = Math.max(vol, 0);
    vol = Math.min(vol, 1);
    this.audio.audioElement.volume = vol;
  }
  typeDraw(x, y, w, h) {
    let drawX = 0;
    let drawY = 0;
    if(this.isPlaying()) {
      this.p.fill(0, 100, 150);
    }
    else {
      // #212522
      this.p.fill(33, 37, 34);
    }
    this.p.stroke(0,0,0,0);
    this.p.rect(drawX, drawY, w, h);
    if(this.name) {
      this.p.stroke(0);
      this.p.fill(0);
      this.p.strokeWeight(1);
      var textSize = this.textSize * this.screenScale();
      this.p.textSize(textSize);
      this.p.strokeWeight(this.screenScale());
      this.p.text(this.name, drawX, drawY + h / 2);
    }
  }
}

export default AudioFragment;

import p5 from 'p5';
import './img/favicon.ico';
import * as input from './input.js';
import * as positions from './positions.js';
import * as space_module from './space.js';
import * as selection from './selection.js';
import * as settings from './settings.js';
import * as messages from './messages.js';
import {reverse} from './utilities/utilities.js';
import {initUI, drawUI} from './userinterface/ui.js';
import * as ui from './userinterface/ui.js';
import * as text_module from './userinterface/text.js';

import * as api_space from './api/space.js';
import * as api_login from './api/login.js';
import * as api_admin from './api/admin.js';
import * as api_fragments from './api/fragments.js';

// expose global object to allow interaction from javascript console
// this should only be used for testing, not in userscripts, as we
// will not maintain a stable interface
// DANGER not stable
window.nota_code = {};
nota_code.api_admin = api_admin;
// global storage for javascript console testing
// DANGER not stable
window.nota_storage = {};

let myFont = null;
export function getFontName() {
  // TODO  SVG building related
  // let name = MyFont.split('.')[0];
  // return name;
  return "Arial";
}
export function getFont() {
  return myFont;
}
export function fullscreenMobile() {
  if(/Android|webOS/i.test(navigator.userAgent)) {
    return true;
  }
  else {
    return false;
  }
}

export function isMobile() {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  else {
    return false;
  }
}

let textElementHTMLOverlays = null;
export function getSketch() {
  return sketch;
}
let sketch = null;
export let renderer = null;
var $zoomPage = document.getElementById('zoom-page');
export let divWidth = $zoomPage.clientWidth;

export function getArea() {
  return renderer.width * renderer.height;
}

document.onwheel = function() {
  return false;
}


export function setHTMLOverlay(overlay) {
  textElementHTMLOverlays = overlay;
}
export function getHTMLOverlay(overlay) {
  return textElementHTMLOverlays;
}

function downloadObject(name, obj) {
  var stringData = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
  var downloadElement = document.getElementById('downloadElement');
  downloadElement.setAttribute("href",     stringData);
  downloadElement.setAttribute("download", name + ".json");
  downloadElement.click();
}

function sketchInit(p) {
  sketch = p;
  renderer = p;
  initUI(p);
  p.preload = function() {
    // TODO to choose arbitrary font, it must somehow be made available
    // to text -> SVG building process
    // p.loadFont(myFont);
    myFont = 'Arial';
  };
  p.setup = function() {
    renderer.noSmooth();
    p.angleMode(p.DEGREES);
    p.textFont(getFont());
    let searchParams = (new URL(document.location)).searchParams;
    let username = searchParams.get("user");
    let spacename = searchParams.get("room");
    if(username !== null && spacename !== null) {
      space_module.loadThenInitSpace(username, spacename, false);
    }
    else {
      space_module.loadThenInitSpace("nota", "default", false);
    }
    renderer.frameRate(30);
    // TODO load data from backend
    let background = settings.COLORS.BACKGROUND;
    renderer.background(background[0], background[1], background[2], 255);
    let height = $zoomPage.clientHeight;
    let width = $zoomPage.clientWidth;
    positions.changeTranslationBy($zoomPage.offsetLeft, $zoomPage.offsetTop)
    renderer.createCanvas(width, height);
    function resizeCanvas() {
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      renderer.resizeCanvas(w, h);
    }
    window.addEventListener('orientationchange', function() {
      resizeCanvas();
    }, false);
    window.addEventListener('resize', function() {
      resizeCanvas();
    }, false);
    input.initInputModule(p);
    document.getElementById('loadinganimation').remove()
    // setup end
    if(localStorage.getItem('noLoop') === 'yes')
      renderer.noLoop();
  };

  var FR = null;


  // The processing main loop
  // this loop draws notas spaces and fragments a number of times a second onto the canvas
  // For a demonstration of the canvas see this link: https://nota.space/?user=stine&room=backstage
  // and move the text fragment saying 'what' around
  p.draw = function() {
    // reset cursor to arrow at the beginning of each frame
    p.cursor(p.ARROW);
    if(space_module.firstFrameFinished() && !space_module.firstFrameFinishedHandled()) {
      // refresh text after first frame, to load changes from user scripts
      text_module.shouldRefreshText(true);
      space_module.firstFrameFinishedHandled(true);
    }
    let shouldRefreshText = text_module.shouldRefreshText();
    if(space_module.spaceIsLoading) {
      p.text("LOADING", 200, 200);
    }

    renderer.noSmooth();
    p.textFont(getFont());
    // if input module not loaded yet, do nothing in this main loop
    // probably this can be removed now
    if(input === null) {
      messages.error("input is null, set main.js");
      return;
    }
    input.loop();
    let background = settings.COLORS.BACKGROUND;
    renderer.background(background[0], background[1], background[2], 255);

    renderer.fill(255);

    // collide mouse
    var hovered = false;
    var hoveredFragment = null;
    let potentialHover = input.shouldContinueAsHover();
    reverse(positions.getAll(), function(fragment, idx) {
      fragment.precalc(potentialHover);
      fragment.manageState(potentialHover);
      if(fragment.grabbed) {
        potentialHover = false;
        if(hoveredFragment) {
          hoveredFragment.hovered = false;
          hoveredFragment.isWithinBorder = false;
        }
        hoveredFragment = fragment;
        fragment.hovered = true;
      }
      else if(fragment.hovered /*|| fragment.isWithinBorder*/) {
        hoveredFragment = fragment;
        fragment.hovered = true;
        potentialHover = false;
      }
    });
    if(hoveredFragment !== null) {
      // needs to happen AFTER manageState for all fragments is done
      // because that sets steady false!
      selection.setSteadyForHovered(hoveredFragment);
    }

    // draw
    positions.getOnScreenFragments().forEach(function(fragment, idx) {
      if(shouldRefreshText) {
        if(fragment.getTypename() === 'text') {
          fragment.buildTextSvg();
        }
      }
      fragment.draw();
    });

    // draw selection frame
    selection.drawFrame();

    if(FR === null) {
      FR = renderer.frameRate();
    }
    else {
      var alph = 0.9;
      FR = alph * FR + (1-alph) * renderer.frameRate();
    }
    ui.updateFPS(Math.round(FR));

    ui.updateLoading(space_module.isLoading(), renderer.isLooping());
    let all = positions.getAll().length;
    if(space_module.isLoading())
    {
      ui.updateSelectionInfo(`0`);
      ui.updateSpace({
        name: "loading...",
        username: "",
        count: 0,
      });
    }
    else
    {
      let selected = selection.getSelectedFragments().length;
      ui.updateSelectionInfo(`${selected}`);

      let space = api_space.getSpace()
      ui.updateSpace({
        name: space.name,
        username: space.username,
        count: all,
      });
    }

    ui.updateBrakesInfo();

    let pushed = api_fragments.isPushed();
    ui.updateStatusInfo(pushed);
    ui.respondToScreenWidth(window.innerWidth);

    ui.drawUserPositions();

    // drawing done
    // var drawTime = p.millis() - timer;
    // timer = p.millis();

    // var miscTime = p.millis() - timer;
    positions.setPositionChanged(false);
    // only reset this flag, if it is the same as at the beginning of the frame,
    // to allow >= one full frame with reset true
    if(shouldRefreshText && text_module.shouldRefreshText()) {
      text_module.shouldRefreshText(false);
    }
    if(space_module.isInitialized() && !space_module.firstFrameFinishedHandled()) {
      space_module.firstFrameFinished(true);
    }
  }; // draw end
};

var activated = false;
if(!activated) {
  p5.disableFriendlyErrors = true;
  new p5(sketchInit, 'zoom-sketch');
  activated = true;
}


import * as positions from '../positions.js';
import * as multiuser from '../multiuser.js';
import {BaseFragment} from '../fragment/base_fragment.js';
import * as main from '../main.js';
import * as msg from '../messages.js';
import * as api_fragments from '../api/fragments.js';
import * as selection from '../selection.js';


// functions that create / copy / manipulate / delete fragments and push the changes
// to the backend API / database
export function _copyAndAppend(fragment, dx, dy) {
  let sketch = main.getSketch();
  var fragmentCopy = BaseFragment.restore(sketch, fragment.getPersistence());
  fragmentCopy.created = new Date();
  fragmentCopy.updated = null;
  fragmentCopy.setScriptAuthorToCurrectUser();
  let globalDiff = positions.screenToGlobalDiff({dx: dx, dy: dy})
  dx = globalDiff.dx;
  dy = globalDiff.dy;
  const sync = false;
  positions.addFragmentsOnTop([fragmentCopy], sync);
  positions.moveFragment(fragmentCopy, dx, dy);
  return fragmentCopy;
}
export function _createFragments(fragments) {
  const fragmentsToCreate = fragments.filter(fragment => fragment.persistent);
  api_fragments.create(fragmentsToCreate).then(function(success) {
    if(!success) {
      console.error("error creating fragment");
      fragmentsToCreate.forEach(fragment => { fragment.synced = false });
    }
    else {
      multiuser.sendFragmentAddedInfoByIDs(fragmentsToCreate.map((f) => f.id));
    }
  });
}
export function _deleteFragments(fragments) {
  const fragmentsToDelete = fragments.filter(fragment => fragment.persistent);
  api_fragments.del(fragmentsToDelete).then(function(success) {
    if(!success) {
      console.error("error removing fragment");
      fragmentsToDelete.forEach(fragment => fragment.synced = false);
    }
    else {
      multiuser.sendFragmentRemovedInfoByIDs(fragmentsToDelete.map((f) => f.id));
    }
    fragmentsToDelete.forEach(fragment => fragment.isDeleted = true);
  }).catch(function(err) {
    console.error(err);
    fragmentsToDelete.forEach(fragment => fragment.synced = false);
  });
  selection.removeFromSelection(fragments);
}
/**
 * _updateFragments accepts a list of fragments and pushes all the
 * fragments that are set to persistent to the database.
 */
export function _updateFragments(fragments, reAdd=false) {
  let frags = [];
  fragments.forEach(function(frag) {
    if(frag.persistent && frag.canSync()) {
      frags.push(frag);
    }
  });
  let fragIDs = frags.map(frag => frag.id);
  if(frags.length === 0) {
    return;
  }
  function failFun(err) {
    msg.error('Could not save fragment(s). Try reloading the website.');
    console.error(err);
    frags.forEach(function(frag) {
      frag.synced = false;
    });
  }
  api_fragments.batch_update(frags).then(function(success) {
    if(!success) {
      failFun("error changing fragment");
    }
    else {
      multiuser.sendFragmentUpdatedInfoByIDs(fragIDs);
      if(reAdd) {
        multiuser.sendFragmentAddedInfoByIDs(fragIDs);
      }
    }
  }).catch(function(err) {
    failFun(err);
  });
}

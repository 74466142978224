import {checkWithin} from '../utilities/utilities.js';
import * as settings from '../settings.js';
import * as login from '../api/login.js';
import * as admin from '../api/admin.js';
import * as api_space from '../api/space.js';
import * as api_fragments from '../api/fragments.js';
import {message, error} from '../messages.js';
import * as positions from '../positions.js';
import * as space_module from '../space.js';
import * as sketch from '../main.js';
import * as multiuser from '../multiuser.js';
import * as image_cutouts from '../userinterface/image_cutouts.js';
import * as text_module from '../userinterface/text.js';
import icons from './icons.js';

let surface = {
  left_menu_head: {},
  perf_menu_head: {},
  selection: {},
  space: { name: {}, count: {}, username: {} },
  fps: {},
  fps2: {},
  brakes: {},
  status: {},
  sync: {},
  tools: {},
  shelf: {},
  actions: {},
  tips: {},
  messages: {},
  center: {},
  atips: {},
};

export function getToolButton(tool) {
  return surface.tools[tool];
}

export function addMessage(msg) {
  let e = de('div', {class: 'message-item disappear', textContent: msg.text})
  if(msg.type === 'log')
  {
    e.classList.add('log');
  }
  else if(msg.type === 'error')
  {
    e.classList.add('error');
  }
  e.style.animationDuration = `${msg.dur}ms`;
  surface.messages.e.prepend(e);
  return e;
}

export function removeMessage(e) {
  surface.messages.e.removeChild(e);
}

export function updateTip(t) {
  if(surface.tips[t])
  {
    let l = surface.tips.t;
    if(l != t)
    {
      surface.tips.e.textContent = surface.tips[t];
      surface.tips.t = t;
    }
  }
}

export function updateAip(t) {
  if(surface.atips.e)
  {
    let l = surface.atips.t;
    if(l != t)
    {
      if(t)
      {
        surface.atips.e.classList.remove('hide')
        surface.atips.e.textContent = surface.atips[t];
      }
      else
      {
        surface.atips.e.classList.add('hide')
        surface.atips.e.textContent = '';
      }
      surface.atips.t = t;
    }
  }
}

export function updateTool(t, s, r, isMobile) {
  if(surface.tools[t])
  {
    let l = surface.selected_tool;
    if(l != t)
    {
      if(surface.tools[l])
      {
        surface.tools[l].classList.remove('selected');
      }
      surface.tools[t].classList.add('selected');
      surface.tips.e.textContent = surface.tips[t];
      surface.tips.t = t;
      surface.selected_tool = t;
    }

    if(surface.tools.s !== s)
    {
      surface.tools.s = s;
      {
        let e = surface.tools[settings.TOOLS.WALK];
        if(e)
        {
          if(s)
          {
            e.classList.add('nokey');
          }
          else
          {
            e.classList.remove('nokey');
          }
        }
      }
      {
        let e = surface.actions[settings.ACTIONS.DESELECT];
        if(e)
        {
          if(s)
          {
            e.classList.remove('nokey');
          }
          else
          {
            e.classList.add('nokey');
          }
        }
      }
    }
  }

  if(surface.tools[r] && !isMobile)
  {
    if(surface.tools.r != r)
    {
      let l = surface.tools.r;
      surface.tools.r = r;
      if(r && surface.selected_tool !== r)
      {
        surface.tools[r].append(surface.ri);
      }
      else
      {
        let lastParent = surface.ri.parentElement;
        if(lastParent)
          lastParent.removeChild(surface.ri);
      }
    }
  }
}

export function updateStatusInfo(t) {
  let color = t ? settings.COLORS.CLEAN : settings.COLORS.DIRTY;
  let ca = `rgb(${color.join(',')})`;
  if(surface.status.e)
  {
    if(surface.status.t !== t)
    {
      surface.status.t = t;
      surface.status.e.style.backgroundColor = ca;
    }
  }
  if(surface.sync.e)
  {
    if(login && !login.loggedIn())
    {
      if(surface.sync.t !== 'no-login')
      {
        surface.sync.t = 'no-login';
        surface.sync.e.style = '';
        surface.sync.e.textContent = 'Login to sync data';
      }
    }
    else if(surface.sync.t !== t)
    {
      surface.sync.t = t;
      surface.sync.e.style.color = ca;
      if(t)
      {
        surface.sync.e.textContent = 'In sync with server';
      }
      else
      {
        surface.sync.e.textContent = 'Not in sync with server';
      }
    }
  }
}

export function updateBrakesInfo() {
  if(surface.brakes.e)
  {
    let b = settings.brakesOn();
    if(surface.brakes.s !== b)
    {
      let text = 'brakes: ' + (b ? 'on' : 'off');
      surface.brakes.e.textContent = text;
      surface.brakes.s = b;
      let p = surface.actions[settings.ACTIONS.BRAKES];
      let i = p.firstChild;
      if(b)
      {
        i.outerHTML = icons.brake;
        p.style.backgroundColor = '';
      }
      else
      {
        i.outerHTML = icons.nobrake;
        p.style.backgroundColor = 'rgb(255, 160, 223)';
      }
    }
  }
}

export function updateSelectionInfo(t) {
  if(surface.selection.e)
  {
    if(surface.selection.t != t)
    {
      surface.selection.te.textContent = t;
      surface.selection.t = t;

      let e = surface.selection.e;
      if(e)
      {
        if(t < 1)
        {
          e.classList.add('hide');
        }
        else
        {
          e.classList.remove('hide');
        }
      }
    }
  }
}

export function updateSpace(o)
{
  if(surface.space.name.e)
  {
    let t = o.name;
    if(surface.space.name.t != t)
    {
      surface.space.name.e.textContent = t;
      surface.space.name.e2.textContent = `Space: ${t}`;
      surface.space.name.t = t;
    }
  }
  if(surface.space.count.e)
  {
    let t = o.count;
    if(surface.space.count.t != t)
    {
      surface.space.count.e.textContent = `Fragments: ${t}`;
      surface.space.count.e2.textContent = t;
      surface.space.count.t = t;
    }
  }
  if(surface.space.username.e)
  {
    let t = o.username;
    if(surface.space.username.t != t)
    {
      surface.space.username.e.textContent = `User: ${t}`;
      surface.space.username.t = t;
    }
  }
}

export function updateFPS(t) {
  if(surface.fps.e)
  {
    if(surface.fps.t != t)
    {
      surface.fps.e.textContent = `Frames: ${t}/s`;
      surface.fps.t = t;
    }
  }
  if(surface.fps2.e)
  {
    if(surface.fps2.t != t)
    {
      surface.fps2.e.textContent = t.toString().padStart(2, "0");
      surface.fps2.t = t;
    }
  }
}

export function updateLoading(l, n) {
  if(surface.center.e)
  {
    if(surface.center.l != l)
    {
      if(l)
        surface.center.e.classList.add('hide');
      else
        surface.center.e.classList.remove('hide');
      surface.center.l = l;
    }
  }
  if(surface.tips.e)
  {
    if(surface.tips.n != n)
    {
      if(n)
        surface.tips.e.classList.remove('noloop');
      else
        surface.tips.e.classList.add('noloop');
      surface.tips.n = n;
    }
  }
}

export function respondToScreenWidth(w) {
  if(surface.selection.e)
  {
    if(surface.selection.w !== w)
    {
      if(w < 900)
      {
        surface.left_menu_head.append(surface.selection.e);
      }
      else
      {
        surface.perf_menu_head.prepend(surface.selection.e);
      }
      surface.selection.w = w;
    }
  }
  let toolbox = document.querySelector('.toolbox');
  if(surface.shelf.e)
  {
    if(surface.shelf.w !== w)
    {
      surface.shelf.w = w;
      // gather the shelf back for reorganization.
      toolbox.append(...surface.shelf.e.children);
      toolbox.append(surface.shelf.b);

      let ntools = toolbox.children.length-1;
      if(ntools * 50 > w)
      {
        let a = []
        for(let i = 0; i < ntools; i++)
        {
          // 2 for the extra button.
          if((i+2)*50 > w)
            a.push(toolbox.children[i]);
        }
        surface.shelf.e.append(...a);
        surface.shelf.b.classList.remove('hide');
      }
      else
      {
        surface.shelf.b.classList.add('hide');
      }
    }
  }
}

let surfaceEventHandler = undefined;
export function setSurfaceEventHandler(f) {
  surfaceEventHandler = f;
}

export let allowReplace = true;

String.prototype.toHSL = function(opts) {
      var h, s, l;
      opts = opts || {};
      opts.hue = opts.hue || [0, 360];
      opts.sat = opts.sat || [75, 100];
      opts.lit = opts.lit || [40, 60];

      var range = function(hash, min, max) {
                var diff = max - min;
                var x = ((hash % diff) + diff) % diff;
                return x + min;
            }

      var hash = 0;
      if (this.length === 0) return hash;
      for (var i = 0; i < this.length; i++) {
                hash = this.charCodeAt(i) + ((hash << 5) - hash);
                hash = hash & hash;
            }

      h = range(hash, opts.hue[0], opts.hue[1]);
      s = range(hash, opts.sat[0], opts.sat[1]);
      l = range(hash, opts.lit[0], opts.lit[1]);

      return {h:h,s:s,l:l};
}
function handleKeyDown(ev) {
  switch (ev.key) {
    case "Esc": // check "Esc" for browser compatibility
    case "Escape":
      clearHTMLUi();
      window.removeEventListener('keydown', handleKeyDown);
      ev.preventDefault();
      break;
  }
}
let overlayActive = null;
let skt = null;
let rend = null;

export function drawUserPositions() {
  if(nota_storage.drawConnections) {
    rend.push();
    rend.fill(50, 150, 200);
    rend.noStroke();
    let count = nota_storage.connections_count;
    let size = 7;
    let x = size/2;
    let y = rend.height - size * 1.5;
    for(let i = 0; i<count; i++) {
      rend.rect(x, y, size, size);
      x += size * (1.7);
    }
    rend.pop();
  }
  rend.push();
  let other_user_positions = multiuser.other_user_positions;
  rend.colorMode(rend.HSL);
  Object.keys(other_user_positions).forEach(function(key) {
    let view = other_user_positions[key];
    let pos = positions.globalToScreenCoords({
      x: view.x,
      y: view.y
    });
    let size = positions.globalToScreenSize({
      w: view.w,
      h: view.h
    });
    rend.noFill();
    let col = key.toHSL();
    rend.stroke(col.h, col.s, col.l);
    rend.rect(pos.x, pos.y, size.w, size.h);
  });
  rend.pop();
}


function de(n, a) {
  let e = document.createElement(n);
  if(a) {
    for(let i in a)
    {
      if(i === 'textContent')
      {
        e.textContent = a[i];
      }
      else
      {
        e.setAttribute(i, a[i]);
      }
    }
  }
  return e;
}

export function initUI(p) {
  // module wide
  skt = p;
  rend = p;

  const registerActionButton = (i, e, tip) => {

    surface.actions[i] = e;
    surface.atips[i] = tip;
    e.onclick = (e) => {
      if(surfaceEventHandler)
      {
        surfaceEventHandler({action: i, t: 'action'});
      }
    }
    e.onmousemove = (e) => {
      updateAip(i);
    }
    e.onmouseout = (e) => {
      setTimeout(() => {
        if(surface.atips.t === i)
          updateAip();
      }, 200);
    }
  }

  {
    let box = document.querySelector('.perf-cell')

    box.classList.add('collapsed');

    let menu_head = de('div', {class: 'menu-head'});
    surface.perf_menu_head = menu_head;
    box.append(menu_head);

    {
      let item = de('div', { class: 'surface-item select-count context hide' })
      item.style.backgroundColor = `#fcfc8d`
      menu_head.append(item);
      registerActionButton(settings.ACTIONS.DESELECT, item, 'deselect all')

      let ie = de('div', { class: 'w' });
      item.append(ie);
      ie.innerHTML = icons.select;

      let te = de('span');
      item.append(te);
      surface.selection = {e: item, te: te};

      item.append(de('div', {class: 'keybinding wide top', textContent: "ESC"}));
    }

    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.clipboard;
      e.append(de('div', {class: 'keybinding wide top', textContent: "c C"}));
      menu_head.append(e);
      registerActionButton( settings.ACTIONS.CLIPBOARD_COPY, e, 'copy to clipboard (CTRL + C)' );
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.paste;
      e.append(de('div', {class: 'keybinding wide top', textContent: "c V"}));
      menu_head.append(e);
      registerActionButton( settings.ACTIONS.CLIPBOARD_PASTE, e, 'paste from clipboard (CTRL + V)' );
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.undo;
      e.append(de('div', {class: 'keybinding wide top', textContent: "c Z"}));
      menu_head.append(e);
      registerActionButton( settings.ACTIONS.UNDO, e, 'undo last action (CTRL + Z)' );
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.redo;
      e.append(de('div', {class: 'keybinding wide top', textContent: "c Y"}));
      menu_head.append(e);
      registerActionButton( settings.ACTIONS.REDO, e, 'redo last undo (CTRL + Y)' );
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.trash;
      e.append(de('div', {class: 'keybinding wide top', textContent: "DEL"}));
      menu_head.append(e);
      registerActionButton( settings.ACTIONS.DELETE, e, 'delete selected' );
    }
    {
      let e = de('div', {class: 'surface-item large4 icon'});
      menu_head.append(e);
      e.innerHTML = icons.brake;
      let kbe = de('div', {class: 'keybinding spaceicon fit top'});
      e.append(kbe);
      kbe.innerHTML = icons.spacebar;
      registerActionButton( settings.ACTIONS.BRAKES, e, 'toggle brakes (SPACEBAR)' );
    }
    {
      let button = de('div', { class: 'surface-item icon context hide-on-narrow' })
      menu_head.append(button);
      button.innerHTML = icons.down_arrow;

      button.onclick = (e) => {
        box.classList.toggle('collapsed');
      }

      let ie = de('div', { class: 'w' });
      button.append(ie);
      ie.innerHTML = icons.gear;
    }

    {
      let e = de('div', {
        class: 'surface-item text icon hide tips-box hover-tips',
      });
      surface.atips.e = e;
      box.append(e);
    }

    let collapse_box = de('div', {class: 'collapse-box'})
    box.append(collapse_box);

    {
      let getText = () =>
        'zoom mode: ' +
        (settings.getZoomMode() === settings.ZoomMode.TRACKPAD ? 'trackpad' : 'mouse');
      let button = de('div', {
        class: 'surface-item hide-on-narrow',
        textContent: getText()
      });
      button.onclick = (e) => {
        if(settings.getZoomMode() === settings.ZoomMode.TRACKPAD)
          settings.setZoomMode(settings.ZoomMode.MOUSE);
        else
          settings.setZoomMode(settings.ZoomMode.TRACKPAD);

        button.textContent = getText();
      };
      collapse_box.append(button);
    }

    {
      let getText = () =>
        'text borders: ' +
        (settings.getTextBorder() ? 'yes' : 'no');

      let button = de('div', {
        class: 'surface-item',
        textContent: getText(),
      });
      button.onclick = (e) => {
        if(settings.getTextBorder())
        {
          settings.setTextBorder(false);
          message('texts have no borders');
        }
        else
        {
          settings.setTextBorder(true);
          message('texts have borders');
        }
        text_module.shouldRefreshText(true);
        button.textContent = getText();
      }
      collapse_box.append(button);
    }

    {
      let getText = () =>
        'user scripts: ' +
        (settings.USER_SCRIPTS_ACTIVE ? 'enabled' : 'disabled');
      let button = de('div', {
        class: 'surface-item',
        textContent: getText(),
      });
      button.onclick = (e) => {
        if(settings.USER_SCRIPTS_ACTIVE)
        {
          settings.setUserScriptsActive(false);
          message('scripts are stopped now');
        }
        else
        {
          settings.setUserScriptsActive(true);
          message('scripts are running now');
        }
        button.textContent = getText();
      };
      collapse_box.append(button);
    }

    {
      let button = de('div', {
        class: 'surface-item'
      });
      let te = de('span');
      button.append(te);
      surface.brakes.e = te;
      button.append(de('div', {class:'keybinding fit', textContent: 'SPACEBAR'}));

      button.onclick = (e) => {
        if(surfaceEventHandler)
        {
          surfaceEventHandler({action: settings.ACTIONS.BRAKES, t: 'action'});
        }
      };
      collapse_box.append(button);
    }
  }

  let updateMenus = () => {
    {
      let esurface = document.querySelector('.surface');
      let tbox = document.querySelector('.surface-top')
      let box = document.querySelector('.entr-cell')
      while(box.firstChild)
        box.removeChild(box.firstChild);

      box.classList.add('collapsed');
      tbox.classList.add('collapsed-perf');

      let menu_head = de('div', {class: 'menu-head'});
      surface.left_menu_head = menu_head;
      box.append(menu_head);

      {
        let button = de('div', {class: 'surface-item icon ninja'});
        button.innerHTML = icons.ninja;
        registerActionButton( settings.ACTIONS.HIDE_ALL, button, "hide/show interface" );
        menu_head.append(button);
      }

      {
        let button = de('div', {
          class: 'surface-item icon'
        })
        button.onclick = (e) => {
          box.classList.toggle('collapsed');
          tbox.classList.toggle('collapsed');
          esurface.classList.toggle('cover');
        }
        button.innerHTML = icons.down_arrow;
        menu_head.append(button);
      }

      let spaceInfoBox;
      surface.space = { name: {}, count: {}, username: {} };
      {
        let item = de('div', {
          class: 'surface-item text context',
          textContent: ''
        })
        menu_head.append(item);
        {
          let e = de('span', {class: 'name-ellipsis'});
          surface.space.name.e = e;
          item.append(e);
        }
        item.ontouchstart = () => false;
        item.onmousemove = () => {
          spaceInfoBox.classList.remove('hide-info');
        };
        item.onmouseout = () => {
          spaceInfoBox.classList.add('hide-info');
        };
        {
          let e = de('div', {class: 'c'});
          item.append(e);
          surface.space.count.e2 = e;
        }
      }

      {
        let item = de('div', {
          class: 'surface-item text space-info hide-info hover-tips',
          textContent: ''
        })
        spaceInfoBox = item;
        {
          let e = de('span', {});
          item.append(e)
          surface.space.name.e2 = e;
        }
        {
          let e = de('span', {});
          item.append(e)
          surface.space.username.e = e;
        }
        {
          let e = de('span', {});
          item.append(e)
          surface.space.count.e = e;
        }
        box.append(item);
      }

      {
        let button = de('div', { class: 'surface-item icon large4 hide' });
        button.innerHTML = icons.center;
        registerActionButton( settings.ACTIONS.CENTER, button, 'center of space');
        menu_head.append(button);
        surface.center = {e: button};
      }
      if(login.loggedIn()) {
        let button = de('div', {class: 'surface-item icon large4 ninja'})
        button.innerHTML = icons.home;
        registerActionButton( settings.ACTIONS.HOME, button, 'your home space');
        button.onclick  = (e) => {
          loadSpaceList();
        }
        menu_head.append(button);
      }

      if(sketch.fullscreenMobile() && document.fullscreenEnabled)
      {
        let button = de('div', { class: 'surface-item icon large6' });
        button.innerHTML = icons.maximize;
        registerActionButton( settings.ACTIONS.FULLSCREEN, button, 'toggle fullscreen (or ESC to exit)' );
        menu_head.append(button);
      }
      {
        let button = de('div', { class: 'surface-item icon context to-perf-shelf' })
        menu_head.append(button);
        button.innerHTML = icons.down_arrow;

        button.onclick = (e) => {
          tbox.classList.toggle('collapsed-perf');
        }

        let ie = de('div', { class: 'w' });
        button.append(ie);
        ie.innerHTML = icons.toolbox;
      }

      let collapse_box = de('div', {class: 'collapse-box'})
      box.append(collapse_box);

      {
        let button = de('div', {class: 'surface-item', textContent:'nota.space'})
        button.onclick = (e) => {
          loadNotaDefault();
        }
        collapse_box.append(button);
      }

      if(login.loggedIn())
      {

        {
          let button = de('div', {class: 'surface-item', textContent:'spaces'})
          button.onclick  = (e) => {
            loadSpaceList();
          }
          collapse_box.append(button);
        }

        {
          let button = de('div', {class: 'surface-item', textContent:'load a space'})
          button.onclick = (e) => {
            showLoadSpace();
          }
          collapse_box.append(button);
        }

        {
          let button = de('div', {class: 'surface-item', textContent:'new space'})
          button.onclick = (e) => {
            showCreateSpace();
          }
          collapse_box.append(button);
        }
      }
    }

    {
      let box = document.querySelector('.help-cell')

      while(box.firstChild)
        box.removeChild(box.firstChild);

      box.classList.add('collapsed');

      let menu_head = de('div', {class: 'menu-head'});
      box.append(menu_head);
      surface.fps = {};

      let userInfoBox = null;
      if(login.loggedIn())
      {
        let button = de('div', {
          class: 'surface-item icon hide-on-narrow'
        })
        button.onclick = (e) => {
          box.classList.toggle('collapsed');
        }
        button.innerHTML = icons.down_arrow;
        menu_head.append(button);

        let user_item = de('div', {
          class: 'surface-item text context',
          textContent: login.getCreds().username
        })
        user_item.onmousemove = () => {
          userInfoBox.classList.remove('hide-info');
        };
        user_item.onmouseout = () => {
          userInfoBox.classList.add('hide-info');
        };
        menu_head.append(user_item);

        let status_item = de('div', {class: 's'});
        surface.status = {e: status_item};
        user_item.append(status_item);

        let fps_item = de('div', {class: 'c'});
        surface.fps2 = {e: fps_item};
        user_item.append(fps_item);

      }
      else
      {
        surface.status = {};
        surface.sync = {};

        {
          let button = de('div', {
            class: 'surface-item',
            textContent:'login'
          })
          button.onclick = (e) => {
            showLogin()
          };
          menu_head.append(button);
        }

        {
          let e = de('div', { class: 'surface-item text' })
          menu_head.append(e);
          surface.fps2 = {e}
          e.onmousemove = () => {
            userInfoBox.classList.remove('hide-info');
          };
          e.onmouseout = () => {
            userInfoBox.classList.add('hide-info');
          };
        }
      }

      {
        let button = de('div', {class: 'surface-item icon'});
        button.innerHTML = icons.question;
        button.onclick = (e) => {
          loadNotaHelp();
        }
        menu_head.append(button);
      }

      {
        let button = de('div', {class: 'surface-item icon'});
        button.innerHTML = icons.info;
        button.onclick = (e) => {
          loadNotaInfo();
        }
        menu_head.append(button);
      }

      {
        let item = de('div', {
          class: 'surface-item text user-info hide-info hover-tips',
        })
        box.append(item);
        {
          let e = de('span')
          item.append(e);
          surface.fps.e = e;
        }
        {
          let e = de('span')
          item.append(e);
          surface.sync = {e: e};
        }
        userInfoBox = item;
      }


      if(login.loggedIn())
      {

        let collapse_box = de('div', {class: 'collapse-box'})
        box.append(collapse_box);

        {
          let button = de('div', {class: 'surface-item', textContent:'change password'})
          button.onclick = (e) => {
            showChangePassword()
          }
          collapse_box.append(button);
        }

        {
          let button = de('div', {class: 'surface-item', textContent:'logout'})
          button.onclick = (e) => {
            login.logout();
            message('Logged out');
          }
          collapse_box.append(button);
        }
      }
    }
  }
  updateMenus();
  login.onAuthChange(updateMenus);

  {
    let sbox = document.querySelector('.tool-cell')
    sbox.classList.add('collapsed');

    {
      let e = de('div', {
        class: 'surface-item text icon tips-box',
        textContent: 'hello'
      });
      e.ontouchstart = () => false;
      surface.tips = { e: e };
      sbox.append(e);
    }

    let box = de('div', {class: 'toolbox'});
    sbox.append(box);

    {
      let e = de('div', {class: 'r'})
      e.innerHTML = icons.rightmouse;
      surface.ri = e;
    }

    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.walk;
      e.append(de('div', {class: 'keybinding wide', textContent: "ESC"}));
      box.append(e);
      surface.tools[settings.TOOLS.WALK] = e;
      surface.tips[settings.TOOLS.WALK] = 'mouse to pan, wheel to zoom';
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.arrows_cardinal;
      e.append(de('div', {class: 'keybinding', textContent: "F"}));
      box.append(e);
      surface.tools[settings.TOOLS.FRAGMENT] = e;
      surface.tips[settings.TOOLS.FRAGMENT] = 'mouse to move, wheel to scale';
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.copy;
      e.append(de('div', {class: 'keybinding', textContent: "C"}));
      box.append(e);
      surface.tools[settings.TOOLS.COPY] = e;
      surface.tips[settings.TOOLS.COPY] = 'grab a copy';
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.select;
      e.append(de('div', {class: 'keybinding', textContent: "S"}));
      box.append(e);
      surface.tools[settings.TOOLS.SELECT] = e;
      surface.tips[settings.TOOLS.SELECT] = 'select one or more fragments';
    }
    {
      let e = de('div', {class: 'surface-item icon large10'});
      e.innerHTML = icons.over;
      e.append(de('div', {class: 'keybinding', textContent: "O"}));
      box.append(e);
      surface.tools[settings.TOOLS.OVER] = e;
      surface.tips[settings.TOOLS.OVER] = 'pull a fragment over all others';
    }
    {
      let e = de('div', {class: 'surface-item icon large10'});
      e.innerHTML = icons.under;
      e.append(de('div', {class: 'keybinding', textContent: "U"}));
      box.append(e);
      surface.tools[settings.TOOLS.UNDER] = e;
      surface.tips[settings.TOOLS.UNDER] = 'push a fragment under all others';
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.download;
      e.append(de('div', {class: 'keybinding', textContent: "D"}));
      box.append(e);
      surface.tools[settings.TOOLS.DOWNLOAD] = e;
      surface.tips[settings.TOOLS.DOWNLOAD] = 'download a fragment';
    }
    {
      let e = de('div', {class: 'surface-item icon'});
      e.innerHTML = icons.insert;
      e.append(de('div', {class: 'keybinding wide', textContent: "I"}));
      box.append(e);
      surface.tools[settings.TOOLS.INSERT] = e;
      surface.tips[settings.TOOLS.INSERT] = 'tap to upload a file';
    }
    {
      let e = de('div', {class: 'surface-item icon large6'});
      e.innerHTML = icons.textadd;
      e.append(de('div', {class: 'keybinding', textContent: "T"}));
      box.append(e);
      surface.tools[settings.TOOLS.TEXT] = e;
      surface.tips[settings.TOOLS.TEXT] = 'create text';
    }
    {
      let e = de('div', {class: 'surface-item icon large10'});
      e.innerHTML = icons.js;
      e.append(de('div', {class: 'keybinding', textContent: "P"}));
      box.append(e);
      surface.tools[settings.TOOLS.SCRIPT] = e;
      surface.tips[settings.TOOLS.SCRIPT] = 'attach a userscript';
    }
    {
      let b = de('div', {class: 'surface-item icon context large toshelf hide'});
      b.innerHTML = icons.up_arrow;
      b.onclick = () => {
        sbox.classList.toggle('collapsed');
      }
      box.append(b);
      let ie = de('div', { class: 't' });
      b.append(ie);
      ie.innerHTML = icons.tool;

      let e = de('div', {class: 'shelf'});
      sbox.append(e);
      surface.shelf = {b, e};
    }
  }

  {
    let box = document.querySelector('.messages-box');
    surface.messages.e = box;
  }

  for(let i in surface.tools)
  {
    let e = surface.tools[i];
    e.onclick = (e) => {
      if(surfaceEventHandler)
      {
        surfaceEventHandler({tool: i, t: 'tool'});
      }
    }
    e.onmousemove = (e) => {
      updateTip(i);
    }
    e.onmouseout = (e) => {
      setTimeout(() => {
        if(surface.tips.t === i)
          updateTip(surface.selected_tool);
      }, 200);
    }
  }
}

function loadNotaInfo() {
  let oldSpace = api_space.getSpace();
  let pushHistory = oldSpace && (
    oldSpace.name !== 'info' ||
    oldSpace.username !== 'nota'
  );
  api_space.loadByName('nota', 'info').then(function(success) {
    if(success) {
      let space = api_space.getSpace();
      space_module.initSpace(space, pushHistory);
    }
  });
};

function loadNotaHelp() {
  let oldSpace = api_space.getSpace();
  let pushHistory = oldSpace && (
    oldSpace.name !== 'help' ||
    oldSpace.username !== 'nota'
  );
  api_space.loadByName('nota', 'help').then(function(success) {
    if(success) {
      let space = api_space.getSpace();
      space_module.initSpace(space, pushHistory);
    }
  });
};

function loadNotaDefault() {
  let oldSpace = api_space.getSpace();
  let pushHistory = oldSpace && (
    oldSpace.name !== 'default' ||
    oldSpace.username !== 'nota'
  );
  api_space.loadByName('nota', 'default').then(function(success) {
    if(success) {
      let space = api_space.getSpace();
      space_module.initSpace(space, pushHistory);
    }
  });
};

function loadSpaceList() {
  let pushHistory = true;
  let curSpace = api_space.getSpace();
  if(login.loggedIn()) {
    let username = login.getUsername();
    let spacename = "default";
    if(curSpace !== null) {
      if(curSpace.name === spacename && curSpace.username === username) {
        pushHistory = false;
      }
    }
    api_space.loadByName(username, spacename).then(function(success) {
      if(success) {
        let space = api_space.getSpace();
        space_module.initSpace(space, pushHistory);
      }
    });
  }
}

function clearHTMLUi() {
  overlayActive = false;
  let uiContainer = document.getElementById('ui');
  let uiOverlay = document.getElementById('uiOverlay');
  while (uiContainer.firstChild) {
    uiContainer.removeChild(uiContainer.firstChild);
    uiContainer.style.display = 'none';
    uiOverlay.style.display = 'none';
  }
}
function isEnter(ev) {
  return ev.keyCode === 13 || ev.code === "Enter" || ev.code === "NumpadEnter";
}
function setHtmlElements(elements) {
  let uiContainer = document.getElementById('ui');
  let uiOverlay = document.getElementById('uiOverlay');
  clearHTMLUi();
  elements.forEach(function(element) {
    uiContainer.appendChild(element);
  });
  let xItem = de('div', {class: 'overlay-xmark'});
  xItem.innerHTML = icons.xmark;
  uiContainer.append(xItem);
  xItem.onpointerup = () => {
    clearHTMLUi();
  }

  uiOverlay.onpointerdown = (e) => {
    if(e.target === uiOverlay)
      clearHTMLUi();
  }

  uiContainer.style.display = 'flex';
  uiOverlay.style.display = 'flex';
  overlayActive = true;
}
function addFormError(m) {
  let item = de('div', {class: 'message-item disappear error'});
  item.textContent = m;
  item.style.animationDuration = '5000ms';
  let uiContainer = document.getElementById('ui');
  uiContainer.append(item);
  setTimeout(() => {
    uiContainer.removeChild(item);
  }, 5000);
}
async function showLoadSpace() {
  let userNameIn = document.createElement('input');
  userNameIn.placeholder = 'username name';
  userNameIn.type = 'text';
  userNameIn.id = 'username_input';
  if(login.loggedIn())
  {
    let currentUserName = login.getCreds().username;
    userNameIn.value = currentUserName
  }
  let spaceNameIn = document.createElement('input');
  spaceNameIn.placeholder = 'space name';
  spaceNameIn.type = 'text';
  spaceNameIn.id = 'spacename_input';

  async function callLoadSpace() {
    let username = userNameIn.value;
    let spacename = spaceNameIn.value;
    let success = await api_space.loadByName(username, spacename);
    if(success)
    {
      let space = api_space.getSpace();
      space_module.initSpace(space);
      clearHTMLUi();
    }
    else
    {
      addFormError('Failed to load space');
    }
  }
  [userNameIn, spaceNameIn].forEach((elem)=>{
    elem.addEventListener('keydown', function(ev) {
      if(isEnter(ev)) {
        callLoadSpace();
      }
      else if(ev.keyCode === 27) {
        clearHTMLUi();
      }
      else {
      }
    });
  });

  let textItem = de('span');
  textItem.textContent = 'Load space of a user';
  let button = de('button');
  button.onclick = callLoadSpace;
  button.textContent = 'submit';
  setHtmlElements([textItem, userNameIn, spaceNameIn, button]);
  spaceNameIn.focus();
}
async function showCreateSpace() {
  let spaceNameIn = document.createElement('input');
  spaceNameIn.placeholder = 'space name';
  spaceNameIn.type = 'text';
  spaceNameIn.id = 'spacename_input';
  let anonLabel = document.createElement('label');
  let anonIn = document.createElement('input');
  anonIn.type = 'checkbox';
  anonIn.id = 'anon_input';
  anonLabel.append(anonIn, 'Make public');

  async function callCreateSpace() {
    let spacename = spaceNameIn.value;
    let anon = anonIn.checked;
    let res = await api_space.create(spacename, anon);
    if(res.ok) {
      let username = login.getCreds().username;
      message(`Created space: "${spacename}" of user "${username}"`);
      let success = await api_space.loadByName(username, spacename);
      if(success)
      {
        let space = api_space.getSpace();
        space_module.initSpace(space);
        clearHTMLUi();
      }
      else
      {
        addFormError('Space is created but loading failed');
      }
    }
    else {
      addFormError(res.msg);
    }
  }
  [spaceNameIn, anonIn].forEach((elem)=>{
    elem.addEventListener('keydown', function(ev) {
      if(isEnter(ev)) {
        callCreateSpace();
      }
      else if(ev.keyCode === 27) {
        clearHTMLUi();
      }
      else {
      }
    });
  });

  let textItem = de('span');
  textItem.textContent = 'Create new space';
  let button = de('button');
  button.onclick = callCreateSpace;
  button.textContent = 'submit';
  setHtmlElements([textItem, spaceNameIn, anonLabel, button]);
  spaceNameIn.focus();
}
async function showChangePassword() {
  window.addEventListener('keydown', handleKeyDown);
  let currentPassIn = document.createElement('input');
  currentPassIn.placeholder = 'current password';
  currentPassIn.type = 'password';
  currentPassIn.id = 'current_password_input';

  let passIn = document.createElement('input');
  passIn.placeholder = 'new password';
  passIn.type = 'password';
  passIn.id = 'password_input';

  let passIn2 = document.createElement('input');
  passIn2.placeholder = 'repeat new password';
  passIn2.type = 'password';
  passIn2.id = 'password2_input';

  async function callChangePassword() {
    let current_password = document.getElementById('current_password_input').value;
    let password = document.getElementById('password_input').value;
    let password2 = document.getElementById('password2_input').value;
    if(password.length < 10) {
      addFormError('Please make your password is at least 10 characters');
      return;
    }
    if(password !== password2) {
      addFormError('password repeat does not match!');
      return;
    }
    try {
      const res = await login.change_password(current_password, password);
      let json = res.json;
      if(res.res.ok) {
        window.removeEventListener('keydown', handleKeyDown);
        clearHTMLUi();
        const username = login.getCreds().username;
        message('Password changed for ' + username);
      }
      else if(json && json === 'Wrong current password') {
        addFormError('Current password is wrong');
        console.error(res);
      }
      else {
        addFormError('Could not change password');
        console.error(res);
      }
    }
    catch(e) {
      console.error(e);
      message('Sorry, there was an error...');
    }
  }
  [currentPassIn, passIn, passIn2].forEach((elem)=>{
    elem.addEventListener('keydown', function(ev) {
      if(isEnter(ev)) {
        callChangePassword();
      }
      else if(ev.keyCode === 27) {
        clearHTMLUi();
      }
      else {
      }
    });
  });

  let textElement = document.createElement('span');
  const username = login.getCreds().username;
  textElement.textContent = 'Change password for user ' + username;
  let button = document.createElement('button');
  button.textContent = 'submit';
  button.onclick = callChangePassword;
  setHtmlElements([
    textElement,
    currentPassIn,
    passIn,
    passIn2,
    button,
  ]);
  currentPassIn.focus();
}

async function showLogin() {
  window.addEventListener('keydown', handleKeyDown);
  let nameIn = document.createElement('input');
  nameIn.placeholder = 'username';
  nameIn.type = 'text';
  nameIn.id = 'username_input';
  let passIn = document.createElement('input');
  passIn.placeholder = 'password';
  passIn.type = 'password';
  passIn.id = 'password_input';

  async function callLogin() {
    let username = document.getElementById('username_input').value;
    let password = document.getElementById('password_input').value;
    try {
      let res = await login.login(username, password);
      if(res.res.ok) {
        window.removeEventListener('keydown', handleKeyDown);
        clearHTMLUi();
        const username = login.getCreds().username;
        message('Logged in as ' + username);
      }
      else {
        console.error(res);
        let json = res.json;
        console.error(json);
        if(json['username'] !== undefined || json['password'] !== undefined) {
          addFormError('Error with username or password');
        }
        else if(json['non_field_errors'] !== undefined) {
          json['non_field_errors'].forEach((err)=>{
            addFormError(err);
          });
        }
      }
    }
    catch(e) {
      console.error(e);
    }
  }
  [nameIn, passIn].forEach((elem)=>{
    elem.addEventListener('keydown', function(ev) {
      if(isEnter(ev)) {
        callLogin();
      }
      else if(ev.keyCode === 27) {
        clearHTMLUi();
      }
      else {
      }
    });
  });

  let button = document.createElement('button');
  button.textContent = 'submit';
  button.onclick = callLogin;
  let textItem = de('span');
  textItem.textContent = 'Login to nota'
  setHtmlElements([textItem, nameIn, passIn, button]);
  nameIn.focus();
}
export function UIOverlayActive() {
  return !!overlayActive;
}

let rooms_by_script = null;
let scripts = null;
async function showSearch() {
  let searchInput = document.createElement('textarea');
  searchInput.placeholder = 'search';
  searchInput.id = 'search_input';
  searchInput.style.width='80%';
  searchInput.style.height='5em';

  let scriptArea = document.createElement('textarea');
  scriptArea.placeholder = '';
  scriptArea.id = 'scriptArea';
  scriptArea.style.width='80%';
  scriptArea.style.height='5em';
  let scriptidx = 0;
  function changeScriptView(direction) {
    let n = scripts.length;
    if(rooms_by_script === null || n <= 0) {
      return;
    }
    scriptidx = (((scriptidx + direction) % n) + n) % n;
    setScript();
  }
  function setScript() {
    let script = scripts[scriptidx];
    scriptArea.value = scripts[scriptidx];
    console.log(rooms_by_script[script]);
  }

  let gobut = document.createElement('button');
  let startTxt = 'Search (will take a while)';
  let waitTxt = 'In progress......';
  gobut.innerHTML = startTxt

  let scriptLinesBut = document.createElement('button');
  scriptLinesBut.innerHTML = 'List all lines';
  scriptLinesBut.addEventListener('click', function() {
    admin.list_script_lines().then(lines=>console.log);
  });

  let cancelbut = document.createElement('button');
  cancelbut.innerHTML = 'Cancel';

  let prevbut = document.createElement('button');
  prevbut.innerHTML = 'prev';
  prevbut.addEventListener('click', function() {changeScriptView(-1);});
  let nextbut = document.createElement('button');
  nextbut.innerHTML = 'next';
  nextbut.addEventListener('click', function() {changeScriptView(+1);});

  async function callSearch() {
    gobut.innerHTML = waitTxt;
    let search = document.getElementById('search_input').value;
    try {
      let res = await admin.search_scripts(search);
      gobut.innerHTML = startTxt;
      cancelbut.disabled = false;

      if(res.res.ok) {
        message('search succeeded, matching rooms are:');
        message('ALSO SEE CONSOLE!');
        message(JSON.parse(res.json));
        rooms_by_script = JSON.parse(res.json);
        scriptidx = 0;
        scripts = Object.keys(rooms_by_script);
        setScript();
        console.log('search succeeded');
        console.log(rooms_by_script);
        console.log(scripts.length + ' scripts found');
      }
      else {
        console.error(res);
        let json = res.json;
        console.error(json);
        message('search failed!!!');
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  gobut.addEventListener('click', function(ev) {
    cancelbut.disabled = true;
    callSearch();
  });
  cancelbut.addEventListener('click', function(ev) {
    clearHTMLUi();
  });

  setHtmlElements([
    searchInput,
    gobut,
    cancelbut,
    scriptArea,
    prevbut, nextbut,
    scriptLinesBut,
  ]);
  searchInput.focus();
}

async function showSearchAndReplace() {
  if(!allowReplace) {
    return;
  }
  let searchInput = document.createElement('textarea');
  searchInput.placeholder = 'search';
  searchInput.id = 'search_input';
  searchInput.style.width='80%';
  searchInput.style.height='5em';

  let replaceInput = document.createElement('textarea');
  replaceInput.placeholder = 'replace';
  replaceInput.id = 'replace_input';
  replaceInput.style.width='80%';
  replaceInput.style.height='5em';

  let gobut = document.createElement('button');
  let startTxt = 'Search and replace (will take a while)';
  let waitTxt = 'In progress......';
  gobut.innerHTML = startTxt

  let cancelbut = document.createElement('button');
  cancelbut.innerHTML = 'Cancel';

  async function callSearchAndReplace() {
    gobut.innerHTML = waitTxt;
    let search = document.getElementById('search_input').value;
    let replace = document.getElementById('replace_input').value;
    try {
      let res = await admin.search_and_replace_scripts(search, replace);
      gobut.innerHTML = startTxt;
    cancelbut.disabled = false;
      if(res.res.ok) {
        console.log(res);
        message('search and replace succeeded. good luck.');
        message(res.json);
      }
      else {
        console.error(res);
        let json = res.json;
        console.error(json);
        message('search and replace failed!!!');
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  gobut.addEventListener('click', function(ev) {
    cancelbut.disabled = true;
    callSearchAndReplace();
  });
  cancelbut.addEventListener('click', function(ev) {
    clearHTMLUi();
  });

  function br() {
    return document.createElement('br');
  }
  setHtmlElements([searchInput, br(), replaceInput, br(), gobut, br(), cancelbut, br()]);
  searchInput.focus();
}


import * as positions from './positions.js';
import * as input from './input.js';
import * as main from './main.js';
import {renderer} from './main.js';
import * as fragment_production from './fragment/fragment_production.js';
import {CopyChange} from './changes/CopyChange.js';
import {AddFragmentsChange} from './changes/AddFragmentsChange.js';
import {DeleteFragmentsChange} from './changes/DeleteFragmentsChange.js';
import {ScaleChange} from './changes/ScaleChange.js';
import {PositionChange} from './changes/PositionChange.js';
import {CombinedChange} from './changes/CombinedChange.js';
import * as settings from './settings.js';
import * as uploads from './api/uploads.js';
import {reverse} from './utilities/utilities.js';
import * as selection from './selection.js';
import * as download from './download.js';
import {getToolButton} from './userinterface/ui.js';

const zoomFactor = 1.2; // Factor that is used when scaling fragments using the mouse wheel. Must be greater 1

let draggingMode = '';


export function deleteFragments(fragments) {
  if(piggyPositionChange !== null)
  {
    let newPFs = piggyPositionChange._fragments.filter(f => {
      return fragments.indexOf(f) < 0;
    })
    if(newPFs.length === 0)
      piggyToggle();
  }
  let ch = new DeleteFragmentsChange(fragments);
  ch.finalize();
}
// if fragments at to, make a copy and define as dragging fragments
export function copyInPlace(fragments) {
  let copyChange = new CopyChange(fragments, 0, 0);
  copyChange.finalize(0, 0);
  positions.moveToTop(fragments);
}
let piggyPositionChange = null;
let piggyScaleChange = null;
export function piggyBagging() {
  return piggyPositionChange !== null;
}
export function getPiggyFragments() {
  if(piggyPositionChange !== null) {
    return piggyPositionChange._fragments;
  }
  else {
    return null;
  }
}
export function piggyToggle() {
  if(piggyPositionChange !== null) {
    piggyPositionChange._fragments.forEach(function(frag) {
      frag._piggy = false;
    });
    piggyPositionChange.finalize(0, 0);
    piggyScaleChange.finalize();
    piggyPositionChange = null;
    piggyScaleChange = null;
  }
  else if(selection.isSelectionActive()) {
    piggyPositionChange = new PositionChange(selection.getSelectedFragments(), 0, 0);
    piggyPositionChange._fragments.forEach(function(frag) {
      frag._piggy = true;
    });
    piggyScaleChange =
      new ScaleChange(piggyPositionChange._fragments, 1, {x:0,y:0}, -1);
  }
}
function piggyMoveBy(dx, dy) {
  if(piggyPositionChange !== null) {
    piggyPositionChange.progress(dx, dy);
  }
}
function piggyMove(from, to) {
  if(piggyPositionChange !== null) {
    piggyPositionChange.progress(from.x - to.x, from.y - to.y);
  }
}
function piggyZoom(factor, x, y) {
  if(piggyScaleChange !== null) {
    piggyScaleChange.progress(1/factor, {x: x, y: y});
  }
}
function removePiggyBaggedFrags(fragments) {
  if(piggyPositionChange !== null) {
    return fragments.filter(function(frag) {
      return piggyPositionChange._fragments.indexOf(frag) === -1;
    });
  }
  else {
    return fragments;
  }
}

let combinedChange = null;
let pickedUpFragments = null;
function initCopyChange(fragments, dx, dy, scale, point) {
  finalizeCombinedChange();
  cleanupFinalizedCombinedChange();
  combinedChange = new CopyChange(fragments, dx, dy, scale, point, 1000);
}
function initCombinedChange(fragments, dx, dy, scale, point) {
  cleanupFinalizedCombinedChange();
  if(combinedChange === null) {
    combinedChange = new CombinedChange(fragments, dx, dy, scale, point, 1000);
  }
}
function finalizeCombinedChange() {
  if(combinedChange !== null) {
    combinedChange.stopTimer();
    combinedChange.finalize(0, 0);
  }
}
function cleanupFinalizedCombinedChange() {
  if(combinedChange !== null && combinedChange.finalized) {
    combinedChange = null;
  }
}

//renamed from export function getMovingFragments() {
export function getPickedUpFragments() {
  return pickedUpFragments;
}
export function isDraggingFragments() {
  return draggingMode !== '';
}
//renamed from  export function movingFragments() {
export function areFragmentsPickedUp() {
  return pickedUpFragments !== null &&
    pickedUpFragments.length > 0;
}
export function startFragmentsCopy(from, to) {
  // whether mouse hovers current fragment
  // layering information is implicit via fragments array
  let didCopy = false;
  let fragments = [];
  reverse(positions.getAll(), function(frag, idx) {
    var withincur = frag.pointCollide(to.x, to.y);
    if(withincur) {
      // abort, if current fragment is fixed
      if(frag.fixed) {
        return true;
      }
      if(selection.isSelected(frag)) {
        selection.getSelectedFragments().forEach(function(selFrag) {
          fragments.push(selFrag);
        });
      }
      else {
        fragments.push(frag);
      }
      let dx = to.x - from.x;
      let dy = to.y - from.y;
      initCopyChange(fragments, dx, dy, null, null);
      // important not to use fragments here, but the copies instead
      pickedUpFragments = combinedChange._fragments;
      if(selection.isSelectionActive() && selection.isSelected(frag)) {
        selection.switchSelection(combinedChange._fragments);
      }
      didCopy = true;
      draggingMode = 'copy';
      return true;
    }
  });
  return didCopy;
}
export function startFragmentsDrag(from, to) {
  cleanupFinalizedCombinedChange();
  let dragged = false;
  let fragments = [];
  reverse(positions.getAll(), function(curimg, idx) {
    dragged = curimg.drag(from.x, from.y, to.x, to.y);
    if(dragged) {
      fragments.push(curimg);
      if(selection.isSelected(curimg)) {
        selection.getSelectedFragments().forEach(function(fragment) {
          if(fragment !== curimg) {
            fragments.push(fragment);
          }
        });
      }
      draggingMode = 'move';
    }
    return dragged;
  });

  let changingSameFragmentsAgain = true;
  if(combinedChange !== null && combinedChange._fragments !== null
        && combinedChange._fragments.length === fragments.length) {
    let fragmentsInChangeObject = combinedChange._fragments;
    let someMissing = fragments.some(function(frag){
      // if any fragment is not found, return true
      if(fragmentsInChangeObject.indexOf(frag) === -1) {
        return true;
      }
      else {
        return false;
      }
    });
    changingSameFragmentsAgain = !someMissing;
  }
  else {
    changingSameFragmentsAgain = false;
  }
  if(!changingSameFragmentsAgain) {
    finalizeCombinedChange();
  }

  if(dragged && draggingMode === 'move') {
    let dx = to.x - from.x;
    let dy = to.y - from.y;
    
    cleanupFinalizedCombinedChange();
    if(combinedChange === null) {
      initCombinedChange(fragments, dx, dy, null, null);
    }
    else {
      combinedChange.stopTimer();
      combinedChange.translate(dx, dy);
    }
    //oldMoveChange = new PositionChange(fragments, dx, dy);
    pickedUpFragments = fragments;
  }
  return dragged;
}
export function continueMoveOrCopy(from, to) {
  cleanupFinalizedCombinedChange();
  if(combinedChange !== null) {
    let dx = to.x - from.x;
    let dy = to.y - from.y;
    combinedChange.stopTimer();
    combinedChange.translate(dx, dy);
  }
}
function resetDraggingOnFragments(fragments) {
  fragments.forEach(function(fragment) {
    fragment.grabbed = false;
  });
}
export function finishMoveOrCopy(position) {
  cleanupFinalizedCombinedChange();
  if(combinedChange !== null) {
    if(combinedChange._fragments) {
      resetDraggingOnFragments(combinedChange._fragments);
    }
    // if scaling has happend, start a timer to allow
    // further scaling to be combined
    if(combinedChange.hasScaled) {
      combinedChange.resetTimer();
    }
    else {
      combinedChange.finalize(0, 0);
      combinedChange = null;
    }
  }
  draggingMode = '';
  pickedUpFragments = null;
}
function scaleChange(fragments, factor, scalePoint) {
  // overwrite is used to initialize a new Change object,
  // if the set of fragments scaling changes
  // TODO: find out if or why that would happen
  // if yes, it would probably lead to some weird undo/redo
  // and data transmission intervals
  // copy related?
  let overwrite = false;
  cleanupFinalizedCombinedChange();
  if(combinedChange !== null) {
    if(combinedChange._fragments.length === fragments.length) {
      fragments.forEach(function(frag, idx) {
        if(frag !== combinedChange._fragments[idx]) {
          overwrite = true;
        }
      });
    }
    else {
      overwrite = true;
    }
  }
  if(combinedChange !== null) {
    if(overwrite) {
      combinedChange.finalize();
      combinedChange = null;
    }
  }
  if(combinedChange !== null) {
    combinedChange.scale(factor, scalePoint);
    // start the timer for finalization of change, only if
    // dragging is not in progress
    if(draggingMode !== 'move') {
      combinedChange.resetTimer();
    }
  }
  else {
    initCombinedChange(fragments, 0, 0, factor, scalePoint);
    combinedChange.resetTimer();
    //combinedChange = new ScaleChange(fragments, factor, scalePoint);
  }
}
export function toggleFragmentSelectionAt(position) {
  reverse(positions.onScreenFragments(), function(frag) {
    var within = frag.pointCollide(position.x, position.y);
    if(within) {
      selection.toggleFragmentSelection(frag);
    }
    return within;
  });
}
export function defaultActionFragmentAt(position) {
  reverse(positions.onScreenFragments(), function(fragment) {
    var within = fragment.pointCollide(position.x, position.y);
    if(within) {
      if(fragment.fixed) {
        return true;
      }
      fragment.click();
    }
    return within;
  });
}
export function moveAllBy(dx, dy) {
  positions.changeTranslationBy(dx, dy);
  piggyMoveBy(-dx, -dy);
}
export function moveAll(from, to) {
  let x = (to.x - from.x);
  let y = (to.y - from.y);
  positions.changeTranslationBy(x, y);
  piggyMove(from, to);
}
export function toggleFragmentFixedAt(position) {
  reverse(positions.onScreenFragments(), function(fragment) {
    var within = fragment.pointCollide(position.x, position.y);
    if(within) {
      fragment.fixed = !fragment.fixed;
    }
    return within;
  });
}
export function downloadFragmentAt(position) {
  reverse(positions.onScreenFragments(), function(fragment) {
    var within = fragment.pointCollide(position.x, position.y);
    if(within) {
      fragment.download();
      if(fragment.getTypename() === 'text') {
        download.downloadText(fragment.text);
      }
      else {
        download.downloadURL(fragment.url);
      }
    }
    return within;
  });
}
export function sendToFrontAt(position) {
  reverse(positions.onScreenFragments(), function(fragment, idx) {
    var within = fragment.pointCollide(position.x, position.y);
    if(within) {
      if(fragment.fixed) {
        return true;
      }
      positions.sendFragmentToFront(fragment);
    }
    return within;
  });
}
export function sendToBackAt(position) {
  reverse(positions.onScreenFragments(), function(fragment, idx) {
    var within = fragment.pointCollide(position.x, position.y);
    if(within) {
      if(fragment.fixed) {
        return true;
      }
      positions.sendFragmentToBack(fragment);
    }
    return within;
  });
}
export function toggleBorderShownAt(position) {
  reverse(positions.onScreenFragments(), function(fragment) {
    var within = fragment.pointCollide(position.x, position.y);
    if(within) {
      if(fragment.fixed) {
        return true;
      }
      fragment.toggleBorderShown();
    }
    return within;
  });
}
export function zoomFragmentsBy(fragments, factor) {
  let scalePoint = {x: input.mouseX(), y: input.mouseY()};
  scaleChange(fragments, factor, scalePoint);
}
export function zoomFragmentsAtPositionBy(position, factor) {
  let withinAny = false;
  reverse(positions.onScreenFragments(), function(fragment, idx) {
    var within = fragment.pointCollide(position.x, position.y);
    withinAny = withinAny || within;
    if(within) {
      if(fragment.fixed) {
        //zoomed = true;
      }
      else {
        let scalePoint = {x: input.mouseX(), y: input.mouseY()};
        let fragments = [];
        if(selection.isSelected(fragment)) {
          selection.getSelectedFragments().forEach(function(frag) {
            fragments.push(frag);
          });
        }
        else {
          fragments.push(fragment);
        }
        scaleChange(fragments, factor, scalePoint);
      }
    }
    return within;
  });
  return withinAny;
}
export function changeTransparencyAt(position, dir) {
  reverse(positions.onScreenFragments(), function(fragment, idx) {
    var within = fragment.pointCollide(position.x, position.y);
    if(within) {
      if(fragment.fixed) {
        return true;
      }
      var alpha = 1;
      if(dir > 0) {
        alpha = (fragment.getAlpha() / 1.02);
      }
      else {
        alpha = (fragment.getAlpha() * 1.02);
      }
      fragment.setAlpha(alpha);
    }
    return within;
  });
}
export function wheelOnFragmentsAt(position, dir) {
  let withinAny = false;
  reverse(positions.onScreenFragments(), function(fragment, idx) {
    var within = fragment.pointCollide(position.x, position.y);
    withinAny = withinAny || within;
    if(within) {
      if(fragment.fixed) {
        return true;
      }
      fragment.wheel(dir);
    }
    return within;
  });
  return withinAny;
}
export function zoomAll(factor, x, y) {
  cleanupFinalizedCombinedChange();
  let scalePoint = {x: x, y: y};
  positions.zoomGlobal(scalePoint, factor)
  if(areFragmentsPickedUp()) {
    let fragments = removePiggyBaggedFrags(
      pickedUpFragments
    );
    zoomFragmentsBy(fragments, 1/factor);
  }
  piggyZoom(factor, x, y);
}


// ADD FRAGMENTS
export function startNewTextFragment(position) {
  position = positions.screenToGlobalCoords(position);
  let x = position.x;
  let y = position.y;
  let persistent = true;
  let h = 80;
  let w = h*2;
  const textSize = 60;
  let fragment = getTextFragment(' ', x, y, w, h, w, h, persistent, textSize);
  fragment.setIndividualScale(1/positions.getGlobalScaleLowPrec());
  // fragment.textSize = 36 / positions.getGlobalScaleLowPrec();
  //fragment.scaleFragmentToMax()
  addFragments([fragment]);
  fragment.click();
  setTimeout(() => {
    fragment.inp.elt.select();
  });
  fragment.calcAndSetSize(fragment.text);
  return fragment;
}

export function addFragments(fragments) {
  let ch = new AddFragmentsChange(fragments);
  ch.finalize();
}
function getFileFragment(text, x, y, w, h, maxW, maxH, persistent) {
  return fragment_production.getFragment(
    'file', x, y,
    {
      text: text, width: w, height: h, scale: 1,
      maxW: maxW, maxH: maxH
    },
    persistent
  );
}
function getTextFragment(text, x, y, w, h, maxW, maxH, persistent, textSize = 60) {
  return fragment_production.getFragment(
    'text', x, y,
    {
      text: text, width: w, height: h, scale: 1,
      maxW: maxW, maxH: maxH,
      textSize: textSize
    },
    persistent
  );
}
// DROP
function dataURL(file, callback) {
  let url = URL.createObjectURL(file);  
  callback(url);
  return;
  let fr = new FileReader();
  fr.addEventListener('load', () => {
    callback(fr.result);
  });
  fr.readAsDataURL(file);
}
function getArrayBuffer(file, callback) {
  let fr = new FileReader();
  fr.addEventListener('load', () => {
    callback(fr.result);
  });
  fr.readAsArrayBuffer(file);
}

export function insertFragmentAt(position) {
  let inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.multiple = true;
  inputElement.style.visibility = 'hidden';
  inputElement.addEventListener('change', function(ev) {
    if(ev.target.files.length > 0) {
      filesDropped(position.x, position.y, ev.target.files);
      inputElement.remove();
    }
    setTimeout(()=>{
      getToolButton(settings.TOOLS.WALK).click();
    });
  });
  document.body.append(inputElement);
  inputElement.click();
}

/**
 * x and y are screen coordinates.
 * dataTransfer such as obtained from InputEvent, DragEvent
 * must contain a list of files {items : [File]}
 */
export function filesDropped(screenX, screenY, items) {
  // start empty AddFragmentsChange
  let addFragmentsChange = new AddFragmentsChange([]);

  async function defaultDropped(file, x, y, w, h, maxW, maxH) {
    let fragment = getFileFragment(file.name, x, y, w, h, maxW, maxH, true);
    fragment.scaleFragmentToMax()
    addFragmentsChange.progress([fragment]);
    try {
      let res = await uploads.upload(file.name, file);
      if(res.res.ok) {
        fragment.url = res.json.url;
        fragment.filehashes = [res.json.hash];
        return fragment;
      }
      else if(res.json) {
        console.error(res.json);
        messages.error('error uploading file');
        return null;
      }
      else if(res.text) {
        console.error(res.text);
        messages.error('error uploading file');
        return null;
      }
    }
    catch(e) {
      messages.error('error uploading file');
      console.error(e);
      return null;
    }
  }
  async function videoDropped(file, x, y, w, h) {
    let t = new Date().getTime();
    let fragment = fragment_production.getFragment(
      'video', x, y,
      {url: '', width: w, height: h, scale: 1},
      true
    );
    addFragmentsChange.progress([fragment]);
    try {
      let res = await uploads.upload(file.name, file);
      if(res.res.ok) {
        // /api prefix for routing to django
        fragment.setVideo(res.json.url, [res.json.hash], function() {
          fragment.scaleFragmentToMax()
        });
        return fragment;
      }
      else if(res.json) {
        console.error(res.json);
        //messages.error(res.json);
        return null;
      }
      else if(res.text) {
        console.error(res.text);
        //messages.error(res.text);
        return null;
      }
    }
    catch(e) {
      console.error(e);
      //messages.error(e);
      return null;
    }
    //database.uploadFile(fragment.id, file, (url)=>{
    //fragment.setVideo(url);
    //});
    //return fragment;
  }
  async function audioDropped(file, x, y, w, h) {
    let fragment = fragment_production.getFragment(
      'audio', x, y,
      {
        text: 'audioFragment not implemented', width: w, height: h,
        scale: 1
      },
      true
    );
    fragment.scaleFragmentToMax()
    addFragmentsChange.progress([fragment]);
    try {
      let res = await uploads.upload(file.name, file)
      if(res.res.ok) {
        // /api prefix for routing to django
        fragment.setAudio(res.json.url, [res.json.hash]);
        return fragment;
      }
      else if(res.json) {
        console.error(res.json);
        //messages.error(res.json);
        return null;
      }
      else if(res.text) {
        console.error(res.text);
        //messages.error(res.text);
        return null;
      }
    }
    catch(e) {
      console.error(e);
      //messages.error(e);
      return null;
    }
  }

  async function imageDropped(file, x, y, w, h) {
    let fragment = fragment_production.getFragment(
      'image', x, y,
      {
        url: '', width: w, height: h,
        scale: 1
      },
      true
    );
    addFragmentsChange.progress([fragment]);
    try {
      let res = await uploads.upload(file.name, file)
      if(res.res.ok) {
        // /api prefix for routing to django
        await fragment.setImages(
          res.json.url,
          res.json.width,
          res.json.height,
          [res.json.hash]
        );
        return fragment;
      }
      else if(res.json) {
        console.error(res.json);
        //messages.error(res.json);
        return null;
      }
      else if(res.text) {
        console.error(res.text);
        //messages.error(res.text);
        return null;
      }
    }
    catch(e) {
      console.error(e);
      //messages.error(e);
      return null;
    }
    //dataURL(file, (url) => {
    //});
  }
  async function stringDropped(item, x, y, w, h, maxW, maxH) {
    let tx = await new Promise(function(resolve, reject) {
      item.getAsString(function(tx){resolve(tx)});
    });
    const textSize = 60;
    let fragment = getTextFragment(tx, x, y, w, h, maxW, maxH, true, textSize);
    fragment.scaleFragmentToMax()
    if(item.type === 'text/plain') {
      item.getAsString((str) => {
        fragment.setText(str);
      });
    }
    return fragment;
  }
  let globalCoords = positions.screenToGlobalCoords(
    {x: screenX, y: screenY}
  );

  //let items = dataTransfer.items;
  let initScale = positions.getGlobalScaleLowPrec();

  function calcStartOptions(cols, rows) {
    let startOptions = {};
    if(rows%2===0) {
      startOptions.dir = "left";
    }
    else {
      startOptions.dir = "right";
    }
    if(rows%2===0) {
      startOptions.m = cols - rows/2;
    }
    else {
      startOptions.m =Math.floor(rows/2);
    }
    startOptions.n = Math.floor((rows - 1) / 2);
    return startOptions;
  }

  // center in global coordinates
  let x = globalCoords.x;
  let y = globalCoords.y;
  let sqrt = Math.ceil(Math.sqrt(items.length));
  let M = sqrt;
  let N = Math.ceil(items.length/M);
  // total width
  let A = Math.min(renderer.width/4, renderer.height/4) / initScale;
  let dist = A / M / 10;
  let a = A / M - dist;
  // total height
  let b = a;
  let B = N * (b) + (N-1) * dist;
  let lastPosM = items.length > M ? (items.length-1) % M : 0;
  let lastPosN = Math.ceil(items.length / M) - 1;
  function xOffset(m, skipDist=false) {
    return m * (a+dist) - A/2;
  }
  function yOffset(n, skipDist=false) {
    return n * (b+dist) - B/2;
  }
  async function handleDroppedItem(i, x, y, a, b) {
    let frag = null;
    let item = items[i];
    // compute size and position for new fragment
    let w = a;
    let h = b;
    let type = item.type;
    type = type.split('/');
    let subtype = type[1];
    type = type[0];
    if(item.kind === 'string') {
      frag = await stringDropped(item, x, y, A, A, w, h);
    }
    else if(item.constructor === File || item.kind === 'file') {
      let file;
      if(item.constructor.name === 'File') {
        file = item;
      }
      else {
        file = item.getAsFile();
      }
      if(type === 'image') {
        frag = await imageDropped(file, x, y, w, h);
      }
      else if(type === 'video') {
        frag = await videoDropped(file, x, y, w, h);
      }
      else if(type === 'audio') {
        frag = await audioDropped(file, x, y, w, h);
      }
      else  {
        frag = await defaultDropped(file, x, y, w, h, w, h);
      }
    };
    return frag;
  }
  async function handleGridPos(m, n) {
    // list index i
    let i = (m % M  + n * M);
    let isStrip = settings.DROP_MODE === settings.DropMode.STRIP;
    let xoff;
    let yoff;
    if(isStrip) {
      xoff = xOffset(m + n * M, isStrip);
      yoff = yOffset(0);
    }
    else {
      xoff = xOffset(m, isStrip);
      yoff = yOffset(n);
    }
    let frag = await handleDroppedItem(i, x+xoff, y+yoff, a, b);
    return frag;
  }

  let startOptions = calcStartOptions(M, N);
  let m = startOptions.m;
  let n = startOptions.n;
  let dir = startOptions.dir;
  let DIRMAP = {'left': 'down', 'down': 'right', 'right': 'up', 'up': 'left'}

  function getNextPos(m, n, dir) {
    if(dir === 'left') {
      return {m: m-1, n: n};
    }
    else if(dir === 'down') {
      return {m: m, n: n+1};
    }
    else if(dir === 'right') {
      return {m: m+1, n: n};
    }
    else if(dir === 'up') {
      return {m: m, n: n-1};
    }
  }

  let done = 0;
  let stepsTodo = 1;
  let stepsDone = 0;
  let turnCounter = 0;
  let fragPromises = [];
  for(let i = 0; i < items.length; i++) {
    let m = i % M;
    let n = Math.floor(i/M);
    let fragProm = handleGridPos(m, n);
    fragPromises.push(fragProm);
  }
  Promise.all(fragPromises).then(function(fragments) {
    // TODO
    // null might be contained within fragments !
    addFragmentsChange.finalize();
  });
}

import * as manipulate from './manipulations.js';
import {getHTMLOverlay} from './main.js';
import * as main from './main.js';
import * as space from './space.js';
import {UIOverlayActive} from './userinterface/ui.js';
import * as ui from './userinterface/ui.js';
import {checkWithin} from './utilities/utilities.js';
import * as undo from './undo.js';
import * as settings from './settings.js';
import * as positions from './positions.js';
import * as selection from './selection.js';
import * as clipboard from './clipboard.js';
import {Vector} from './utilities/vector.js';
import {userscriptEditor} from './userinterface/userscript_editor.js'
import * as messages from './messages.js';

// initialize input
let t = {
  prevDiff: -1,
  lastcenter: null,
  dragging: false, // is mouse dragging and used for movement?
  position: null,
  downPosition: new Vector(0,0),
  hasMoved: false,
  continueAsHover: true,
};

export function shouldContinueAsHover() {
  return t.continueAsHover;
}

/**
 * Configuration
 */

// Movement behaviour parameters
const stopGlidingThreshold = 0.7; // Minimum velocity to keep gliding
const spaceFriction = 1.1;  // Factor by which the movement is slowed down. Has to be greater than 1
let startGlidingThreshold = 2.0; // Minimum Acceleration to start gliding after navigation
let maxMoveSpeed = 3.0; // speed of movement when navigating with the keyboard
let keyboardAccStepLen = 0.04;
let moveAcc = keyboardAccStepLen; // position on the ease in curve 0..1

// Mouse wheel behaviour parameters
const maxAccS = 0.3;
const minFactorSpeedZoom = 0.016; // Previously known as minFacS
let minFactorSpeedScale = 0.016;
const factorAccelerationZoom = 0.5; // Previously known as scaleAcc
let factorAccelerationScale = 0.5; 
const frictionZoom = 1.08; // Previously known as scaleBrake
let frictionScale = 1.08;
let minSpeedScale = 0.002;
const minSpeedZoom = 0.002;
const cfac = 1.2;
let trackpadScaleSpeedFactor = 30;

// Movement state/configuration variables
const velKeyboardNav = new Vector(0.0, 0.0); // Velocity of the screen moving when navigating by keyboard
const velMouseNav = new Vector(0.0, 0.0); // Velocity of the screen moving when navigating by mouse
const accNavigateMouse = new Vector(0.0, 0.0); // Most recent acceleration obtained by dragging with the mouse
let currentZoomSpeed = 0; // Previously known as facS
let currentScaleSpeed = 0;

// Click behaviour parameters
export let mouseDownMovementThreshold = 2;

let sketch = null;
let reloadCalled = false;

export let uiContainer = document.getElementById('ui');

/**
 * Event listeners
 */

//window.addEventListener("click", unmuteVideos, false);
window.addEventListener('unload', function(ev) {
  reload();
});
window.addEventListener('beforeunload', function(ev) {
  reload();
});

/* visibilitychange
 * The document's visiblitiy has changed.
 * Navigating pages, switching tabs, switching apps,
 * closing browser, ...
 */
window.addEventListener("visibilitychange", (event) => {
});


/**
 * // TODO:
 * @todo Is the following code block still needed?
 * The keypress event does not fire on my machine (Mac + Chrome)
 * The ev.charCode property is deprecated
 * The keypress event is deprecated
 */
window.addEventListener('keypress', function(ev) {
  if(ev.ctrlKey && ev.charCode === 97) {
    console.log('CTRL A');
    ev.preventDefault();
  }
  if(ev.ctrlKey && ev.charCode === 115) {
    console.log('CTRL S');
    ev.preventDefault();
  }
  if(ev.ctrlKey && ev.charCode === 122) {
    console.log('CTRL Z');
    ev.preventDefault();
  }
  if(ev.ctrlKey && ev.charCode === 121) {
    console.log('CTRL Y');
    ev.preventDefault();
  }
});

window.addEventListener('keydown', function(ev) {
  switch (ev.key) {
    case "Esc": // check "Esc" for browser compatibility
    case "Escape":
      if(onTool(settings.TOOLS.WALK))
      {
        selection.resetSelection();
        ev.preventDefault();
      }
      if(manipulate.piggyBagging())
      {
        manipulate.piggyToggle();
      }
      toolState.selected = settings.TOOLS.WALK;
      break;
  }
});

window.addEventListener('keyup', function(ev) {
  if(ev.key === settings.KEYS.FRAGMENT_MODIFIER) {
    currentScaleSpeed = 0;
  }
});

window.addEventListener("dragover",function(e){
  e.preventDefault();
}, false);
window.addEventListener("drop",function(e){
  handleDropEvent(e);
  e.preventDefault();
}, false);

/**
 * Public functions
 */

export let toolState = {};
export function onTool(s)
{
  return toolState.selected === s;
}
export function transientTool(s)
{
  return !!toolState.transient;
}

// This function is called by the positions module when you click on 'center'
// For USER SCIPTS: This function will cut your zoom-flow and set your speed to 0
// When you can still zoom step by step but you will not accumulate speed
export function resetMovement() {
  velKeyboardNav.zero();
  velMouseNav.zero();
  accNavigateMouse.zero(); // Most recent acceleration obtained by dragging with the mouse
  settings.resetFalling();
  ui.updateBrakesInfo();
  currentZoomSpeed = 0;
  currentScaleSpeed = 0;
}
export function loop() {
  if(UIOverlayActive())
  {
    velMouseNav.zero();
    velKeyboardNav.zero();
    currentZoomSpeed = 0;
    currentScaleSpeed = 0;
    accNavigateMouse.zero();
    return;
  }
  let fr = Math.min(main.renderer.frameRate(), 10);
  let conf = fr / 10;
  // decelerate post navigation glide
  if(!t.dragging && !velMouseNav.isZero()) {
    // glide movement
    if(velMouseNav.length() <= stopGlidingThreshold) {
      velMouseNav.zero();
    }
    // keep moving after mouse release
    manipulate.moveAllBy(velMouseNav.x, velMouseNav.y);
    if(!settings.speedFreeze()) {
      let slowdownThresh = 100;
      let curve = Math.min(slowdownThresh, velMouseNav.length()) / slowdownThresh;
      curve = velMouseNav.length() > slowdownThresh ? 0.98 : 0.8;
      velMouseNav.mix(accNavigateMouse, 1 - 0.98 * curve * conf);
    }
  }
  else {
    // accumulate acceleration before mouse release
    if(!accNavigateMouse.isZero()) {
      velMouseNav.mix(accNavigateMouse, 0.5);
    }
  }
  accNavigateMouse.zero();

  if(!settings.speedFreeze()) {
    if(velKeyboardNav.length() > stopGlidingThreshold) {
      velKeyboardNav.divideScalar(spaceFriction);
    }
    else {
      velKeyboardNav.zero();
    }
  }
  // Slow down zoom an scale speed
  if(!settings.scaleFreeze()) {
    if(currentZoomSpeed != 0) {
      currentZoomSpeed = apply3dFriction(currentZoomSpeed, frictionZoom);
    }
    if(currentScaleSpeed != 0) {
      currentScaleSpeed = apply3dFriction(currentScaleSpeed, frictionScale);
    }
  }
  // ZOOM
  if(currentZoomSpeed != 0) {
    if(settings.getZoomMode() === settings.ZoomMode.MOUSE) {
      manipulate.zoomAll(1/(1+currentZoomSpeed), mouseX(), mouseY());
    }
  }

  // SCALE fragments
  scaleFragments(currentScaleSpeed);

  // keyboard navigation
  if(keyDown(settings.KEYS.GO_LEFT)) {
    velKeyboardNav.add(new Vector(maxMoveSpeed * moveAcc,0));
  }
  if(keyDown(settings.KEYS.GO_RIGHT)) {
    velKeyboardNav.add(new Vector(-maxMoveSpeed * moveAcc,0));
  }
  if(keyDown(settings.KEYS.GO_UP)) {
    velKeyboardNav.add(new Vector(0, maxMoveSpeed * moveAcc));
  }
  if(keyDown(settings.KEYS.GO_DOWN)) {
    velKeyboardNav.add(new Vector(0, -maxMoveSpeed * moveAcc));
  }
  if(keyDown(settings.KEYS.GO_DOWN) || keyDown(settings.KEYS.GO_UP) ||
    keyDown(settings.KEYS.GO_LEFT) || keyDown(settings.KEYS.GO_RIGHT)) {
    if(moveAcc <= 1 - keyboardAccStepLen) {
      // while a key is pressed speed up
      moveAcc += keyboardAccStepLen;
    }
  } 

  if(!velKeyboardNav.isZero()) {
    manipulate.moveAllBy(velKeyboardNav.x, velKeyboardNav.y);
  }
  else {
    moveAcc = keyboardAccStepLen;
  }

  // selection
  if((selection.isPerformingSelection() && !velKeyboardNav.isZero()) ||
    (selection.isPerformingSelection() && settings.USER_SCRIPTS_ACTIVE)) {
    selection.continueSelection({x: t.position.x, y: t.position.y});
  }

  if(manipulate.isDraggingFragments()) {
    manipulate.continueMoveOrCopy({x: 0, y: 0}, {x: -velKeyboardNav.x, y: -velKeyboardNav.y});
  }

  let tool = undefined;
  
  if(keyDown(settings.KEYS.ESCAPE))
  {
    tool = settings.TOOLS.WALK;
  }
  else if(keyDown(settings.KEYS.TEXT))
  {
    tool = settings.TOOLS.TEXT;
  }
  else if(keyDown(settings.KEYS.FRAGMENT_MODIFIER))
  {
    tool = settings.TOOLS.FRAGMENT;
  }
  else if(keyDown(settings.KEYS.COPY))
  {
    tool = settings.TOOLS.COPY;
  }
  else if(keyDown(settings.KEYS.SCRIPT))
  {
    tool = settings.TOOLS.SCRIPT;
  }
  else if(keyDown(settings.KEYS.SELECTION_MODIFIER))
  {
    tool = settings.TOOLS.SELECT;
  }
  else if(keyDown(settings.KEYS.ROTATE_MODIFIER))
  {
    tool = settings.TOOLS.ROTATE;
  }
  else if(keyDown(settings.KEYS.OVER))
  {
    tool = settings.TOOLS.OVER;
  }
  else if(keyDown(settings.KEYS.UNDER))
  {
    tool = settings.TOOLS.UNDER;
  }
  else if(keyDown(settings.KEYS.DOWNLOAD))
  {
    tool = settings.TOOLS.DOWNLOAD;
  }
  else if(keyDown(settings.KEYS.INSERT))
  {
    tool = settings.TOOLS.INSERT;
  }
  else if(manipulate.piggyBagging())
  {
    tool = settings.TOOLS.BAG;
  }

  if(tool)
  {
    toolState.selected = tool;
    toolState.transient = tool != settings.TOOLS.WALK;
  }
  else if(toolState.switch)
  {
    toolState.switch = false;
    toolState.selected = toolState.candidate;
  }
  else if(toolState.transient)
  {
    if(manipulate.piggyBagging())
    {
      toolState.selected = settings.TOOLS.BAG;
    }
    else
    {
      toolState.selected = toolState.step;
      toolState.transient = false;
    }
  }
  else
  {
    toolState.step = toolState.selected
  }
  toolState.switch = false;

  if(toolState.last !== toolState.selected)
  {
    let l = toolState.last;
    let c = toolState.selected;
    toolState.last = c;
    if(c !== settings.TOOLS.WALK)
    {
      toolState.candidate = settings.TOOLS.WALK;
    }
    else
    {
      toolState.candidate = l;
    }
  }

  ui.updateTool(toolState.selected,
    onTool(settings.TOOLS.WALK) && selection.isSelectionActive(),
    toolState.transient ? undefined : toolState.candidate,
    main.isMobile());

  if(onTool(settings.TOOLS.SELECT)) {
    main.renderer.cursor("crosshair");
  }
  else if(onTool(settings.TOOLS.TEXT)) {
    main.renderer.cursor('text');
  }

}

if(main.isMobile()) {
  document.addEventListener('touchmove', function (event) {
    if (event.scale !== 1) { event.preventDefault(); }
  }, false);
}

export function initInputModule(p) {
  toolState.selected = settings.TOOLS.WALK;
  toolState.last = settings.TOOLS.WALK;
  toolState.candidate = settings.TOOLS.WALK;
  toolState.switch = false;
  ui.setSurfaceEventHandler(e => {
    if(e.t === 'action')
    {
      if(e.action === settings.ACTIONS.CENTER)
      {
        positions.goToCenter();
      }
      else if(e.action === settings.ACTIONS.DELETE)
      {
        if(selection.isSelectionActive())
        {
          manipulate.deleteFragments(selection.getSelectedFragments());
        }
      }
      else if(e.action === settings.ACTIONS.UNDO)
      {
        undo.undo();
      }
      else if(e.action === settings.ACTIONS.REDO)
      {
        undo.redo();
      }
      else if(e.action === settings.ACTIONS.CLIPBOARD_COPY)
      {
        clipboard.copy(selection.getSelectedFragments());
      }
      else if(e.action === settings.ACTIONS.CLIPBOARD_PASTE)
      {
        clipboard.paste();
      }
      else if(e.action === settings.ACTIONS.DESELECT)
      {
        selection.resetSelection();
      }
      else if(e.action === settings.ACTIONS.HIDE_ALL)
      {
        document.querySelector('.surface').classList.toggle('hide-all');
      }
      else if(e.action === settings.ACTIONS.BRAKES)
      {
        settings.toggleFalling();
      }
      else if(e.action === settings.ACTIONS.FULLSCREEN)
      {
        if(document.documentElement.requestFullscreen)
        {
          if(document.fullscreenElement)
            document.exitFullscreen();
          else
            document.documentElement.requestFullscreen();
        }
      }
    }
    else if(e.t === 'tool')
    {
      if(e.tool === settings.TOOLS.BAG)
      {
        if(selection.isSelectionActive())
          manipulate.piggyToggle();
        else
          return;
      }

      toolState.selected = e.tool;
      toolState.transient = false;
    }
  });

  sketch = p;
  window.addEventListener('mouseover', function(ev) {
    sketch.canvas.focus();
  });

  sketch.canvas.oncontextmenu = (e) => {
    return false;
  }

  let pevs = new Array();

  function getCenter() {
    let center = {x:0, y:0};
    let count = 0;
    pevs.forEach((pev)=>{
      count += 1;
      center.x += pev.x;
      center.y += pev.y;
    });
    center.x /= count;
    center.y /= count;
    return center;
  }

  const PRIMARY_BUTTON_VAL = 0;
  function pointerdown_listener(e) {
    if(e && e.target.nodeName !== 'CANVAS')
      return;
    if(e.button !== PRIMARY_BUTTON_VAL)
    {
      toolState.switch = true;
      return false;
    }
    let pev = {x: e.clientX, y: e.clientY, id: e.pointerId, as: pevs.length+1, max: 0, type: e.pointerType};
    pev.fx = pev.x;
    pev.fy = pev.y;
    pevs.push(pev);
    for(var i = 0; i < pevs.length; i++)
    {
      let p = pevs[i];
      p.max = Math.max(p.max, pevs.length);
    }

    let center = getCenter();
    t.lastcenter = null;
    t.prevDiff = -1;
    velMouseNav.zero();
    t.continueAsHover = true;
    if (pev.as === 1)
    {
      t.downPosition = new Vector(center.x, center.y);
      pev.discardClick = false;
      if(pev.type !== 'touch')
      {
        pev.discardClick = processPress(center);
      }
    }
    else
    {
      pev.discardClick = true;
    }
  }

  function pointerup_listener(e) {
    let pev = null;
    // Drop from pointer events.
    for (var i = 0; i < pevs.length; i++) {
      if (pevs[i].id === e.pointerId) {
        pev = pevs.splice(i, 1)[0];
        break;
      }
    }
    if(!pev)
      return;

    if(accNavigateMouse.isZero()) {
      velMouseNav.zero(); // hard zero movement speed, if acceleration zero
    }
    if(pevs.length < 1)
    {
      t.dragging = false;
      t.continueAsHover = (e.pointerType !== 'touch');
    }
    if(pev.as === 1)
    {
      t.position = null;

      if(selection.isPerformingSelection()) {
        selection.finishSelection(pev);
      }
      if(manipulate.isDraggingFragments()) {
        manipulate.finishMoveOrCopy();
      }
    }
    if(pev.max < 2)
    {
      if(pev.type === 'touch')
      {
        pev.discardClick = processPress(pev);
      }
      if(!pev.movedSignificant && !pev.discardClick) {
        click(pev);
      }
    }
  }

  function pointermove_listener(e) {
    t.hasMoved = true;
    if(pevs.length < 1)
      return;

    if(space.isLoading() || UIOverlayActive()) {
      return;
    }

    // Update the position of pointer.
    let pev = null;
    let first_pev = null;
    for (var i = 0; i < pevs.length; i++) {
      let p = pevs[i];
      if(p.as === 1)
      {
        first_pev = p;
      }

      if (p.id === e.pointerId)
      {
        p = {...p, x: e.clientX, y:e.clientY};
        if((new Vector(p.fx, p.fy)).distance(new Vector(p.x, p.y)) > mouseDownMovementThreshold)
          p.movedSignificant = true;
        pevs[i] = p;
        pev = p;
      }
    }
    if(!pev || !first_pev)
      return;

    let from, to;
    if(pev.as === 1)
    {
      if(t.position === null) {
        t.position = new Vector(pev.x, pev.y);
      }
      from = t.position.clone()
      to = new Vector(pev.x, pev.y);
      t.position = to.clone();
    }

    // If two pointers are down, check for pinch gestures
    if (pevs.length === 2)
    {

      // Calculate the distance between the two pointers
      let p0 = pevs[0], p1 = pevs[1];
      let dx = p0.x - p1.x;
      let dy = p0.y - p1.y;
      let curDiff = Math.sqrt(dx*dx + dy*dy);
      if(curDiff < 50)
        return;
      let prevDiff = t.prevDiff;
      t.prevDiff = curDiff;

      let center;
      if(manipulate.isDraggingFragments() ||
          selection.isPerformingSelection())
      {
        center = first_pev;
      }
      else
      {
        center = getCenter();
      }
      let last = t.lastcenter;
      t.lastcenter = center;
      if (last !== null)
      {
        manipulate.moveAll(last, center)
      }

      let scale = curDiff / prevDiff;
      if (prevDiff > 0 && scale > 0.3 && scale < 3)
      {
        if(manipulate.areFragmentsPickedUp())
        {
          manipulate.zoomFragmentsBy(manipulate.getPickedUpFragments(), scale);
        }
        else
        {
          manipulate.zoomAll(scale, center.x, center.y);
        }
      }
    }
    else if(pevs.length === 1)
    {
      if(manipulate.isDraggingFragments()) {
        // continueMoveOrCopy is even called with 0 movement
        manipulate.continueMoveOrCopy(from.toObject(), to.toObject());
      }
      else if(selection.isPerformingSelection()) {
        selection.continueSelection(to.toObject());
      }
      else if(pev.max === 1)
      {
        // try to initiate copy
        if(onTool(settings.TOOLS.COPY)) {
          manipulate.startFragmentsCopy(from.toObject(), to.toObject());
        }
        // move fragments
        else if(onTool(settings.TOOLS.FRAGMENT)) {
          manipulate.startFragmentsDrag(from.toObject(), to.toObject());
        }
        // select fragments
        else if(onTool(settings.TOOLS.SELECT)) {
          selection.startSelection(from.toObject(), to.toObject());
        }
        else if(onTool(settings.TOOLS.WALK)) {
          velKeyboardNav.zero();
          manipulate.moveAll(from.toObject(), to.toObject());
          let currentAcc = to.clone().subtract(from);
          accNavigateMouse.copy(currentAcc);
          t.dragging = true;
        }
      }
    }
  }

  document.addEventListener('pointerdown', pointerdown_listener);

  document.addEventListener('pointermove', pointermove_listener);

  // Use same listener for pointer{up,cancel,out,leave} events since
  // the semantics for these events - in this app - are the same.
  document.addEventListener('pointerup', pointerup_listener);
  document.addEventListener('pointercancel', pointerup_listener);
  document.addEventListener('pointerleave', pointerup_listener);

  // handle scaling of fragments
  p.mouseWheel = function(event) {
    if(space.isLoading()) {
      return;
    }
    if(UIOverlayActive()) {
      // do nothing, if overlay is save / load textbox
      return false;
    }
    if(event.target.nodeName !== "CANVAS")
    {
      return;
    }

    var dir = p.abs(event.delta) / event.delta;
    // when dir is 0 (f.i. deltaX != 0 but deltaY == 0)
    // return, as dir is NaN and horizontal scroll defined
    // defined yet
    if(isNaN(dir)) {
      return false;
    }
    let position = {x: p.mouseX, y: p.mouseY};
    let eventStopped = false;
    if(onTool(settings.TOOLS.FRAGMENT)) {
      // SCALE
      if(settings.getZoomMode() === settings.ZoomMode.TRACKPAD) {
        currentScaleSpeed = 0;
        scaleFragments(dir/trackpadScaleSpeedFactor);
      } else {
        currentScaleSpeed = apply3dAcceleration(currentScaleSpeed, minSpeedScale, minFactorSpeedScale, factorAccelerationScale, dir);
      }
      
      eventStopped = true;
    }
    else if(keyDown(settings.KEYS.ALT)) {
      manipulate.wheelOnFragmentsAt(position, dir);
      eventStopped = true;
    }
    else if(onTool(settings.TOOLS.ROTATE)) {
      manipulate.wheelOnFragmentsAt(position, dir);
      eventStopped = true;
    }

    if(!eventStopped) {
      if(settings.getZoomMode() === settings.ZoomMode.TRACKPAD) {
        trackWheel(event);
      }
      if(settings.getZoomMode() === settings.ZoomMode.MOUSE) {
        mouseWheel(event);
      }
    }
    return false;
  }
  function trackWheel(event) {
    let zoomStep = settings.ZOOM_STEP;
    let fac = event.delta > 0 ? 1/zoomStep : zoomStep;
    manipulate.zoomAll(fac, mouseX(), mouseY());
  }
  // ZOOM
  function mouseWheel(event) {
    var dir = p.abs(event.delta) / event.delta;
    currentZoomSpeed = apply3dAcceleration(currentZoomSpeed, minSpeedZoom, minFactorSpeedZoom, factorAccelerationZoom, dir);
  };

  p.keyPressed = function(keyCode) {
    if(getHTMLOverlay() || UIOverlayActive()) {
      return;
    }
    if(keyDown(settings.KEYS.FALLING)) {
      settings.toggleFalling();
    }

    // TODO: ask if this can stay.
    if(keyDown(settings.KEYS.RESUME)) {
      if(!p.isLooping())
        p.loop();
    }

    else if(keyCode.code === 'Backspace') {
      return false;
    }
    else if(keyCode.code === 'Delete') {
      if(selection.isSelectionActive()) {
        manipulate.deleteFragments(selection.getSelectedFragments());
      }
    }
    else if(
      keyDown(settings.KEYS.CTRL) &&
      keyDown(settings.KEYS.COPY)
    ) {
      console.log('copy');
      clipboard.copy(selection.getSelectedFragments());
    }
    else if(
      keyDown(settings.KEYS.CTRL) &&
      keyDown(settings.KEYS.PASTE)
    ) {
      console.log('paste');
      clipboard.paste();
    }
    else if(
      keyDown(settings.KEYS.CTRL) &&
      keyDown(settings.KEYS.COMBINED_UNDO) &&
      !keyDown(settings.KEYS.SHIFT)
    ) {
      undo.undo();
    }
    else if(
      keyDown(settings.KEYS.CTRL) &&
      keyDown(settings.KEYS.COMBINED_REDO)
      ||
      keyDown(settings.KEYS.CTRL) &&
      keyDown(settings.KEYS.SHIFT) &&
      keyDown(settings.KEYS.COMBINED_UNDO)

    ) {
      undo.redo();
    }
    else if(
      keyDown(settings.KEYS.CTRL) &&
      keyDown(settings.KEYS.COMBINED_SELECT_ALL)
    ) {
      selection.resetSelection();
      selection.addToSelection(positions.getAll());
    }
    else if(
      keyDown(settings.KEYS.COPY)
    ) {
      let copyFragments = null;
      if(manipulate.areFragmentsPickedUp()) {
        copyFragments = manipulate.getPickedUpFragments();
      }
      else if (manipulate.piggyBagging()) {
        // dont do this, would always copy even if intention is
        // to copy by pressing key and dragging!
        //copyFragments = manipulate.getPiggyFragments();
      }
      if(copyFragments !== null) {
        manipulate.copyInPlace(copyFragments);
      }
    }
    else if(
      keyDown(settings.KEYS.BAG)
    ) {
      manipulate.piggyToggle();
    }
  };
}

export function setMouseDownMovementThreshold(value) {
  mouseDownMovementThreshold = value;
}

export function mouseX() {
  if(t.hasMoved) {
    return sketch.mouseX;
  }
  else {
    return sketch.width / 2;
  }
}
export function mouseY() {
  if(t.hasMoved) {
    return sketch.mouseY;
  }
  else {
    return sketch.height / 2;
  }
}

export function keyDown(key) {
  return settings.keyDown(sketch, key);
}

/**
 * Private functions
 */

function reload() {
  if(!reloadCalled) {
    reloadCalled = true;
    positions.storeCamera();
    positions.reset();
  }
}
function handleDropEvent(ev) {
  //ev.dataTransfer.getData('text/plain');
  //ev.dataTransfer.getData('text/html');
  ev.preventDefault();
  let items = ev.dataTransfer.items;
  manipulate.filesDropped(ev.clientX, ev.clientY, items);
}

/**
 * This method applies a given frictionFactor to a given speed and
 * calculates a resulting speed using only the given parameters.
 * The new acceleration is returned.
 * History: The logic is taken from the behaviour for zooming and was not changed.
 * @param {float} speed speed before applying friction
 * @param {float} frictionFactor factor used to calculate the deceleration
 * @returns {float} newly calculated speed (slower) 
 */
function apply3dFriction(speed, frictionFactor) {
  let effBr = frictionFactor * 1 / Math.abs(speed) / 75;
  effBr = Math.max(effBr, frictionFactor);
  speed = speed / effBr;
  if (Math.abs(speed) < 0.0001) {
    speed = 0;
  }
  return speed;
}
/**
 * This method applies a directed acceleration to a given speed and
 * returns a new speed.
 * For the calculation of the new speed it uses the constants 
 * defined in the configuration section of this module.
 * History: The logic is taken from the behaviour for zooming and was not changed.
 * @param {float} speed speed before applying acceleration
 * @param {float} minimalSpeed minimal speed
 * @param {float} direction direction of the acceleration
 * @returns {float} newly calculated speed 
 */
function apply3dAcceleration(speed, minimalSpeed, minScaleFactor, scaleAcceleration, direction) {
  if (settings.scaleFreeze()) {
    if (speed === 0) {
      speed = Math.sign(direction) * minimalSpeed;
    }
    else if (Math.sign(direction * speed) > 0) {
      speed *= cfac;
    }
    else {
      speed /= cfac;
    }
    if (Math.abs(speed) < minimalSpeed * 0.99) {
      speed *= -1.1;
    }
    speed = Math.sign(speed) * Math.min(Math.abs(speed), maxAccS);
  }
  else if (speed === 0) {
    speed += direction * minScaleFactor;
  }
  else {
    let acc = direction * scaleAcceleration * Math.abs(speed);
    speed = speed + acc;
    speed = Math.sign(speed) * Math.min(maxAccS, Math.abs(speed));
  }
  return speed;
}
/**
 * This method handles the scaling of fragments (if neccessary)
 * and respects an existing momentum (if neccessary)
 * @param scaleSpeed the momentum of the current scale state
 */
function scaleFragments(scaleSpeed) {
  if (scaleSpeed != 0) {
    if (manipulate.areFragmentsPickedUp()) {
      manipulate.zoomFragmentsBy(manipulate.getPickedUpFragments(), 1 / (1 + scaleSpeed));
    }
    else {
      manipulate.zoomFragmentsAtPositionBy({ x: mouseX(), y: mouseY() }, 1 / (1 + scaleSpeed));
    }
  }
}
function click(position) {
  if(UIOverlayActive())
    return
  else if(onTool(settings.TOOLS.SELECT)) {
    selection.toggleFragmentSelectionAt(position);
  }
  else if(onTool(settings.TOOLS.INSERT)) {
    manipulate.insertFragmentAt(position);
    return true;
  }
  else if(keyDown(settings.KEYS.CTRL)) {
    /// IMPORTANT prevent default action
    // ??
  }
  else if(onTool(settings.TOOLS.WALK) || onTool(settings.TOOLS.TEXT)) {
    manipulate.defaultActionFragmentAt(position);
  }
}

function processPress(position) {
  if(UIOverlayActive()) {
    return false;
  }
  let textElementHTMLOverlay = getHTMLOverlay();
  if(textElementHTMLOverlay) {
    if(textElementHTMLOverlay.element !== null) {
      textElementHTMLOverlay.element.inputModeToViewMode();
    }
  }
  if(onTool(settings.TOOLS.OVER)) {
    manipulate.sendToFrontAt(position);
    return true;
  }
  else if(onTool(settings.TOOLS.UNDER)) {
    manipulate.sendToBackAt(position);
    return true;
  }
  else if(onTool(settings.TOOLS.DOWNLOAD)) {
    manipulate.downloadFragmentAt(position);
    return true;
  }
  else if(onTool(settings.TOOLS.SCRIPT)) {
    userscriptEditor();
    return true;
  }
  else if(onTool(settings.TOOLS.TEXT)) {
    manipulate.startNewTextFragment(position);
    return true;
  }
  return false;
}

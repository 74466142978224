/* jshint moz: true*/
import {getFragment, getTypename} from './fragment_production.js';
import {recommendFunction, expectFunction} from '../utilities/utilities.js';
// needed for side effects>>>>>
import * as sketch from '../main.js';
import {renderer} from '../main.js';
// <<<<<needed for side effects
import * as positions from '../positions.js';
import * as changes from '../changes/utilities.js';
import * as settings from '../settings.js';
import * as login from '../api/login.js';
import * as api_space from '../api/space.js';
import * as api_fragments from '../api/fragments.js';
import * as selection from '../selection.js';
import * as messages from '../messages.js';
import * as ui from '../userinterface/ui.js';
import * as manipulate from '../manipulations.js';
import * as input from '../input.js';
import * as space from '../space.js';
import * as audio from '../audio.js';
import * as multiuser from '../multiuser.js';

let Decimal = settings.Decimal;

/**
 * BaseFragment, the base class for all fragments
 */
export class BaseFragment {
  /**
   * constructor not to be called directly; use fragment_production module
   * @param {processing sketch} p - The sketch object from p5js
   */
  constructor(p, x, y, type, data, persistent = false) {
    if(this.constructor === BaseFragment) {
      console.error('BaseFragment constructor should not be called directly!');
    }
    // signals for userscripts
    // these signals are only true for one frame after an event has happened
    this.signals = {
      fragmentSetup: true,
      scriptSetup: false,
    };
    this.removed_from_space = false;
    this.tintOpacityAbsoluteMin = 30;
    this.tintOpacityAbsoluteMax = 255;
    this.tintFadeFramesCount = 5; // must never be 0!
    this._tintStepLen = null;
    this._tintOpacity = 0;
    this._tintOpacityCurrentMax = 75;
    this.clickListeners = [];
    this.namedClickListeners = {};
    this._synced = true;
    this.sx = 0;
    this.sy = 0;
    this.text = "";
    this._persistent = persistent;
    this.floating_z = -1;
    this.boxed = false;
    expectFunction('typeDraw', this);
    recommendFunction('typeClick', this);
    this.maxRes = settings.MAX_RESOLUTION;
    this.maxW = data.maxW || data.width;
    this.maxH = data.maxH || data.height;
    this.w = data.width; // TODO: find a way to save enteredW and enteredH
    this.h = data.height;
    // for temporary scripting
    this._tempScaleW = 1; // renamed from w_scale TODO: user scripts (use getter/setter)
    this._tempScaleH = 1; // renamed from h_scale TODO: user scripts (use getter/setter)
    this._tempRelShiftX = 0; // renamed from x_drift TODO: user scripts (use getter/setter)
    this._tempRelShiftY = 0; // renamed from y_drift TODO: user scripts (use getter/setter)
    this.tmpMem = {};
    this.userscript = {
      scriptSourceString: ''
    };
    this.initScale = data.initScale;
    this.hovered = false;
    this.mouseX = null;
    this.mouseY = null;
    // fixed flag for deactivating fragment interaction
    this.fixed = false;
    this.p = p;
    this._borderWidth = 30;
    this._alpha = 255;
    this._borderShown = true;
    this._hasBorder = false;
    this._hasImageTransparency = false;
    this._lastRelativeOsScreenArea = 0;
    this._selected = false;
    this.setPlaying(false);
    // rotation, degrees
    this.rotation = 0;
    this._relativeRotationPointX = 0.5;
    this._relativeRotationPointY = 0.5;
    // text input for changing value
    this.inp = null;
    this.setReady(false);
    if(data.name) {
      this.name = data.name;
    }
    this.drawGray = false;

    this.x = x;
    this.y = y;
    this.tempX = 0; // TODO: check and remove
    this.tempY = 0; // TODO: check and remove
    this._scale = data.scale || 1;
    this.url = data.url || '';
    if(BaseFragment.nextID === undefined) {
      BaseFragment.nextID = 0;
    }
    let id = -1;
    this.id = id;
  }
 
  getTypename() {
    return getTypename(this.constructor);
  }
  typeSetChanged(val) {
  }
  setChanged(val) {
    this.typeSetChanged(val);
  }
  // getters / setters for fragment's horizontal position
  set x(val) {
    this._x = val;
    this.setChanged(true);
  }
  get x() {
    return this._x
  }
  get x_drift() {
    return this._tempRelShiftX;
  }
  set x_drift(val) {
    this._tempRelShiftX = val;
  }
  getTempX() {
    return this.x + this.getTempW() * this.x_drift;
  }
  // getters / setters for fragment's vertical position
  set y(val) {
    this._y = val;
    this.setChanged(true);
  }
  get y() {
    return this._y
  }
  get y_drift() {
    return this._tempRelShiftY;
  }
  set y_drift(val) {
    this._tempRelShiftY = val;
  }
  getTempY() {
    return this.y + this.getTempH() * this.y_drift;
  }
  // getters / setters for fragment's width
  set w(val) {
    if(val === 0) {
      console.warn("Fragment width set to 0");
    }
    this._w = val;
    this.setChanged(true);
  }
  get w() {
    return this._w;
  }
  get w_scale() {
    return this.tempScaleW; // TODO filter
  }
  set w_scale(val) {
    this.tempScaleW = val; //TODO filter
  }
  get h_scale() {
    return this.tempScaleH; // TODO filter
  }
  set h_scale(val) {
    this.tempScaleH = val; //TODO filter
  }
  get tempScaleW() {
    return this._tempScaleW;
  }
  set tempScaleW(val) {
    this._tempScaleW = val;
  }
  getTempW() {
    return this.w * this.tempScaleW * this.getIndividualScale();
  }
  // getters / setters for fragment's height
  set h(val) {
    if(val === 0) {
      console.warn("Fragment height set to 0");
    }
    this._h = val;
    this.setChanged(true);
  }
  get h() {
    return this._h;
  }
  get tempScaleH() {
    return this._tempScaleH;
  }
  set tempScaleH(val) {
    this._tempScaleH = val;
  }
  getTempH() {
    return this._h * this.tempScaleH * this.getIndividualScale();
  }
  set synced(val) {
    this._synced = val;
  }
  get synced() {
    return this._synced;
  }
  getAlpha() {
    return this._alpha;
  }
  setAlpha(alpha) {
    this._alpha = alpha;
  }
  getScale() {
    return this.screenScale(); // TODO make filter
  }
  screenScale() { // renamed from getScale() TODO: user scripts
    return positions.getGlobalScaleLowPrec() * this._scale;
  }
  getIndividualScale() {
    return this._scale;
  }
  setScale(scale) {
    this.setIndividualScale(scale); // TODO make filter
  }
  setIndividualScale(scale) { // renamed from setScale(scale) TODO: user scripts
    this._scale = scale;
  }
  screenX() {
    // _calcedSX is be set to null at the beginning of every frame (ref. precalc)
    if(this._calcedSX === null) {
      let x = new Decimal(this.x);
      x = x.times(positions.getGlobalScalePrec()).plus(positions.getTranslationPrec().x);
      this._calcedSX = x.toNumber();
      this._calcedSX += this.screenW() * this.x_drift;
    }
    return this._calcedSX;
    //return this.x * positions.getGlobalScaleLowPrec() + positions.getTranslation().x;
  }
  screenY() {
    // _calcedSY be set to null at the beginning of every frame (ref. precalc)
    if(this._calcedSY === null) {
      let y = new Decimal(this.y);
      y = y.times(positions.getGlobalScalePrec()).plus(positions.getTranslationPrec().y);
      this._calcedSY = y.toNumber();
      this._calcedSY += this.screenW() * this.y_drift;
    }
    //return this.y * positions.getGlobalScaleLowPrec() + positions.getTranslation().y;
    return this._calcedSY;
  }
  screenW(scale) {
    if(scale) return this.w * this.getIndividualScale() * scale * this.tempScaleW;
    // _calcedSW be set to null at the beginning of every frame (ref. precalc)
    if(this._calcedSW === null) {
      this._calcedSW = this.w * this.screenScale() * this.tempScaleW;
    }
    return this._calcedSW;
  }
  screenH(scale) {
    if(scale) return this.h * this.getIndividualScale() * scale * this.tempScaleH;
    // _calcedSH be set to null at the beginning of every frame (ref. precalc)
    if(this._calcedSH === null) {
      this._calcedSH = this.h * this.screenScale() * this.tempScaleH;
    }
    return this._calcedSH;
  }
  getOnScreenArea() {
    let gw = renderer.width;
    let gh = renderer.height;
    const l = Math.min(gw, Math.max(0, this.screenX()));
    const r = Math.min(gw, Math.max(0, this.screenX() + this.screenW()));
    const t = Math.min(gh, Math.max(0, this.screenY()));
    const b = Math.min(gh, Math.max(0, this.screenY() + this.screenH()));

    let w = r - l;
    let h = b - t;
    return Math.abs(w * h);
  }
  getRelativeOnScreenArea() {
    return this.getOnScreenArea() / sketch.getArea();
  }
  getArea() {
    return this.screenH() * this.screenW();
  }
  getRelativeArea() {
    return this.getArea() / sketch.getArea();
  }
  screenCenterX() {
    return (this.screenX() + 0.5 * this.screenW());
  }
  screenCenterY() {
    return (this.screenY() + 0.5 * this.screenH());
  }
  screenDist(x, y) {
    var dx = x - this.screenX()
    var dy = y - this.screenY()
    var d = this.p.sqrt(dx * dx + dy * dy);
    return d;
  }
  screenCenterDist(x, y) {
    var dx = x - this.screenCenterX()
    var dy = y - this.screenCenterY()
    var d = this.p.sqrt(dx * dx + dy * dy);
    return d;
  }
  isReady() {
    return this._ready;
  }
  // This should only be done once, not on loading from database, etc.
  scaleFragmentToMax() {
    // calc size (images, videos must load first)
    let self = this;
    let curW = self.w;
    let curH = self.h;
    if(curW > self.maxW || curH > self.maxH) {
      let factor = Math.min(self.maxW / curW, self.maxH / curH);
      self._scale *= factor;
    }
    else if(curW < self.maxW && curH < self.maxH) {
      let factor = Math.min(self.maxW / curW, self.maxH / curH);
      self._scale *= factor;
    }
    //self.x += (self.maxW - self.w*self._scale) / 2;
    //self.y += (self.maxH - self.h*self._scale) / 2;
  }
  setReady(val) {
    let self = this;
    this._ready = val;
    self._borderShown = self._hasBorder;
  }
  typeIsPlaying() {
    return null;
  }
  isPlaying() {
    let playing = this.typeIsPlaying();
    if(playing !== null) {
      return playing && this._playing;
    }
    else {
      return this._playing;
    }
  }
  setPlaying(val) {
    this._playing = val;
  }
  isSmall() {
    return this._small;
  }
  calcOnScreen() {
    if(!this.precalced) {
      let rend = sketch.renderer;
      var l = this.screenX();
      var r = this.screenX() + this.screenW();
      var t = this.screenY();
      var b = this.screenY() + this.screenH();
      var w = rend.width;
      var h = rend.height;
      if(r >= 0 && l <= w && b >= 0 && t <= h)
      {
        this.setOnScreen(true);
      }
      else {
        this.setOnScreen(false);
      }
    }
    else {
      console.warn("calcOnScreen more than once");
    }
  }
  typeCalcSmall() {
    return false;
  }
  download() {
    console.warn("download() not implemented for this fragment type: " + this.type);
  }
  calcSmall() {
    if(!this.precalced) {
      let small = this.screenW() < 3 && this.screenH() < 3;
      small = small || this.typeCalcSmall();
      if(small) {
        this.setSmall(true);
      }
      else {
        this.setSmall(false);
      }
    }
    else {
      console.warn("calcSmall() called more than once");
    }
  }
  isOnScreen() {
    return this._onScreen;
  }
  movesOnScreen() {
    this.typeMovesOnScreen();
  }
  typeMovesOnScreen() {
  }
  movesOffScreen() {
    this.typeMovesOffScreen();
  }
  typeMovesOffScreen() {
  }
  setSmall(val) {
    if(val != this._small) {
      this._small = val;
      this.typeSetSmall(this._small);
    }
  }
  typeSetSmall() {
  }
  setOnScreen(onScreen) {
    if(this._onScreen != onScreen) {
      if(onScreen) {
        this.movesOnScreen();
      }
      else {
        this.movesOffScreen();
      }
    }
    this._onScreen = onScreen;
  }
  typePause() {
  }
  pause() {
    // dont try to pause if not ready
    if(!this.isReady()) {
      return;
    }
    if(this.isPlaying()) {
      this.typePause();
    }
  }
  typeLoop() {
  }
  loop() {
    // dont try to play if not ready
    if(!this.isReady()) {
      return;
    }
    if(!this.isPlaying()) {
      this.typeLoop();
    }
  }

  // precalc first resets variables that may change every frame and
  // then calculates their values.
  // This is done, because the calculations are not performant and
  // some of those values are required more than once per frame.
  precalc(potentialHover) {
    this.precalced = false;
    this._calcedSX = null;
    this._calcedSY = null;
    this._calcedSW = null;
    this._calcedSH = null;
    // just leave everything for clarity, even if enclosed by
    // other calls
    this.screenX();
    this.screenY();
    this.screenW();
    this.screenH();
    this.calcOnScreen();
    this.calcSmall();
    if(potentialHover) {
      this.hover(this.p.mouseX, this.p.mouseY);
    }
    else {
      this.hovered = false;
      this.isWithinBorder = false;
    }
    this.precalced = true;
  }
  manageState(potentialHover) {
    if(potentialHover === undefined) {
      console.error("doTestHover is undefined");
    }
    // pre-calculate important numbers of high detail
    // due to expensive toNumber function of Decimal
    // and other non-performant calculations
    if(potentialHover) {
      this.hover(this.p.mouseX, this.p.mouseY);
    }
    else {
      this.hovered = false;
      this.isWithinBorder = false;
    }
    // reset steady every frame
    this.steady = false;

    this.typeManageState();

    this.callUserScript();
  }
  typeManageState() {
  }
  get scriptSourceString() {
    return this.userscript.scriptSourceString;
  }
  activateScript() {
    this.buildUserScript();
  }
  buildUserScript() {
    // only build userscript, if it should also
    // be called
    if(this.shouldBuildUserScript()) {
      try {
        this.tmpScriptCompileError = false;
        let fun = new Function(
          'fragment',
          'sketch',
          'modules',
          'signals',
          this.userscript.scriptSourceString
        );
        this.tmpScript = fun;
        this.warnedTmpScript = false;
      }
      catch(e) {
        console.warn("Syntax error in your temporary script:");
        console.warn(e);
        this.tmpScriptCompileError = true;
      }
    }
  }
  shouldBuildUserScript() {
    let userId = login.getCreds().id;
    let userscriptByCurrentUser = parseInt(this.userscriptAuthor) === parseInt(userId);
    let userscriptByStaff = this.userscriptByStaff;
    let userscriptTrusted = this.userscriptTrusted;

    let shouldBuild = userscriptByStaff === true;
    shouldBuild = shouldBuild || userscriptByCurrentUser === true;
    shouldBuild = shouldBuild || userscriptTrusted === true;
    // allow scripts when no one is logged in. this can be used to mess up
    // user settings and space positions kept in localStorage, though
    shouldBuild = shouldBuild || login.loggedIn() === false;
    return shouldBuild && !this.tmpScriptCompileError;
  }
  shouldExecuteUserScript() {
    return this.shouldBuildUserScript() && settings.USER_SCRIPTS_ACTIVE;
  }
  setTmpScript(sourceString, sync) {
    this.signals.scriptSetup = true;
    this.userscript.scriptSourceString = sourceString;
    this.tmpScriptCompileError = false;
    this.warnedTmpScript = false;
    this.buildUserScript();
    if(sync === true) {
      this.sync();
    }
  }
  setScriptAuthorToCurrectUser() {
    let userCreds = login.getCreds();
    this.userscriptAuthor = userCreds.id;
    // userCreds contain strings, so 'true' needs to converted to a boolean
    this.userscriptByStaff = userCreds.is_staff === 'true';
  }
  callUserScript() {
    if(this.shouldExecuteUserScript()) {
      if(typeof this.tmpScript !== 'function') {
        this.buildUserScript();
      }
      if(typeof this.tmpScript === 'function') {
        try {
          let modules = {
            messages: messages,
            positions: positions,
            changes: changes,
            settings: settings,
            selection: selection,
            ui: ui,
            api_fragments: api_fragments,
            main: sketch,
            input: input,
            manipulations: manipulate,
            _server_api_space: api_space,
            space: space,
            audio: audio,
          };
          this.tmpScript(this, sketch.renderer, modules, this.signals);
          // reset all signals
          for(const signal in this.signals) {
            this.signals[signal] = false;
          }
        }
        catch (e) {
          if(!this.warnedTmpScript) {
            console.warn("Execution error in your temporary script:");
            console.warn(e);
            this.warnedTmpScript = true;
          }
        }
      }
    }
  }
  calculateTintOpacity() {
    this._lastRelativeOsScreenArea = this.getRelativeOnScreenArea();
    //const growth = this.getRelativeOnScreenArea() // linear
    //const growth = Math.sqrt(this.getRelativeOnScreenArea()) // sqrt
    const growth = Math.pow(this.getRelativeOnScreenArea(), 1/5); // sqrt
    //const growth = 1 - Math.pow(1 - this.getRelativeOnScreenArea(), 4); // quart
    //const growth = 1 - Math.pow(2, -10 * this.getRelativeOnScreenArea()); // exponential
    //const growth = 1 - Math.pow(5, -10 * this.getRelativeOnScreenArea()); // exponential
    return this.tintOpacityAbsoluteMax - (this.tintOpacityAbsoluteMax - this.tintOpacityAbsoluteMin) * growth;
  }
  calculateFadeProgress() {
    if (this.showSteady()) {
      //restart the animation when size changed
      if(this._lastRelativeOsScreenArea !== this.getRelativeOnScreenArea()) {
        this._tintStepLen = null;
      }
      // highlight with fade in animation...
      if (this._tintStepLen === null || this._tintStepLen < 0) {
        this._tintOpacityCurrentMax = this.calculateTintOpacity();
        this._tintStepLen = Math.max(((this._tintOpacityCurrentMax - this._tintOpacity) / this.tintFadeFramesCount), 0);
      } 
      if (this._tintOpacity < this._tintOpacityCurrentMax) {
        this._tintOpacity += this._tintStepLen;
      }
      if (this._tintOpacity >= this._tintOpacityCurrentMax) {
        this._tintOpacity = this._tintOpacityCurrentMax;
      }
    } else {
      // ...and fade out.
      if (this._tintStepLen !== null) {
        if (this._tintStepLen >= 0) { // was still fading in
          this._tintOpacityCurrentMax = this.calculateTintOpacity();
          this._tintStepLen = -this._tintOpacity / this.tintFadeFramesCount; // start fading out
        }
        if (this._tintOpacity > 0) {
          this._tintOpacity += this._tintStepLen;
        }
        if (this._tintOpacity <= 0) {
          this._tintOpacity = 0;
          this._tintStepLen = null;
        }
      }
    }
  }
  /**
   * Tint the fragment to indicate it is highlighted
   */
  drawTintHighlight(rend, w, h, tintOpacity) {
    rend.noStroke();
    const r = settings.COLORS.TINT[0];
    const g = settings.COLORS.TINT[1];
    const b = settings.COLORS.TINT[2];
    rend.fill(rend.color(r, g, b, tintOpacity));
    rend.rect(0, 0, w, h);
  }
  draw() {
    if(!this.isOnScreen()) {
      // dont do anything, if not on screen and not always active
      return;
    }
    let rend = sketch.renderer;
    let x = this.screenX();
    let y = this.screenY();
    let w = this.screenW();
    let h = this.screenH();
    let x_translated = 0;
    let y_translated = 0;
    let rotation_point_x = this._relativeRotationPointX * this.screenW();
    let rotation_point_y = this._relativeRotationPointY * this.screenH();
    this.p.push();
    this.p.translate(x + rotation_point_x, y + rotation_point_y);
    this.p.rotate(this.rotation);
    this.p.translate(-rotation_point_x, -rotation_point_y);

    if(this.inp) {
      // TODO
      // only needs to be called on changes... but who cares right now?
      this.setLayout(this.inp);
      let w = this.width;
      let h = this.height;
      let textSize = this.textSize;
      // if textarea hast scrollbar
      if(this.inp.elt.scrollHeight != this.inp.elt.offsetHeight) {
        // scroll bar
      }
      else {
      }
    }

    // draw border
    if(!this.synced) {
      this.p.noFill();
      this.p.stroke(settings.COLORS.WARN);
      this.p.strokeWeight(3);
      rend.rect(x_translated-7, y_translated-7, w+14, h+14);
    }
    if(this._piggy) {
      rend.strokeWeight(2);
      rend.stroke(settings.COLORS.BAG);
      rend.noFill();
      rend.rect(x_translated-4, y_translated-4, w+8, h+8);
    }
    // selected
    if(this._selected) {
      rend.strokeWeight(2);
      rend.stroke(settings.COLORS.FOREGROUND);
      rend.noFill();
      rend.rect(x_translated-2, y_translated-2, w+4, h+4);
    }
    // default border
    else if(this.borderShown()) {
      rend.strokeWeight(1);
      rend.stroke(settings.COLORS.WEAK_FOREGROUND);
      rend.noFill();
      //rend.rect(x_translated, y_translated, w, h);
    }
    // default background
    else if(this.borderShown()) {
      rend.noStroke();
      if(this.isReady()) {
        rend.fill(55, 55, 55, 100);
      }
      else {
        rend.fill(settings.COLORS.LOADING);
      }
      //rend.rect(x_translated, y_translated, w, h);
    }

    // performance ... dont draw small fragments
    if(this.red()) {
      this.drawPlaceholder(x_translated, y_translated, w, h);
    }
    else {
      this.typeDraw(x, y, this.screenW(), this.screenH());
    }
    this.calculateFadeProgress();
    if(this._tintStepLen !== null && !this._hasImageTransparency) {
      this.drawTintHighlight(rend, w, h, this._tintOpacity);
    }
    if(this.hovered) {
      this.drawHover(x, y, this.screenW(), this.screenH());
    }
    if(this.warnedTmpScript && settings.getUserScriptRuntimeErrorDisplay()) {
      const spacing = 4;
      rend.stroke(220, 120, 0, 240);
      rend.noFill();
      rend.strokeWeight(4);
      rend.rect(
        x_translated - spacing, y_translated - spacing,
        w + 2 * spacing, h + 2 * spacing
      );
    }
    if(this.tmpScriptCompileError && settings.getUserScriptCompileErrorDisplay()) {
      const spacing = 4;
      rend.stroke(250, 0, 0, 240);
      rend.noFill();
      rend.strokeWeight(4);
      rend.rect(
        x_translated - spacing, y_translated - spacing,
        w + 2 * spacing, h + 2 * spacing
      );
    }
    // for buttons
    /*
    let cd = Math.min(this.screenW(), this.screenH()) / 2;
    let cx = x_translated + this.screenW() / 2;
    let cy = y_translated + this.screenH() / 2;
    rend.strokeWeight(4);
    rend.stroke(100, 70, 160, 255);
    //rend.ellipse(cx, cy, cd, cd);
    let cr = cd/2;
    let num = 5;
    for(let i = 0; i<num; i++) {
      let xoff = Math.sin(i / num * 2 * Math.PI);
      let yoff = Math.cos(i / num * 2 * Math.PI);
      let part = 1/5;
      let x = cx + xoff * cr;
      let y = cy + yoff * cr;
      let dx = rend.mouseX - x;
      let dy = rend.mouseY - y;
      if(Math.sqrt(dx*dx+dy*dy) < cr){
        rend.fill(255, 100, 200);
      }
      else
      {
        rend.noFill();
      }
      rend.ellipse(x, y, cd*part, cd*part);
    }
    */
    this.p.pop();
  }
  getImage() {
    if(this.typeGetImage) {
      return this.typeGetImage();
    }
    else {
      return null;
    }
  }
  /**
   * Checks if a fragment should be highlighted
   * 
   * @returns True if the fragment should be highlighted
   */
  showSteady() {
    let tool = input.onTool(settings.TOOLS.FRAGMENT)
      || input.onTool(settings.TOOLS.SELECT)
      || input.onTool(settings.TOOLS.COPY)
      || input.onTool(settings.TOOLS.OVER)
      || input.onTool(settings.TOOLS.UNDER)
      || input.onTool(settings.TOOLS.SCRIPT);
    let showSteady = tool && (this.steady || this.hovered);
    showSteady = showSteady && !selection.isPerformingSelection();
    return showSteady;
  }
  setGray(drawGray) {
    this.drawGray = drawGray;
  }
  /**
   * pass in the string name of the cursor that
   * should be drawn, when fragment is hovered,
   * f.i. 'grab'
   * use p5js's cursor variables, if possible
   */
  setHoverCursor(hoverCursor) {
    this.cursorString = hoverCursor;
  }
  /**
   * returns overrideableCursor = false, if cursor should not be overridden by specific
   * cursor function
   * */
  selectHoverCursor() {
    if(input.onTool(settings.TOOLS.FRAGMENT) && !manipulate.isDraggingFragments()) {
      this.p.cursor("grab");
      return false;
    }
    else if(input.onTool(settings.TOOLS.COPY)) {
      this.p.cursor('copy');
      return false;
    }
    else if(input.onTool(settings.TOOLS.SELECT)) {
      this.p.cursor('crosshair');
      return false;
    }
    else if(input.onTool(settings.TOOLS.OVER)
        || input.onTool(settings.TOOLS.UNDER)
        || input.onTool(settings.TOOLS.SCRIPT)) {
      this.p.cursor('pointer');
      return false;
    }
    else if(input.onTool(settings.TOOLS.DOWNLOAD)) {
      this.p.cursor('pointer');
      return false;
    }
    else if(manipulate.isDraggingFragments()) {
      this.p.cursor('grabbing');
      return false;
    }
    else if(this.cursorString) {
      this.p.cursor(this.cursorString);
      return false;
    }
    return true;
  }
  drawHover(x, y, w, h) {
    let overridableCursor = this.selectHoverCursor();
    if(this.typeSelectHoverCursor) {
      this.typeSelectHoverCursor(overridableCursor);
    }
    if(this.typeDrawHover) {
      this.typeDrawHover(x, y, w, h);
    }
  }
  drawPlaceholder(x, y, w, h) {
    if(this.typeDrawRed) {
      this.typeDrawRed(x, y, w, h);
    }
    else {
      this.p.fill(settings.COLORS.LOADING);
      this.p.noStroke(255);
      this.p.rect(x, y, w, h);
    }
  }
  // the state which will prevent native drawing of fragment type
  // and show some placeholder (red box..)
  red() {
    return this.isOnScreen() &&
      (this.disabledOnScreen() || this.typeRed());
  }
  onRemove() {
    this.typeOnRemove();
  }
  typeOnRemove() {
  }
  typeRed() {
    return false;
  }
  typeDisabledOnScreen() {
    return false;
  }
  disabledOnScreen() {
    return this.typeDisabledOnScreen();
  }
  move(x, y) {
    throw Error('use positions to move fragment');
  }
  drag(pmouseX, pmouseY, mouseX, mouseY) {
    if(this.fixed) {
      this.grabbed = false;
      return false;
    }
    // change position
    else if(this.hovered) {
      this.grabbed = true;
    }
    else {
      this.grabbed = false;
    }
    return this.grabbed;
  }
  typePointCollide(mouseX, mouseY) {
    return this.within(mouseX, mouseY);
  }
  pointCollide(mouseX, mouseY) {
    return this.typePointCollide(mouseX, mouseY);
  }
  isTransparentAt(mouseX, mouseY) {
    if(this.typeTransparentAt !== undefined) {
      return this.typeTransparentAt(mouseX, mouseY);
    }
    else {
      return false;
    }
  }
  click() {
    const self = this;
    // if preventNotaDefault is true, the default typeClick function will be
    // omitted
    let preventNotaDefault = false;
    try {
      this.clickListeners.forEach(function(listener) {
        preventNotaDefault = preventNotaDefault || listener();
      });
      Object.keys(this.namedClickListeners).forEach(function(listenerName) {
        const listener = self.namedClickListeners[listenerName];
        preventNotaDefault = preventNotaDefault || listener();
      });
    }
    catch(exception) {
      this.warnedTmpScript = false;
      console.error(exception);
      messages.error(
        "Error in a click listener from user scripts. " +
        "Please check the browser's dev console for details."
      );
    }
    if(!preventNotaDefault) {
      this.typeClick();
    }
  }
  typeClick() {
  }
  typeWheel() {
  }
  wheel(dir) {
    if(input.onTool(settings.TOOLS.ROTATE)) {
      this.rotation += dir/1;
    }
    this.typeWheel(dir);
  }

  borderShown() {
    return this._hasBorder || this._borderShown;
  }
  toggleBorderShown() {
    this._hasBorder = !this._hasBorder;
  }
  getUnscaledBorderWidth() {
    return Math.min(20, Math.max(this.h, this.w) * 0.2);
  }
  getUnscaledBorder() {
    var bx = this.x - this.getUnscaledBorderWidth();
    var by = this.y - this.getUnscaledBorderWidth();
    var bw = this.w + 2 * this.getUnscaledBorderWidth();
    var bh = this.h + 2 * this.getUnscaledBorderWidth();
    return {x: bx, y: by, w: bw, h: bh};
  }
  static checkWithin(x, y, thisx, thisy, w, h, rotation) {
    if(rotation === undefined) {
      alert("checkWithin error. check console.");
      console.error("error from BaseFragment.checkWithin(...)");
    }
    var l = thisx;
    var r = l + w;
    var t = thisy;
    var b = t + h;
    return x > l && x < r && y > t && y < b;
  }
  hover(mouseX, mouseY) {
    if(!this.precalced) {
      this.mouseX = mouseX;
      this.mouseY = mouseY;
      this.hovered = this.pointCollide(mouseX, mouseY);
    }
    return this.hovered;
  }
  setSelected(val) {
    this._selected = val;
  }
  getSelected() {
    return this._selected;
  }
  /**
   * check whether given x, y (screen coordinates)
   * are within this fragment
   */
  within(x, y) {
    var l = this.screenX();
    var r = l + this.screenW();
    var t = this.screenY();
    var b = t + this.screenH();
    return x > l && x < r && y > t && y < b;
  }

  sync() {
    if(this.canSync()) {
      api_fragments.update(this);
      multiuser.sendFragmentUpdatedInfoByIDs([this.id]);
    }
  }
  push() {
    if(this.canSync()) {
      changes._updateFragments([this]);
    }
  }
  get persistent() {
    return this._persistent && login.loggedIn();
  }
  set persistent(val) {
    this._persistent = val;
  }
  canSync() {
    return this.persistent && this.id !== undefined &&
      this.id !== null && this.id !== -1 &&
      login.loggedIn();
  }
  pushRes() {
    let self = this;
    if(
      !this.persistent ||
      self.sizeIsStored ||
      !login.loggedIn() ||
      this.isDeleted
    ) {
      return;
    }
    if(
      self.id !== undefined &&
      self.id !== -1
    ) {
      self.sizeIsStored = true;
      self.push();
    }
    else {
      // retry after short period to allow fragment
      // POST to finish
      setTimeout(function() {
        self.pushRes();
      }, 500);
    }
  }
  getPersistence() {
    // ('x', 'y', 'width', 'height', 'scale', 'content')

    let content = {};
    var persistence = {
      x: this.x, y: this.y, scale: this._scale, type: this.getTypename(), url: this.url,
      hasBorder: this._hasBorder, fixed: this.fixed, textSize: this.textSize,
      width: this.w, height: this.h, sx: this.sx, sy: this.sy, text: this.text,
      content: content, persistent: this.persistent, floating_z: this.floating_z,
      boxed: this.boxed, size_is_stored: this.sizeIsStored,
      created_by: this.created_by,
      created_by_staff: this.created_by_staff,
      userscript_author: this.userscriptAuthor,
      userscript_by_staff: this.userscriptByStaff,
      userscript_trusted: this.userscriptTrusted,
      removed_from_space: this.removed_from_space
    };
    // deep clone, to avoid shared script editing after copying fragment
    persistence.userscript = JSON.parse(JSON.stringify(this.userscript));
    if(
      this.filehashes !== '' && this.filehashes !== null &&
      this.filehashes !== undefined
    ) {
      persistence.file_hashes = this.filehashes;
    }
    if(this.name) {
      persistence.name = this.name;
    }
    return persistence;
  }

  static setFragmentData(fragment, persistence, initCall = false) {
    // the 'data' part ...
    // all fragment constructors rely on data object
    // but when setting the values of an existing fragment,
    // these values also need to be set
    fragment.removed_from_fragment = persistence.removed_from_fragment;
    fragment.maxW = persistence.width;
    fragment.maxH = persistence.height;
    fragment.w = persistence.width;
    fragment.h = persistence.height;
    if(fragment.text !== persistence.text) {
      fragment.text = persistence.text;
      // TODO: store this in backend
      fragment.textSize = 60;
      if(fragment.type === 'text' || fragment.type === 'file') {
        fragment.buildTextSvg();
      }
    }
    fragment.url = persistence.url || '';
    // end datapart

    if(persistence.name) {
      fragment.name = persistence.name;
    }

    fragment.created = new Date(persistence.created);
    fragment.changed = new Date(persistence.changed);
    fragment.created_by = persistence.created_by;
    fragment.created_by_staff = persistence.created_by_staff;
    if(fragment.floating_z != persistence.floating_z) {
      fragment.floating_z = persistence.floating_z;
      if(!initCall) {
        let top = positions.getTopFragment();
        if(fragment.floating_z === top.floating_z) {
          positions.sendFragmentToFront(fragment);
        }
        else if(positions.getBottomFragment().floating_z === fragment.floating_z) {
          positions.sendFragmentToBack(fragment);
        }
      }
    }
    fragment.id = persistence.id;
    if(persistence.file_hashes || persistence.filehashes) {
      fragment.filehashes = persistence.file_hashes || persistence.filehashes;
    }
    fragment.text = persistence.text;
    fragment.type = persistence.type;
    fragment.x = persistence.x;
    fragment.y = persistence.y;
    fragment._scale = persistence.scale;
    //fragment.w = persistence.w;
    //fragment.h = persistence.h;
    fragment.url = persistence.url;
    fragment.fixed = persistence.fixed || false;
    fragment.sx = persistence.sx || 0;
    fragment.sy = persistence.sy || 0;
    fragment.boxed = persistence.boxed || false;
    fragment.sizeIsStored = persistence.size_is_stored || false;
    // whether the fragment is supposed to be persistent beyond reload
    // the persistent property is a hack and not stored on the server,
    // but set in different places where fragment data are retrieved
    fragment.persistent = persistence.persistent || true;
    // if nothing has been stored, use the current default initialization
    
    fragment.userscript = persistence.userscript || fragment.userscript;
    fragment.userscriptAuthor = persistence.userscript_author;
    fragment.userscriptByStaff = persistence.userscript_by_staff;
    fragment.userscriptTrusted = persistence.userscript_trusted;
    fragment.setTmpScript(fragment.userscript.scriptSourceString, false);
    fragment.textSize = persistence.textSize || fragment.textSize;

    if(!isNaN(persistence.relative_rotation_point_x)) {
      fragment._relativeRotationPointX = persistence.relative_rotation_point_x;
    }
    if(!isNaN(persistence.relative_rotation_point_y)) {
      fragment._relativeRotationPointY = persistence.relative_rotation_point_y;
    }

    return fragment;
  }

  static restore(p, persistence) {
    var x = persistence.x;
    var y = persistence.y;
    var type = persistence.type;

    // handling legacy data for type
    if(type === 'IM') {
      persistence.type = 'image';
    }
    if(type === 'AU') {
      persistence.type = 'audio';
    }
    if(type === 'VI') {
      persistence.type = 'video';
    }
    if(type === 'TX') {
      persistence.type = 'text';
    }

    let data = {
      width: persistence.width, height: persistence.height,
      text: persistence.text, url: persistence.url,
      textSize: 60
    }
    var fragment = getFragment(type, x, y, data);

    fragment = BaseFragment.setFragmentData(fragment, persistence, true);
    return fragment;

  }
}

import {BaseFragment} from './base_fragment.js';
import * as settings from '../settings.js';
import * as ui from '../userinterface/ui.js';
import * as space from '../space.js';

class ImageFragment extends BaseFragment {
  constructor(p, x, y, type, data, persistent=false) {
    super(p, x, y, type, data, persistent);
    let self = this;
    this._disableTimeout = null;
    this._shrinked = false;
    this.resolutionLimited = true;
    this.url = data.url || this.url || '';
    this.filehashes = null;
    self._borderShown = false;
    self._hasBorder = false;

    this.sizeIsStored = false;
    this.invalidResolutions = {}
    //this._tintedImage = null;
    this._maskImage = null;
    this._fileExtension = "";
    this._hasImageTransparency = null; // We do not know yet if the image has transparency
    //this._tempTintedImage = null;
    this._lastCollosionResult = false;
  }
  calcRes() {
    let resolutions = [4000, 2000, 1000, 500, 250, 125];
    let curW = this.screenW();
    let curH = this.screenH();
    let curMax = Math.max(curW, curH);
    let max = Math.max(this.original_w, this.original_h);
    let resolution = null;
    resolutions.reverse().forEach(function(res) {
      //if(res > curMax && res < max && resolution === null) {
      if(res > curMax && resolution === null) {
        resolution = res;
      }
    });
    let currentSpaceMaxRes = space.getMaxImageLength();
    if(currentSpaceMaxRes != null) {
      // set resolution to smallest valid resolution
      if(resolution === null || resolution > currentSpaceMaxRes) {
        resolution = 125;
        resolutions.forEach(function(res) {
          if(res <= currentSpaceMaxRes) {
            resolution = res;
          }
        });
      }
    }
    return resolution;
  }
  debounceMipMapImage() {
    // TODO: re-check !!!
    let self = this;
    if(this._loading || this.disabled) {
      return;
    }
    if(!this.image && this.url !== '') {
      this._loading = true;
      let res = this.calcRes();
      this.curRes = res;
      this.doLoadImage(res, function(errorstring) {
        // maybe different resolutions are not available
        self.doLoadImage();
        self.invalidResolutions[res] = true;
      });
    }
    else if(this.url !== '') {
      let res = this.calcRes();
      if(this.plannedRes !== res) {
        this.plannedRes = res;
        if(this.loadTimer) {
          clearTimeout(this.loadTimer);
          this.loadTimer = null;
        }
        this.loadTimer = setTimeout(function() {
          self.doLoadImage(res, function(errorstring) {
            // maybe different resolutions are not available
            self.doLoadImage();
            self.invalidResolutions[res] = true;
          });
          self.curRes = res;
        }, 100);
      }
    }
  }
  setImages(url, width, height, filehashes) {
    let self = this;
    self.original_w = width;
    self.original_h = height;
    self.w = width;
    self.h = height;
    this.url = url;
    this.filehashes = filehashes;
    this.scaleFragmentToMax();
  }
  typeDisabledOnScreen() {
    return this.isOnScreen() && this.isSmall();
  }
  typeRed() {
    return this.image === null || this.image === undefined;
  }
  doLoadImage(size = null, failureCb) {
    let self = this;
    // Load image
    self._loading = true;

    let url = "";
    if(size !== null) {
      let urls = this.url.split('/');
      let filename = urls[urls.length - 1];
      this._fileExtension = filename.split('.').pop();
      urls = urls.splice(0, urls.length - 1);
      urls.push(size + '.' + filename);
      url = urls.join('/');
      url = settings.get_media_url() + url;
    }
    else {
      url = settings.get_media_url() + self.url;
      this._fileExtension = url.split('.').pop();
    }
    let imgDrawData = self.p.loadImage(
      url,
      function() {
        // success callback
        if(imgDrawData.width > self.maxRes) {
          console.time("resize w");
          imgDrawData.resize(self.maxRes, 0);
          console.timeEnd("resize w");
        }
        if(imgDrawData.height > self.maxRes) {
          console.time("resize h");
          imgDrawData.resize(0, self.maxRes);
          console.timeEnd("resize h");
        }
        if(!self.w || !self.h) {
          self.w = imgDrawData.width;
          self.h = imgDrawData.height;
        }
        if(self.original_w) {
          self.w = self.original_w;
        }
        if(self.original_h) {
          self.h = self.original_h;
        }
        self.image = imgDrawData;
        self.grayImage = self.p.createImage(self.image.width, self.image.height);
        let img = self.grayImage;
        self.image.loadPixels();
        switch(self._fileExtension) {
          case "png": // TODO: Add all file types that support transparency
          case "webp":
            if(self._hasImageTransparency === null) {
              self._hasImageTransparency = self.checkImageForTransparency(self.image.pixels);
            }
            break;
          default:
            self._hasImageTransparency = false;
        }
        if(self._hasImageTransparency && self._maskImage === null) {
          self.createTintedMaskImage(self.image); // TODO: use the lowest resolution
        }
        self.grayImage.loadPixels();
        img.pixels.set(self.image.pixels);
        self.grayImage.updatePixels();
        self.grayImage.filter(self.p.GRAY);
        // TODO
        // revoke it and reload it on copy if neccessary
        self._shrinked = false;
        self.pushRes();
        self._loading = false;
      },
      function(err) {
        // failure callback
        if(typeof failureCb === 'function') {
          failureCb("could not load image");
        }
        else {
          console.error("Error loading image.");
          console.error(err);
        }
      }
    );
  }
  checkImageForTransparency(imagePixels) {
    for(let i = 0; i < imagePixels.length; i +=4) {
      if(imagePixels[i+4] === 0) {
        return true;
      }
    }
    return false;
  }
  createTintedMaskImage(img) {
    const r = settings.COLORS.TINT[0];
    const g = settings.COLORS.TINT[1];
    const b = settings.COLORS.TINT[2];
    this._maskImage = this.p.createImage(img.width, img.height);
    this._maskImage.copy(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height);
    this._maskImage.loadPixels();
    for (let i = 0; i < this._maskImage.pixels.length; i+=4) {
      this._maskImage.pixels[i] = r;
      this._maskImage.pixels[i + 1] = g;
      this._maskImage.pixels[i + 2] = b;
    }
    this._maskImage.updatePixels();
  }
  drawTintHighlightWithAlpha(drawX, drawY, w, h, img) {
    this.p.image(img, drawX, drawY, w, h, this.sx, this.sy);
    this.p.drawingContext.globalAlpha = this._tintOpacity/255;
    this.p.image(this._maskImage, drawX, drawY, w, h, this.sx, this.sy);
    this.p.drawingContext.globalAlpha = 1;
  }
  typeDraw(x, y, w, h) {
    this.p.push();
    let self = this;
    let drawX = 0;
    let drawY = 0;

    if(this.image === null || this.image === undefined) {
    }
    else {
      let img = self.image;
      if(this.drawGray) {
        img = self.grayImage;
      }
      if(this._hasImageTransparency && this._tintStepLen !== null) {
        // highlight with fade animation
        // TODO: the final tinted image could be cached to improve performance
        this.drawTintHighlightWithAlpha(drawX, drawY, w, h, img);
      } else {
        this.p.image(img, drawX, drawY, w, h, this.sx, this.sy);
      }
    }
    this.p.pop();
  }
  typeGetImage() {
    return this.image;
  }
  typeManageState() {
    let disabled = !this.isOnScreen() || this.disabledOnScreen();
    if(!disabled) {
      let size = Math.max(this.screenW(), this.screenH());
      let blur = size < 300;
    }
    this.disabled = disabled;
    this.setDisabled(disabled)
    this.debounceMipMapImage();
  }
  setDisabled(disable) {
    if(!disable && this._disableTimeout !== null) {
      clearTimeout(this._disableTimeout);
      this._disableTimeout = null;
    }
    else if(disable && this.image && this._disableTimeout === null) {
      this._movedOn = false;
      this._disableTimeout = setTimeout(function() {
        if(this.image && this.image.canvas && this.image.canvas.parentNode) {
          let element = this.image.canvas;
          element.parentNode.removeChild(element);
        }
        this.image = null;
      }, 4000);
    }
  }
  typePointCollide(mouseX, mouseY) {
    return (
      this.within(mouseX, mouseY) &&
      !this.typeTransparentAt(mouseX, mouseY)
    );
  }
  typeTransparentAt(mouseX, mouseY) {
    if(this.image === undefined || this.image === null) {
      // not transparent, if image is not loaded (red)
      return false;
    } else if(this.p.pmouseX == mouseX && this.p.pmouseY == mouseY) {
      return this._lastCollosionResult;
    }
    this.image.loadPixels();
    let pixels = this.image.pixels;
    let x = mouseX - this.screenX();
    let y = mouseY - this.screenY();
    x /= this.screenW();
    y /= this.screenH();
    x = Math.round(x * this.image.width);
    y = Math.round(y * this.image.height);
    let i = (y * this.image.width + x) * 4;
    this._lastCollosionResult = pixels[i+3] < 10;
    return this._lastCollosionResult;
  }
}
export default ImageFragment;

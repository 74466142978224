let _firstAction = null;
let _lastAction = null;

export function reset() {
  _firstAction = null;
  _lastAction = null;
}

/** An Action object must provide a redo and an undo function that should change a
 * fragment and return that fragment. The fragment will be synced with the local
 * and/or remote databases.
 */
export class Action {
  constructor(change) {
    this.change = change;
    this.prev = null;
    this.next = null;
  }
  async sync() {
    // TODO sync fragments to database and catch conflicts
  }
}

export function addAction(action) {
  if(!action instanceof Action) {
    throw Error(
      'Parameter "action" must be a subclass of class Action defined in undo module'
    );
  }
  if(_lastAction) {
    delete _lastAction.next;
    _lastAction.next = action;
    action.prev = _lastAction;
    _lastAction = action;
  }
  else {
    _lastAction = action;
    _firstAction = action;
  }
  // TODO store action list in local database
  let persistence = {changes: [], position: 0};
  let actionIt = _firstAction;
  let pos = 0;
  while(actionIt) {
    if(actionIt.change) {
    }
    else {
      // this is an empty action to allow redoing after reverting the first change
      persistence.changes.push(null);
    }
    if(action === _lastAction) {
      persistence.position = pos;
    }
    pos++;
    actionIt = actionIt.next;
  }
}

export function undo() {
  if(_lastAction) {
    if(_lastAction.change) {
      _lastAction.change.undo();
    }
    if(!_lastAction.prev) {
      let dummy = {next: _lastAction, change: null};
      dummy.prev = dummy;
      _lastAction.prev = dummy;
    }
    _lastAction = _lastAction.prev;
  }
}

export function redo() {
  if(_lastAction && _lastAction.next) {
    if(_lastAction.next.change) {
      _lastAction.next.change.redo();
    }
    _lastAction = _lastAction.next;
  }
}


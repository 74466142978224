import {OngoingChange} from './OngoingChange.js';
import {_updateFragments} from './utilities.js';
import * as positions from '../positions.js';

/** MoveChange is combining ScaleChange and PositionChange as one OngoingChange
*/
export class CombinedChange extends OngoingChange {
  constructor(fragments, dx, dy, factor=null, point=null, timeout=-1) {
    super(fragments, timeout);
    let self = this;
    this.hasScaled = false;
    // translation related
    // init
    this.dx = 0;
    this.dy = 0;
    // change
    if(dx != null && dy != null) {
      this.translate(dx, dy);
    }

    // scale related
    // init
    self.diff = [];
    let changeId = 0;
    fragments.forEach(function(frag) {
      let id = frag.id;
      self.diff[id] = {};
      self.diff[id].x = 0;
      self.diff[id].y = 0;
      self.diff[id].scale = 1;
    });

    // change
    if(factor != null && point != null) {
      self.scale(factor, point);
    }
  }
  scale(factor, point) {
    let self = this;
    this.hasScaled = true;
    self._fragments.forEach(function(frag) {
      let diff = positions.scaleFragment(frag, factor, point);
      let id = frag.id;
      self.diff[id].x += diff.x;
      self.diff[id].y += diff.y;
      self.diff[id].scale *= factor;
    });
  }
  translate(dx, dy) {
    let globalDiff = positions.screenToGlobalDiff({dx: dx, dy: dy})
    let gdx = globalDiff.dx;
    let gdy = globalDiff.dy;
    this.dx += gdx;
    this.dy += gdy;
    this._fragments.forEach(function(fragment) {
      positions.moveFragment(fragment, gdx, gdy);
    });
  }
  progress(dx=null, dy=null, factor=null, point=null) {
    if(dx != null && dy != null) {
      this.translate(dx, dy);
    }
    if(factor !== null && point !== null) {
      this.scale(factor, point);
    }
  }
  s_finalize(dx=null, dy=null) {
    // translate
    if(dx !== null && dy !== null) {
      this.translate(dx, dy);
    }
    // scale
    super.s_finalize();
    _updateFragments(this._fragments);
  }
  undo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      // translate
      positions.moveFragment(frag, -self.dx, -self.dy);
      // scale
      let id = frag.id;
      let dx = self.diff[id].x;
      let dy = self.diff[id].y;
      let dscale = self.diff[id].scale;
      frag.setIndividualScale(frag.getIndividualScale() / dscale);
      frag.x += dx;
      frag.y += dy;
    });
    _updateFragments(this._fragments);
  }
  redo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      // translate
      positions.moveFragment(frag, self.dx, self.dy);
      // scale
      let id = frag.id;
      let dx = self.diff[id].x;
      let dy = self.diff[id].y;
      let dscale = self.diff[id].scale;
      frag.setIndividualScale(frag.getIndividualScale() * dscale);
      frag.x -= dx;
      frag.y -= dy;
    });
    _updateFragments(this._fragments);
  }
}

import * as login from '../api/login.js';
import * as selection from '../selection.js';
import * as positions from '../positions.js';
import * as settings from '../settings.js';
import { getSketch, getHTMLOverlay, setHTMLOverlay } from '../main.js';
import ace from 'ace-builds';
import "ace-builds/webpack-resolver";


export const userscriptEditor = () => {
  const sketch = getSketch();
    let mousePoint = {
      x: sketch.mouseX,
      y: sketch.mouseY
    };
    let fragmentAtMouse = positions.getFragmentAt(mousePoint);
    if(fragmentAtMouse === undefined || fragmentAtMouse === null) {
      console.warn("No fragment at mouse. This might be an effect " +
      "of temporary scripts");
    }
    else {
      let zoomPage = document.getElementById('zoom-page');
      let p5elem = sketch.createDiv();
      let ta = sketch.createDiv().elt;
      ta.style.width = "70vw";
      ta.style.height = "70vh";
      ta.style.top = "15vh";
      ta.style.left = "15vw";
      ta.id="ace";
      ta.textContent="";
      if(fragmentAtMouse.scriptSourceString) {
        ta.innerHTML = fragmentAtMouse.scriptSourceString;
        if(!fragmentAtMouse.shouldBuildUserScript()) {
          ta.readOnly = true;
          ta.style.background = "#ccc";
          ta.style.color = "#666";
          ta.style.fontWeight = "bold";
        }
      }
      let editor = ace.edit("ace");
      editor.autoIndent = true;
      editor.setOptions({
        mode: "ace/mode/javascript",
        showPrintMargin: false
      });
      editor.setTheme("ace/theme/sqlserver")

      let elem = p5elem.elt;
      elem.id = "ace-parent";
      let downTarget;
      const setTarget = (event) => {
        event.preventDefault();
        downTarget = event.target;
      };
      document.onmousedown = setTarget;

      let keypressdefault = ta.onkeypress;
      let modifierKeyReleased = false;
      ta.onkeyup = function(ev) {
        if(ev.key === settings.KEYS.SCRIPT) {
          modifierKeyReleased = true;
        }
      }
      ta.onkeypress = function(ev) {
        if(!modifierKeyReleased && ev.key === settings.KEYS.SCRIPT) {
          ev.preventDefault();
          return false;
        }
      };
      function endScripting() {
        getHTMLOverlay().overlay.remove();
        setHTMLOverlay(null);
        zoomPage.style.filter = "";
        let src = editor.getValue();
        function setUserScript(fragment) {
          // if the script has changed, the user should be
          // changed to the current user
          // do this before calling setTmpScript, as the
          // script will not build otherwise
          // TODO: the order should not matter, make sure
          // a script that should be executed, is built in
          // fragment, if it is not yet available
          if(src !== fragment.userscript.scriptSourceString) {
            let userCreds = login.getCreds();
            fragment.setScriptAuthorToCurrectUser();
          }
          fragment.setTmpScript(src, true);
        }
        if(selection.selectionContains(fragmentAtMouse)) {
          selection.getSelectedFragments().forEach(function(frag) {
            setUserScript(frag);
          });
        }
        else {
          setUserScript(fragmentAtMouse);
        }
      }
      elem.style.width = "100vw";
      elem.style.height = "100vh";
      elem.style.position = "absolute";
      elem.style.zIndex = "10";
      elem.style.top = "0";
      elem.style.left = "0";
      elem.style.background = "#fffa";
      elem.style.textAlign = "center";
      document.body.append(elem);
      zoomPage.style.filter = "blur(2px)";
      setHTMLOverlay({overlay: p5elem});
      elem.addEventListener('mousemove', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('wheel', function(ev) {
        ev.stopPropagation();
      }, {passive:false});
      elem.addEventListener('keypress', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('keydown', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('dragstart', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('drag', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('dragend', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('dragenter', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('dragover', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('dragleave', function(ev) {
        ev.stopPropagation();
      });
      elem.addEventListener('drop', function(ev) {
        ev.stopPropagation();
      });
      ta.addEventListener('click', function(ev) {
        ev.stopPropagation();
      });
      ta.onkeydown = function(ev) {
        if(ev.code == 'Escape') {
          endScripting();
        }
      };
      elem.addEventListener('click', (ev) => {
        if(ev.target.id === downTarget.id) {
          endScripting();
        }
      });
      elem.append(ta);
      ta.childNodes[0].focus();
      elem.focus();
    }
}

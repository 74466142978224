import {CopyChange} from './changes/CopyChange.js';
import * as positions from './positions.js';

/**
 * clipboard stores fragments and position at time of copy
 * [{fragment, screenX, screenY, scale}, ...]
 */
let clipboard = {
};
let globalScale = null;

export function copy(fragments) {
  if(fragments.length > 0) {
    clipboard = [];
    globalScale = positions.getGlobalScaleLowPrec();
    fragments.forEach(function(frag) {
      let clipFrag = {
        fragment: frag,
        screenX: frag.screenX(),
        screenY: frag.screenY(),
        scale: frag.getIndividualScale()
      };
      clipboard.push(clipFrag);
    });
  }
}

export function paste() {
  if(clipboard !== null && clipboard.length > 0) {
    let frags = [];
    clipboard.forEach(function(clipFrag) {
      frags.push(clipFrag.fragment);
    });
    let copyChange = new CopyChange(frags, 0, 0);
    let scaleMult = globalScale / positions.getGlobalScaleLowPrec();
    clipboard.forEach(function(clipFrag, idx) {
      let coords = positions.screenToGlobalCoords({
        x: clipFrag.screenX,
        y: clipFrag.screenY
      });
      copyChange._fragments[idx].x = coords.x;
      copyChange._fragments[idx].y = coords.y;
      copyChange._fragments[idx].setIndividualScale(
        clipFrag.scale * scaleMult
      );
    });
    copyChange.finalize(0, 0);
  }
}

export function reverse(array, callback) {
  var i = array.length - 1;
  for( ; i>=0; i--) {
    let abort = callback(array[i], i, array.length);
    if(abort) {
      break;
    }
  }
}

export function checkWithin(x, y, thisx, thisy, w, h) {
  var l = thisx;
  var r = l + w;
  var t = thisy;
  var b = t + h;
  return x > l && x < r && y > t && y < b;
}
export function recommendFunction(name, object) {
  if(object[name] === undefined || typeof object[name] !== 'function') {
    console.warn(`Every instance of type ${object.name} should have` +
      `an implementation of the function "${name}" !`);
  }
}
export function expectFunction(name, object) {
  if(object[name] === undefined || typeof object[name] !== 'function') {
    console.error(`Every instance of type ${object.name} needs an implementation` +
      `of the function "${name}" !`);
  }
}
import {OngoingChange} from './OngoingChange.js';
import {_updateFragments} from './utilities.js';
import * as positions from '../positions.js';

export class ScaleChange extends OngoingChange {
  constructor(fragments, factor, point, timeout) {
    super(fragments, timeout);
    let self = this;
    self.diff = [];
    let changeId = 0;
    fragments.forEach(function(frag) {
      let id = frag.id;
      self.diff[id] = {};
      self.diff[id].x = 0;
      self.diff[id].y = 0;
      self.diff[id].scale = 1;
    });

    self.scale(factor, point);
  }
  progress(factor, point) {
    let self = this;
    self.scale(factor, point);
  }
  s_finalize() {
    super.s_finalize();
    let self = this;
    _updateFragments(this._fragments);
  }
  undo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      let id = frag.id;
      let dx = self.diff[id].x;
      let dy = self.diff[id].y;
      let dscale = self.diff[id].scale;
      frag.setIndividualScale(frag.getIndividualScale() / dscale);
      frag.x += dx;
      frag.y += dy;
    });
    _updateFragments(this._fragments);
  }
  redo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      let id = frag.id;
      let dx = self.diff[id].x;
      let dy = self.diff[id].y;
      let dscale = self.diff[id].scale;
      frag.setIndividualScale(frag.getIndividualScale() * dscale);
      frag.x -= dx;
      frag.y -= dy;
    });
    _updateFragments(this._fragments);
  }
  scale(factor, point) {
    this.resetTimer();
    let self = this;
    self._fragments.forEach(function(frag) {
      let diff = positions.scaleFragment(frag, factor, point);
      let id = frag.id;
      self.diff[id].x += diff.x;
      self.diff[id].y += diff.y;
      self.diff[id].scale *= factor;
    });
  }
}

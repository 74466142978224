import * as DecimalLight from 'decimal.js-light';
import * as localstore from './localstore.js';
//export let API_BASE_URL = 'api/'; this is never read
//export let API_HOST = 'api/'; this is never read
//export let API_PORT = '8081'; this is never read

export function get_media_url() {
  if(
    document.location.host.substr(0, 9) === 'localhost' ||
    document.location.host.substr(0, 8) === '192.168.' ||
    document.location.host.substr(0, 9) === '127.0.0.1'
  ) {
    return document.location.origin + "/api/";
  }
  else {
    return document.location.origin + "/";
  }
}
export function get_api_url() {
  if(
    document.location.host.substr(0, 9) === 'localhost' ||
    document.location.host.substr(0, 8) === '192.168.' ||
    document.location.host.substr(0, 9) === '127.0.0.1'
  ) {
    return "api/";
  }
  else {
    return "";
  }
}

// Number type for increased precision
// this number type (Decimal) is used to project fragment's world coordinates
// to fragment's screen coordinates, which improves precision due to
// precise intermediate values.
// but it is not used to store fragment's world coordinates, which are
// stored as FloatField in the databse.
export let Decimal = DecimalLight.Decimal;

if(Decimal === DecimalLight) {
  Decimal.set({
      precision: 100,
      rounding: Decimal.ROUND_HALF_UP,
      toExpNeg: -20,
      toExpPos: 41
  });
}

let _speedFreeze = false;
let _scaleFreeze = false;

export function brakesOn() {
  return !_speedFreeze && !_scaleFreeze;
}
export function scaleFreeze() {
  return _scaleFreeze;
}
export function speedFreeze() {
  return _speedFreeze;
}

export function resetFalling() {
  _speedFreeze = false;
  _scaleFreeze = false;
}

export function toggleFalling() {
  _speedFreeze = !_speedFreeze;
  _scaleFreeze = !_scaleFreeze;
}

// Which way to render text fragments
export let TEXT = "TEXT";
export let IMAGE_TEXT = "IMAGE_TEXT";
export let textType = IMAGE_TEXT;
export function getTextBorder() {
  let val = localstore.getItem('text_border', true);
  // 1 means not set, for some reason...
  if(val === 1) {
    val = false;
  }
  else if(val === 'true') {
    return true;
  }
  else {
    return false;
  }
}
export function setTextBorder(val) {
  let s;
  if(val) {
    s = 'true';
  }
  else {
    s = 'false'
  }
  localstore.setItem('text_border', s, true);
}
export let imagesAsText = false;

// TODO: check if this should be a constant
export let textResolutionFactor = 0.5;
export let DRAW = {
  DEBUG: true
};

// TODO: do not export the variable
// TODO: create a setter
export let USER_SCRIPTS_ACTIVE = true;
export function setUserScriptsActive(val) {
  USER_SCRIPTS_ACTIVE = val;
};

let USER_SCRIPT_RUNTIME_ERROR_DISPLAY = false;
export function getUserScriptRuntimeErrorDisplay() {
  return USER_SCRIPT_RUNTIME_ERROR_DISPLAY;
}
export function setUserScriptRuntimeErrorDisplay(val) {
  USER_SCRIPT_RUNTIME_ERROR_DISPLAY = val;
};

let USER_SCRIPT_COMPILE_ERROR_DISPLAY = false;
export function getUserScriptCompileErrorDisplay() {
  return USER_SCRIPT_COMPILE_ERROR_DISPLAY;
}
export function setUserScriptCompileErrorDisplay(val) {
  USER_SCRIPT_COMPILE_ERROR_DISPLAY = val;
};

// TODO: maybe rename to maxImageSideDisplayLength
//TODO maybe make accessible via getter only
// the maximum for the longest dimension of an image
// i.e. 4000 -> 4000 x 4000 
export let MAX_RESOLUTION = 4000000000000000;

export let DropMode = {
  GRID: 'GRID',
  STRIP: 'STRIP'
}
export let DROP_MODE = DropMode.GRID;


export let ZOOM_STEP = 1.05;
// TODO: make constant
export let ZoomMode = {
  MOUSE: 'MOUSE',
  TRACKPAD: 'TRACKPAD',
}
export let _INITIAL_ZOOM_MODE = ZoomMode.TRACKPAD;
let store = window.localStorage;
export function setZoomMode(val) {
  store.setItem('zoom_mode', val);
}
export function getZoomMode() {
  let zoomMode = store.getItem('zoom_mode');
  if(zoomMode === null) {
    zoomMode = _INITIAL_ZOOM_MODE
    store.setItem('zoom_mode', _INITIAL_ZOOM_MODE);
  }
  return zoomMode;
}

// colors as [r, g, b, a]
//[95, 55, 95, 150];
export let COLORS = {
  BACKGROUND: [0, 0, 0],
  BAG: [250, 50, 200],
  TINT: [255, 0, 255],
  FOREGROUND: [255, 255, 255],
  WEAK_FOREGROUND: [120, 120, 120],
  FOREGROUND_DEFAULT: [255, 255, 255],
  WEAK_FOREGROUND_DEFAULT: [120, 120, 120],
  MESSAGE_LOG: [255, 255, 255],
  MESSAGE_LOG_STROKE: [100, 100, 100],
  MESSAGE_ERR: [255, 0, 0],
  MESSAGE_ERR_STROKE: [100, 50, 50],
  //LOADING: [255, 71, 61, 200],
  LOADING: [255, 255, 255, 0xEE],
  CLEAN: [0, 150, 0],
  DIRTY: [250, 200, 0],
  SYNC_ERROR: [250, 50, 0],
  INTERACTION: [180, 0, 220],
  UIBACK: [255, 255, 255, 0xEE],
  UISOFTBACK: [255, 255, 255, 0x99],
  UIFRONT: [0, 0, 0, 0xDD],
  BACKGROUND_DEFAULT: [0, 0, 0],
  SELECT_FRAME: [200, 200, 20],
  WARN: [230, 130, 50],
  FOCUS: [200, 90, 200],
  //LOADING: [50, 100, 100, 200],
};


// no differention between lower and upper case in p5js.keyIsDown
export let KEYS = {
  RESUME: 'm',
  ROTATE_MODIFIER: 'r',
  SELECTION_MODIFIER: 's',
  FRAGMENT_MODIFIER: 'f',
  CTRL: 'CTRL',
  SHIFT: 'SHIFT',
  ALT: 'ALT',
  ESCAPE: 'ESCAPE',
  COMBINED_SELECT_ALL: 'a',
  COMBINED_UNDO: 'z',
  COMBINED_REDO: 'y',
  COPY: 'c',
  PASTE: 'v',
  BAG: 'b',
  OVER: 'o',
  UNDER: 'u',
  GO_LEFT: 37,
  GO_RIGHT: 39,
  GO_UP: 38,
  GO_DOWN: 40,
  FALLING: 32,
  TEXT: 't',
  SCRIPT: 'p',
  DOWNLOAD: 'd',
  INSERT: 'i',
}

export let TOOLS = {
  TEXT: 'text',
  WALK: 'walk',
  FRAGMENT: 'fragment',
  ROTATE: 'rotate',
  COPY: 'copy',
  SCRIPT: 'script',
  SELECT: 'select',
  BAG: 'bag',
  OVER: 'over',
  UNDER: 'under',
  DOWNLOAD: 'download',
  INSERT: 'insert',
};

export let ACTIONS = {
  DELETE: 'delete',
  UNDO: 'undo',
  REDO: 'redo',
  CLIPBOARD_COPY: 'clipboard_copy',
  CLIPBOARD_PASTE: 'clipboard_paste',
  DESELECT: 'deselect',
  CENTER: 'center',
  HIDE_ALL: 'hide_all',
  BRAKES: 'toggle_brakes',
  FULLSCREEN: 'fullscreen',
  HOME: 'home',
}

// functions, not settings
// TODO: don not require to pass p
function CtrlCmd(p) {
  let CTRLCMD = false;
  if(window.navigator.platform === 'MacIntel') {
    CTRLCMD = p.keyIsDown(p.CONTROL);
  }
  else {
    CTRLCMD = p.keyIsDown(p.CONTROL);
  }
  return CTRLCMD;
}

// TODO: don not require to pass p
export function keyDown(p, setting) {
  if(setting === KEYS.CTRL) {
    return CtrlCmd(p);
  }
  else if(setting === KEYS.COPY) {
    return p.keyIsDown(setting.toLowerCase().charCodeAt(0)) ||
      p.keyIsDown(setting.toUpperCase().charCodeAt(0));
  }
  else if(setting === KEYS.SHIFT) {
    return p.keyIsDown(p.SHIFT);
  }
  else if(setting === KEYS.ALT) {
    return p.keyIsDown(p.ALT);
  }
  else if(setting === KEYS.ESCAPE) {
    return p.keyIsDown(p.ESCAPE);
  }
  else if(setting === KEYS.DELETE) {
    return p.keyIsDown(p.DELETE);
  }
  else if(typeof setting === 'number') {
    return p.keyIsDown(setting);
  }
  else if(setting.length === 1) {
    return (
      p.keyIsDown(setting.toLowerCase().charCodeAt(0)) ||
      p.keyIsDown(setting.toUpperCase().charCodeAt(0))
    );
  }
  else {
    console.error('Invalid keyDown input in settings: ' + setting);
  }
}

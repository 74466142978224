import {CombinedChange} from './CombinedChange.js';
import {_updateFragments} from './utilities.js';
import {_createFragments} from './utilities.js';
import {_deleteFragments} from './utilities.js';
import {_copyAndAppend} from './utilities.js';
import * as positions from '../positions.js';

export class CopyChange extends CombinedChange {
  constructor(fragments, dx, dy, factor, point, timeout) {
    let copies = [];
    fragments.forEach(function(frag) {
      copies.push(_copyAndAppend(frag, dx, dy));
    });
    super(copies, dx, dy, factor, point, timeout);
  }
  s_finalize(dx=null, dy=null) {
    if(dx !== null && dy !== null) {
      this.translate(dx, dy);
    }
    _createFragments(this._fragments);
  }
  undo() {
    positions.removeFragments(this._fragments);
    _deleteFragments(this._fragments);
  }
  redo() {
    const sync = false
    positions.addFragmentsOnTop(this._fragments, sync);
    _updateFragments(this._fragments);
  }
}

import * as api from './api.js';
import * as space from './space.js';
import * as msg from '../messages.js';

let inProgress = 0;
let dirty = false;
export function addProgress() {
  inProgress += 1;
}
export function removeProgress() {
  inProgress -= 1;
}
export function syncError() {
  return dirty;
}
export function isPushed() {
  return inProgress === 0;
}

export async function get_list(fragments) {
  let fragment_ids = [];
  fragments.forEach(function(frag) {
    if(frag.canSync()) {
      fragment_ids.push(frag.id);
    }
  });
  return get_list_by_ids(fragment_ids);
}

export async function get_list_by_ids(fragment_ids) {
  try {
    let url = 'room/fragments/';
    // wrap the list in another list, for query parameters
    let query_params = {
      fragment_ids: fragment_ids,
      room_id: space.getSpaceId()
    };
    let res = await api.auth_get(url, query_params);
    if(res.res.ok) {
      let ret = {};
      let frags = res.json.fragments;
      frags.forEach(function(frag) {
        ret[frag.id] = frag;
      });
      return ret;
    }
    else {
      console.error(res.json || res.text);
      return {};
    }
  }
  catch(e) {
    //return updateErr(e);
    console.log(e);
  }
}

export async function del(fragments) {
  addProgress();

  function onSuccess() {
    removeProgress();
    return true
  }

  function onError(e) {
    console.error(e);
    dirty = true;
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    return true;
  }

  if (fragments && fragments.length > 0) {
    try {
      const url = 'room/fragment/remove_from_room/';
      const body = {
        fragments: fragments.map((f) => {
          const simpleFragment = {};
          simpleFragment.id = f.id;
          return simpleFragment;
        }),
        space_id: space.getSpace().id
      };
      const res = await api.auth_delete_req(url, body);
      if (res.res.ok) {
        return onSuccess();
      } else {
        return onError(res.json || res.text);
      }
    } catch (e) {
      return onError(e);
    }
  }
  else {
    return true;
  }
}

export async function batch_update(fragments) {
  addProgress();
  function onSuccess() {
    removeProgress();
    return true
  }
  function onError(e) {
    dirty = true;
    console.error(e);
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    return true;
  }
  let cur_space = space.getSpace();
  if (!cur_space || !cur_space.id) {
    return onError('Could not save fragment, because no space is loaded.');
  }
  const fragments_json = fragments.map(fragment => {
    const json = fragment.getPersistence();
    json.id = fragment.id;
    return json;
  });
  const valid = fragments_json.every(fragment_json => validate(fragment_json));
  if(valid) {
    try {
      let res = await api.auth_put('room/fragments/', {fragments: fragments_json, room: cur_space.id});
      if(res.res.ok) {
        return onSuccess();
      } else {
        return onError(res.json || res.text);
      }
    }
    catch(e) {
      return onError(e);
    }
  }
}

export async function update(fragment) {
  addProgress();
  function updateSuccess() {
    removeProgress();
    return true
  }
  function updateErr(e) {
    dirty = true;
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    console.error(e);
    return true;
  }
  let json = await prepareFragmentJson(fragment);
  if(
    json !== null &&
    fragment &&
    fragment.id !== null &&
    fragment.id !== undefined
  ) {
    try {
      let url = 'room/fragment/' + fragment.id;
      let res = await api.auth_put(url, json);
      if(res.res.ok) {
        return updateSuccess();
      }
      else {
        return updateErr(res.json || res.text);
      }
    }
    catch(e) {
      return updateErr(e);
    }
  }
}

export async function create(fragments) {
  // report success, if no fragments are to be synced
  if(fragments.length === 0) {
    return true;
  }
  addProgress();
  function onSuccess(fragment_ids) {
    fragment_ids.forEach(function(frag_id, list_idx) {
      fragments[list_idx].id = frag_id;
    });
    removeProgress();
    return true
  }
  function onError(e) {
    dirty = true;
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    console.error(e);
    return true;
  }
  let cur_space = space.getSpace();
  if (!cur_space || !cur_space.id) {
    return onError('Could not save fragment, because no space is loaded.');
  }
  const fragments_json = fragments.map(fragment => fragment.getPersistence());
  const valid = fragments_json.every(fragment_json => validate(fragment_json));
  if(valid) {
    try {
      let res = await api.auth_post('room/fragments/', { fragments: fragments_json, room: cur_space.id });
      if(res.res.ok) {
        return onSuccess(res.json);
      } else {
        return onError(res.json || res.text);
      }
    }
    catch(e) {
      return onError(e);
    }
  }
  else {
    return onError("Invalid fragment data passed");
  }
}

async function prepareFragmentJson(fragment) {
  function jsonSuccess() {
    return json;
  }
  function jsonErr(e) {
    console.error(e);
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    return null;
  }
  let space_id = null;
  let json = fragment.getPersistence();
  let valid = validate(json);
  if(valid) {
    try {
      let cur_space = space.getSpace();
      if(cur_space !== null) {
        space_id = cur_space.id;
        json.room = space_id;
        return jsonSuccess();
      }
      else {
        return jsonErr('Could not save fragment, because no space is loaded.');
      }
    }
    catch(e) {
      return jsonErr(e);
    }
  }
  else {
    return jsonErr("Invalid fragment data passed");
  }
}

function validate(json) {
  function present(key) {
    let valid = json[key] !== undefined && json[key] !== null;
    if(!valid) {
      console.error(key + ' is not present in fragment!');
    }
    return valid;
  }
  let valid = true;
  valid = valid && present('x');
  valid = valid && present('y');
  valid = valid && present('width');
  valid = valid && present('height');
  valid = valid && present('scale');
  valid = valid && present('content');
  valid = valid && present('url');
  if(json.url !== '') {
    valid = valid && present('file_hashes');
  }

  return valid;
}

import {BaseFragment} from './base_fragment.js';
import * as sketch from '../main.js';
import * as input from '../input.js';
import * as settings from '../settings.js';
import * as positions from '../positions.js';
import * as manipulate from '../manipulations.js';
import * as text_module from '../userinterface/text.js';
import MyFont from '../fonts/Muli-SemiBold.ttf';

export class TextFragment extends BaseFragment {
  constructor(p, x, y, type, data, persistent=false) {
    super(p, x, y, type, data, persistent);
    this.text = data.text;
    this.fontName = null;
    this.myFont = MyFont;

    this.setReady(true);
    this.textStrokeWeight = 3;
    this.textColor = [255, 255, 255, 240];
    this.textStrokeColor = [0, 0, 0];
    this.textSize = data.textSize;
    if(!this.text || this.text === '') {
      this.text = "…";
      this.buildTextSvg();
      this.text = "";
    }
    else {
      this.buildTextSvg();
    }
  }
  html_to_xml(html) {
    let doc = document.implementation.createHTMLDocument('');
    doc.write(html);
    // You must manually set the xmlns if you intend to immediately serialize     
    // the HTML document to a string as opposed to appending it to a
    // <foreignObject> in the DOM
    doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI);
    // Get well-formed markup
    html = (new XMLSerializer).serializeToString(doc.body);
    return html;
  }
  buildTextSvg() {
    let self = this;
    let span = this.buildTextContainerElement('pre');
    let div = span.elt;
    div.textContent = self.text
    document.body.append(div);
    let width = div.offsetWidth;
    let height = div.offsetHeight;
    this.w = width;
    this.h = height;
    div.style.height = '';
    div.style.width = '';
    if(settings.getTextBorder()) {
      div.style.WebkitTextStroke = '1px rgb(' + settings.COLORS.BACKGROUND.join(',') + ')';
    }

    const fontName = this.fontName === null ? sketch.getFontName() : this.fontName;

    let htmldata = '<svg xmlns="http://www.w3.org/2000/svg" '
      + 'width="' + Math.floor(width) +'" '
      + 'height="' + Math.floor(height) + '" '
      + 'font-family="' + fontName + '" '
      + '>'
      + '<foreignObject width="100%" height="100%">'
      + this.html_to_xml(div.outerHTML)
      + '</foreignObject>'
      + '</svg>';
    let dataurl = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(htmldata);

    this.textSVG = new Image();
    this.textSVG.style.color = 'rgb(' + settings.COLORS.FOREGROUND.join(',') + ')';
    this.textSVG.onload = function () {
      self.textSVGdone = true;
      document.body.removeChild(div);
    }
    this.textSVG.src = dataurl;
    this.inp = null;
  }
  render_html_to_canvas(ctx, x, y, width, height) {
    let self = this;
    if(!this.textSVG && !this.textSVGreq) {
      this.textSVGreq = true;
      this.buildTextSvg();
    }
    if(self.textSVGdone && self.textSVG.width > 0 && self.textSVG.height > 0) {
      ctx.drawImage(
        self.textSVG,
        x,
        y,
        width,
        height
      );
    }
  }
  setText(text) {
    this.text = text;
  }
  removeTextArea() {
    sketch.getHTMLOverlay().overlay.remove();
    sketch.setHTMLOverlay(null);
  }
  inputModeToViewMode() {
    if(this.inp.value().trim() === "") {
      manipulate.deleteFragments([this]);
      this.removeTextArea();
    }
    else {
      this.text = this.inp.value();
      this.removeTextArea();
      if(this.ontexteditend) {
        if(typeof(this.ontexteditend) === "function") {
          this.ontexteditend();
        }
      }
      this.buildTextSvg();
      this.inp = null;
      this.push();
      this.imgDirty = true;
    }

    const styleSheet = document.styleSheets[0]
    if(styleSheet.rules[0].selectorText === 'textarea.text-fragment-input::selection') {
      styleSheet.removeRule(0);
    }
  }
  /** ontexteditend
   *
   * Will be called, when edit of a text field is finished by leaving the text
   * edit mode (pressing ESC / clicking somewhere / ..?).
   * Can be overwritten externally.
   */
  ontexteditend() {
  }
  typeClick() {
    let self = this;

    let inp = this.buildTextContainerElement();
    sketch.setHTMLOverlay({overlay: inp, element: this});
    this.addCallbacksToInputElement(inp);
    self.calcAndSetSize(inp.value());
  }
  isNewTextFragment() {
    return this.text === " ";
  }
  addCallbacksToInputElement(inp) {
    let self = this;
    let modifierKeyPressed = self.isNewTextFragment() && input.transientTool();

    inp.elt.addEventListener('mousedown', (ev) => {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('wheel', function(ev) {
      ev.preventDefault();
    });
    inp.elt.addEventListener('keypress', function(ev) {
      if(modifierKeyPressed && ev.key === settings.KEYS.TEXT) {
        ev.preventDefault();
      }
      ev.stopPropagation();
      return false;
    });
    inp.elt.addEventListener('keyup', function(ev) {
      if(ev.key === settings.KEYS.TEXT) {
        modifierKeyPressed = false;
      }
    });
    inp.elt.addEventListener('keydown', function(ev) {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('dragstart', function(ev) {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('drag', function(ev) {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('dragend', function(ev) {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('dragenter', function(ev) {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('dragover', function(ev) {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('dragleave', function(ev) {
      ev.stopPropagation();
    });
    inp.elt.addEventListener('drop', function(ev) {
      ev.stopPropagation();
    });
    // exit on Escape
    inp.elt.onkeydown = function(ev) {
      if(ev.code == 'Escape') {
        self.inputModeToViewMode();
      }
    };
    inp.elt.oninput = function(ev) {
      self.calcAndSetSize(inp.value());
    }
  }
  htmlText() {
    return this.text.replace(new RegExp('\n', 'g'), '<br>');
  }
  typeSetChanged(val) {
    this._html_elem_change = val;
  }
  buildTextContainerElement(containerType = 'textarea') {
    let self = this;
    let width = -1;
    let height = -1;
    const fontName = this.fontName === null ? sketch.getFontName() : this.fontName;
    if(containerType === 'textarea') {
      // This only determins the size of the textspan
      let span = this.buildTextContainerElement('span').elt;
      span.innerText = self.htmlText();
      document.body.append(span);
      width = span.offsetWidth;
      height = span.offsetHeight;
      document.body.removeChild(span);
    }
    self.inp = self.p.createElement(containerType, self.text);
    var inp = this.inp;
    inp.style('padding', '4px');
    inp.style('margin', '0');
    inp.style('resize', 'none');
    inp.style('backgroundColor', '#fafafa00');
    inp.style('color', 'rgb(' + settings.COLORS.FOREGROUND.join(',') + ')');
    inp.style('border', 'none');
    inp.elt.style.fontFamily = fontName;
    if(containerType === 'textarea') {
      inp.elt.style.background = '#fafafa2b';
      // set outline (border when focused) and caret color
      const focusColor = 'rgb(' + settings.COLORS.FOCUS.join(',') + ')';
      inp.style('caret-color', focusColor);
      inp.style('outline', '2px solid ' + focusColor);
      inp.elt.classList.add('text-fragment-input');
      const styleSheet = document.styleSheets[0]
      styleSheet.insertRule('textarea.text-fragment-input::selection '
        + '{ background: ' + focusColor + '; }', 0);
    }

    // textarea width does not match SVG width, leading to forced
    // line breaks messing with height ....
    if(width !== -1 && height !== -1) {
      inp.style('width', width + 'px');
      inp.style('height', height + 'px');
    }
    else {
      inp.style('width', 'max-content');
      inp.style('height', 'max-content');
      inp.style('width', '-moz-max-content');
      inp.style('height', '-moz-max-content');
      inp.style('width', '-webkit-max-content');
      inp.style('height', '-webkit-max-content');
    }

    if(containerType === 'textarea') {
      this._html_elem_change = true;
      self.setLayout(inp);
    }
    else {
      // hide the element using zIndex to avoid it glitching on screen
      inp.elt.style.zIndex = -1000;
      inp.elt.style.position = 'absolute';
      inp.elt.style.top = '0';
      inp.elt.style.left = '0';
    }

    let textSize = this.textSize;
    inp.elt.style.fontSize = Math.floor(textSize * 1000) / 1000 + 'px';
    inp.elt.style.fontWeight = '400';
    inp.elt.style.fontStyle = 'normal';
    if(settings.getTextBorder()) {
      inp.elt.style.WebkitTextStroke = '0.2px black';
    }
    inp.elt.style.lineHeight = 'normal';
    inp.elt.style.overflow = 'hidden';
    var inEv = function() {
    };
    inp.input(inEv);
    inp.elt.focus();
    inp.elt.selectionEnd= -1;
    inp.elt.selectionStart= -1;
    return inp;
  }
  setLayout(elem) {
    if(this._html_elem_change || positions.getPositionChanged()) {
      elem.style('transform-origin', 'top left');
      elem.position(this.screenX(), this.screenY());
      elem.style('transform', 'scale(' + this.screenScale() + ')');
      this._html_elem_change = false;
    }
  }
  setTextFormat() {
    this.p.textSize(this.textSize * this.screenScale());
    this.p.textSize(this.textSize );
  }
  calcAndSetSize(text) {
    let self = this;
    this.setTextFormat();
    let widest = text.split('\n').map(line => self.p.textWidth(line)).reduce(
      (x, y) => Math.max(x,y)
    );
    widest = Math.max(widest);
    let screenW = widest;
    let wHtml = screenW + 20;
    let lines = text.split('\n').length;
    this.w = screenW + 8 //padding: 4px in textarea;
    this.h = lines * this.p.textLeading() + 8 //padding: 4px in textarea;
    let hHtml = lines * (this.p.textLeading());
    this.inp.style('width', 'calc(' + wHtml + 'px)');
    this.inp.style('height', 'calc(' + hHtml + 'px)');
  }
  typeCalcSmall() {
    return Math.max(this.screenW(), this.screenH()) < 3;
  }
  typeSelectHoverCursor(overridableCursor) {
    if(overridableCursor) {
      this.p.cursor("text");
    }
  }
  typeDraw(x, y, w, h) {
    let drawX = 0;
    let drawY = 0;
    if(this.isSmall() || this.inp !== null) {
      return;
    }
    if(this.textSize * this.screenScale() < 1) {
      this.p.fill(222, 244, 255, 100);
      this.p.noStroke();
      this.p.rect(drawX, drawY, w, h);
    }
    else {
      this.render_html_to_canvas(this.p.drawingContext, drawX, drawY, w, h);
    }
  }

}

export default TextFragment;
